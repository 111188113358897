import { setPosts } from '../../../store/workspaces/action.messenger';
import { setLoaderVisible } from '../../../store/loader/actions';
import store from '../../../store/store';
import { getPosts } from '../../../backend/services/chat/post.get';

export const getPostsData = async (runActionsState, navData, lastMessageRef) => {
  const params = {
    id: runActionsState?.id,
    limit: 20
  };

  try {
    await store.dispatch(setLoaderVisible(true));
    const response = await getPosts(params);
    await store.dispatch(setPosts(response?.message || []));
    console.log(
      `%c ${navData.widgetData.widget} (key: ${navData.widgetData.key}) %c `,
      'background:#FF8800; color: #000',
      'background: transparent; color: black',
      {
        params: runActionsState,
        widgetData: navData.widgetData,
        posts: response?.message
      }
    );
    await store.dispatch(setLoaderVisible(false));
    setTimeout(() => {
      lastMessageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }, 1000);
  } catch (error) {
    console.log('Error getting posts: ', error);
  }
};

