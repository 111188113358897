import axios from 'axios';

export const jwtEncrypt = async (text: any) => {


  return axios.post(
    process.env.REACT_APP_BACKEND_URL + 'services/enc',
    {
      data: text
    },
  ).then(
    (response) => {
      return response.data;
    }
  ).catch(
    (error) => {
      console.log('jwt encrypt error: ', error);
    }
  );
};