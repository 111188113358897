import React from 'react';
import '../styles.css';

class ImageList extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      listData: null,
      inputValue: null
    };
  }

  select = (args: any) => {
    const { $this } = this.props;
    $this.setState({ selectedElement: { ...args.data } });
  };

  render() {
    const { $this } = this.props;

    return (
      <div id="sample" className="list-content">
        <input
          className="e-input textbox-external-link"
          type="url"
          id="textbox"
          placeholder="External URL"
          onChange={(e: any) => {
            $this.setState({ externURL: { url: e.target.value, type: 'image' } });
          }}
        />
      </div>
    );
  }
}

export default ImageList;
