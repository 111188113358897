import { run as runActions } from '../../../store/actions/run';

export const contextMenuClick = (args: any, gridViewInstance: any, contextMenuItems: any) => {

  let flag = false;

  contextMenuItems.map((item: any) => {
    switch (gridViewInstance && args.item.id) {
    case 'grid_excelexport':
      if (flag === false) { gridViewInstance.excelExport(); }
      flag = true;
      break;

    case 'grid_pdfexport':
      if (flag === false) { gridViewInstance.pdfExport(); }
      flag = true;
      break;

    case 'grid_print':
      if (flag === false) { gridViewInstance.print(); }
      flag = true;
      break;

    case item.id:{
      const selectedRecord: any = args;
      const eventKey: any = item.id;
      const itemID = parseInt(selectedRecord.rowInfo.rowData.id);
      runActions(eventKey, itemID, { target: selectedRecord.rowInfo.rowData }, null);
      break;
    }
    default:
      return null;
    }
    return null;
  });
};