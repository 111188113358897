import { setGridFilterValuesToBreadcrumb } from '../../../store/workspaces/action.breadcrumb';
import store from '../../../store/store';

export const setFilterValues = async (args, key) => {
  const breadcrumbGridFilterValues = store.getState()?.workspaces?.instances[store.getState().workspaces.activeWorkspace.index]?.breadcrumb?.currentPage?.grid?.filterValues || [];

  if (args.action === 'clearFilter') {
    const updatedFilterValues = await breadcrumbGridFilterValues.filter(element => element.field !== args.currentFilterObject.properties.field);
    await store.dispatch(setGridFilterValuesToBreadcrumb(updatedFilterValues, key));
  }

  if (args.action === 'filter') {
    const { field, value, uid } = args.currentFilterObject;
    const filterObject = { key, field, value, uid };

    let updatedFilterValues = breadcrumbGridFilterValues;

    if (!updatedFilterValues.some(element => element.field === field)) {
      await updatedFilterValues.push(filterObject);
    } else {
      updatedFilterValues = await breadcrumbGridFilterValues.map(element =>
        element.field === filterObject.field ? filterObject : element
      );
    }

    await store.dispatch(setGridFilterValuesToBreadcrumb(updatedFilterValues, key));
  }
};

export const insertFilterValues = async ($this) => {
  try {
    const currentPageGrid = store.getState().workspaces.instances[store.getState().workspaces.activeWorkspace.index]?.breadcrumb?.currentPage?.grid;

    if (!currentPageGrid) {
      return;
    }

    const { filterValues, key } = currentPageGrid;
    await waitForFilterModule($this);

    filterValues?.forEach(element => {
      if (key === $this.props.navData.widgetData.key) {
        $this.gridViewInstance.filterModule.filterByColumn(element.field, null, element.value);
      }
    });
  } catch (error) {
    console.log('Error setting filter value:', error);
  }
};

const waitForFilterModule = async ($this) => {
  return new Promise<void>((resolve) => {
    const checkFilterModule = () => {
      if ($this.gridViewInstance?.filterModule) {
        resolve();
      } else {
        setTimeout(checkFilterModule, 100);
      }
    };
    checkFilterModule();
  });
};

