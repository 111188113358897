import { notifyDebugError } from '../../components/Toasts/toast.debugError';
import { notifyDefault } from '../../components/Toasts/toast.default';
import { notifyInfo } from '../../components/Toasts/toast.info';
import { notifySuccess } from '../../components/Toasts/toast.success';
import { notifyWarning } from '../../components/Toasts/toast.warn';

export const notifyMessage = (debug: any) => {
  try {
    switch (true) {
    case debug.error:
      notifyDebugError(debug.response.message, debug.step);
      break;
    case debug.warning:
      notifyWarning(debug?.response?.message);
      break;
    case debug.info:
      notifyInfo(debug?.response?.message);
      break;
    case debug.success:
      notifySuccess(debug?.response?.message);
      break;
    case debug.default:
      notifyDefault(debug?.response?.message);
      break;
    default:
      null;
    }
  } catch (error) {
    console.log('Error notifyMessage', error);
  } finally {
    console.log(
      `%c ${debug.step} %c\n`,
      `background: ${debug.style.background}; color: ${debug.style.color};`,
      'background: transparent; color: #000;',
      {
        params: debug.params,
        response: debug.response
      }
    );
  }
};
