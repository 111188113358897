import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { run as runActions } from '../../store/actions/run';
import { callFunctions } from '../../backend/functions/call.functions';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { notifyError } from '../Toasts/toast.error';
import store from '../../store/store';
import PropTypes from 'prop-types';

const MenuBarDropdownButton: any = ({ element, properties, propertiesFiles, menuIndex, validationFormObject, widget }) => {
  const { t } = useTranslation();
  const [isOpenIconClass, setIsOpenIconClass] = useState('e-chevron-right');

  const handleDropdownButtonClick = async (args) => {

    const key = args.item?.key;

    if (!key) {
      notifyError('Event is missing');
      return null;
    }

    const widgetData = widget.props.navData.widgetData;
    let data;
    let files;


    switch (widgetData.widget) {
    case 'Diagram':
      data = await widget.getContent();
      break;
    case 'View':
      data = properties;
      break;
    case 'Gantt':
      data = await widget.getCurrentGanttData();
      break;
    case 'Grid':
      data = widget.state.gridEditData;
      break;
    case 'TreeGrid':
    case 'Graph':
      runActions(key, widgetData.dataID, { ...widget.props.runActionsState.data.params, data: properties }, propertiesFiles)
        .then(() => {
          const response = store.getState().actions?.data?.params;
          if (response.functions) {
            callFunctions(response.functions, { reloadLayoutGraph: widget.reloadLayout });
          }
        });
      break;
    case 'Form':
      if (validationFormObject?.validate() === false) {
        return null;
      }
      data = widget.state.formEdit_data;
      files = widget.state.formEdit_Files;
      break;
    default:
      return null;
    }

    return runActions(key, widgetData.dataID, { ...widget.props.runActionsState.data.params, data: data }, files);
  };


  const handleClose = () => {
    setIsOpenIconClass('e-chevron-right');
  };

  const handleOpen = () => {
    setIsOpenIconClass('e-chevron-down');
  };



  return (
    <DropDownButtonComponent
      items={element.elements.sort((a, b) => (a.sequence - b.sequence)).map((subMenuItem) => {
        return {
          text: t(subMenuItem.label),
          iconCss: 'e-ddb-icons e-dashboard',
          id: subMenuItem.key,
          key: subMenuItem.events.find(event => event.type === 'onClick')?.key
        };
      })}
      iconPosition="Left"
      cssClass='e-custom-dropdown'
      select={handleDropdownButtonClick}
      close={handleClose}
      open={handleOpen}
      key={'Dropdown' + menuIndex}
    >
      {t(element.label)}
      <span className={`e-icons e-small ${isOpenIconClass} dropdown-icon`}></span>
    </DropDownButtonComponent>
  );
};

MenuBarDropdownButton.propTypes = {
  element: PropTypes.object,
  properties: PropTypes.object,
  propertiesFiles: PropTypes.object,
  menuIndex: PropTypes.number,
  handleNewTab: PropTypes.func,
  validationFormObject: PropTypes.object,
  widget: PropTypes.object
};

export default MenuBarDropdownButton;
