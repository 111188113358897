import React from 'react';
import { connect } from 'react-redux';
import '../styles.css';

class LinkForm extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      listData: null,
      inputValue: '',
      url: ''
    };
  }

  componentDidMount() {
    this.setState({
      inputValue: this.props.modalStates.imageDialog.params.selectedText,
      url: this.props.modalStates.selectedText.href
    });
  }

  select = (args: any) => {
    const { $this } = this.props;
    $this.setState({ selectedElement: { ...args.data } });
  };

  render() {
    const { $this } = this.props;

    return (
      <div id="sample" className="list-content">
        <input
          className="e-input textbox-external-link"
          type="url"
          id="textbox"
          placeholder="External URL"
          onChange={(e: any) => {
            this.setState({ url: e.target.value });
            $this.setState({ externURL: { url: e.target.value, type: 'link' } });
          }}
          value={this.state.url}
        />
        <input
          className="e-input textbox-link-text"
          type="text"
          id="textbox"
          placeholder="Text"
          onChange={(e: any) => {
            this.setState({ inputValue: e.target.value });
          }}
          value={this.state.inputValue}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  modalStates: state.workspaces.instances[state.workspaces.activeWorkspace.index].modals
});

export default connect(
  mapStateToProps,
  null
)(LinkForm);
