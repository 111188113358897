import { GET_MODULES, SET_SELECTED_MODULE } from './types';
import { getModules as getModulesNew_API } from '../../backend/query/static/run.modules';
import { logger } from '../../utils/logger';

export const getModules = () => async (dispatch) => {
  try {
    const response = await getModulesNew_API();
    dispatch({ 
      type:  GET_MODULES, 
      data: response 
    });
  } catch (err) {
    logger.error(`Get modules error (${GET_MODULES}): `, err);
  }
};

export const setSelectedModule = (key) => ({
  type: SET_SELECTED_MODULE,
  key
});

