import * as React from 'react';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent
}
  from '@syncfusion/ej2-react-grids';
import { getData } from './services/getDataLink';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// import DataCounter from '../../../../../grid/services/dataCounter/dataCounter';


class LinkGrid extends React.Component<any, any>{

  constructor(props) {
    super(props);
    this.state = {
      gridData: null,
      gridRenderData: {
        columns: [
          { name: 'target.ID', label: 'ID', field: 'target.ID', hide: false, minWidth: 50 },
          { name: 'target.id', label: 'id', field: 'target.id', hide: true, minWidth: 50 },
          { name: 'text', label: 'name', field: 'text', hide: false, minWidth: 150 }
        ],
        type: 'link',
        rowSelection: 'single'
      },
      inputValue: ''
    };
  }

  gridInstance: GridComponent | null;
  selectionSettings: any = {
    persistSelection: true,
    type: 'Single'
  };


  componentDidMount() {
    this.setState({ inputValue: this.props.modalStates.imageDialog.params.selectedText });
    getData(this);
  }

  componentDidUpdate(prevProps: Readonly<any>): void {
    if (prevProps.tabType !== this.props.tabType) {
      getData(this);
    }
  }

  rowSelected(args: any) {
    this.props.$this.setState({ selectedElement: args.data });
  }

  rowSelecting() {
    this.gridInstance.clearSelection();
  }


  render() {
    const { gridRenderData, gridData } = this.state;

    if (!gridData) return null;
    return (
      <>
        {this.props.tabType === 'new' ?
          <input
            className="e-input textbox-link-text"
            type="text"
            id="textbox"
            placeholder="Text"
            onChange={(e: any) => {
              this.setState({ inputValue: e.target.value });
            }}
            value={this.state.inputValue}
            style={{ width: '50%' }}
          />
          : null}
        <div className='widget-container'>

          {/* <h3 className='widget-label'>{this.props.t(navData.widgetData.label)} </h3> */}
          <div className="widget-content">
            <GridComponent
              ref={g => this.gridInstance = g}
              height={'550px'}
              rowHeight={28}
              className={' e-link-grid'}
              dataSource={this.state.gridData}
              allowFiltering={true}
              allowSelection={true}
              rowSelected={this.rowSelected.bind(this)}
              rowSelecting={this.rowSelecting.bind(this)}
            >
              <ColumnsDirective>
                <ColumnDirective
                  type='checkbox'
                  width='24px'
                  allowResizing={false}
                />
                {gridRenderData.columns.map((column) => {
                  switch (column.type) {
                  case 'boolean':
                    return null;
                  case 'code':
                    return (
                      <ColumnDirective
                        key={`field-${column.id}`}
                        headerText={this.props.t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                        width={column.minWidth}
                        minWidth={column.minWidth}
                        maxWidth={column.maxWidth}
                        textAlign={'Center'}
                      />
                    );
                  default:
                    return (
                      <ColumnDirective
                        key={`field-${column.id}`}
                        field={column.name}
                        width={column.minWidth}
                        minWidth={column.minWidth}
                        maxWidth={column.maxWidth}
                        // customAttributes={this.customAttributes}
                        headerText={this.props.t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                        visible={!column.hide}
                        disableHtmlEncode={false}
                        isPrimaryKey={column.name === 'id'}
                        type={'string'}
                      />
                    );
                  }
                })}
              </ColumnsDirective>
            </GridComponent>
          </div>
          {/* <DataCounter
            counter={this.state.dataCounterString}
          /> */}
        </div>
      </>

    );
  }
}

const mapStateToProps = (state: any) => ({
  modalStates: state.workspaces.instances[state.workspaces.activeWorkspace.index].modals
});

export default connect(
  mapStateToProps,
  null
)(withTranslation()(LinkGrid));

