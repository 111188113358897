import React from 'react';
import cytoscape from 'cytoscape';
import klay from 'cytoscape-klay';
import dagre from 'cytoscape-dagre';
import cola from 'cytoscape-cola';
import bilkent from 'cytoscape-cose-bilkent';
import euler from 'cytoscape-euler';
import { withRouterHooks } from '../../routes/router.hooks';
import 'cytoscape-context-menus/cytoscape-context-menus.css';
import { contextMenu } from './graph.contextmenu';
import cytoscapeStyle from './graph.style';
import { run as runActions } from '../../store/actions/run';
import onLoad from './events/onLoad';
import tapNode from './events/tap.node';
import tabContext from './events/tab.context';
import tapEdge from './events/tab.edge';
import { mouseoverNode, mouseoverEdge } from './events/mouseover';
import { mouseoutNode, mouseoutEdge } from './events/mouseout';
import MenuBar from '../../components/MenuBar/MenuBar';
import { withTranslation } from 'react-i18next';
import './styles.css';
import { connect } from 'react-redux';
import { WidgetProvider } from '../WidgetContext';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const nodeHtmlLabel = require('cytoscape-node-html-label');
nodeHtmlLabel(cytoscape); // register extension

cytoscape.use(klay);
cytoscape.use(dagre);
cytoscape.use(cola);
cytoscape.use(bilkent);
cytoscape.use(euler);

class Graph extends React.Component<any, any> {
  cy: any;
  contextMenuInstance: any = null;
  cyRef: React.RefObject<any>;

  constructor(props: any) {
    super(props);
    this.cyRef = React.createRef();
  }

  componentDidMount() {
    const { renderData, data } = this.props;
    this.renderCytoscapeElement(data, renderData.config);
  }

  componentDidUpdate(prevProps) {
    const { renderData, data } = this.props;

    if (prevProps.renderData !== renderData || prevProps.data !== data) {
      this.contextMenuInstance?.destroy();
      this.renderCytoscapeElement(data, renderData.config);
    }
  }

  componentWillUnmount() {
    if (this.contextMenuInstance)
      this.contextMenuInstance.destroy();
  }



  getAllNodesAndEdges = async () => {
    const fetchArray = [];
    this.cy.nodes().positions((node) => {
      fetchArray.push({ 'group': node._private.group, 'data': node._private.data, 'position': node._private.position });
    });
    this.cy.edges().positions((edge) => {
      fetchArray.push({ 'group': edge._private.group, 'data': edge._private.data });
    });
    return fetchArray;
  };


  async addNodesCytoscape(nodes, position) {

    if (!nodes) return null;

    const sizeNodesArray = Object.keys(nodes).length / 2;

    nodes.forEach((element, index) => {
      if (element.group === 'nodes') {
        if (sizeNodesArray > 20) {
          element.position.x = position.x + index * 100;
          element.position.y = position.y + index * 40 + 200;
        } else {
          element.position.x = position.x + 200 * Math.cos(2 * Math.PI * index / sizeNodesArray);
          element.position.y = position.y + 200 * Math.sin(2 * Math.PI * index / sizeNodesArray);
        }
      }
    });
    this.cy.add(nodes);
  }

  async removeNode(node: any) {

    if (!node) return null;
    this.cy.remove(this.cy.getElementById(node.id.toString()));
  }

  reloadLayout = async (layout: any) => {
    const _layout = this.cy.layout({
      name: layout
    });
    _layout.run();
  };


  async renderCytoscapeElement(elementsData, config) {
    const { navData,runActionsState } = this.props;

    this.cy = cytoscape({
      container: this.cyRef.current,
      selected: false,
      selectable: config.selectable,
      grabbable: config.grabbable,
      style: await cytoscapeStyle(config),
      elements: elementsData,
      wheelSensitivity: 0.1,
      locked: true,
      boxSelectionEnabled: false,
      autounselectify: true,
      layout: {
        name: config.layout,
        fit: true,
        spacingFactor: 1.2,
        randomize: config.randomize,
        animate: config.animate,
      }
    });

    this.cy.nodeHtmlLabel([
      {
        query: 'node.selected', // cytoscape query selector
        halign: 'center', // title vertical position. Can be 'left',''center, 'right'
        valign: 'bottom', // title vertical position. Can be 'top',''center, 'bottom'
        halignBox: 'center', // title vertical position. Can be 'left',''center, 'right'
        valignBox: 'bottom', // title relative box vertical position. Can be 'top',''center, 'bottom'
        cssClass: 'cy-infoblock', // any classes will be as attribute of <div> container for every title
        tpl(data) {
          return data?.infoblock === null || data?.infoblock === undefined ? '' : data.infoblock;
        }
      }
    ]);

    if (navData.widgetData.menu.context !== undefined)
      this.contextMenuInstance = await contextMenu(this.cy, navData.widgetData.menu.context, this.addNodesCytoscape.bind(this), this.removeNode.bind(this), this.props.t);
    //await edgeHandle(this.cy);
    //await expandCollapse(this.cy);
    // await doubleTap(this.cy, setRightSideBar);

    if (navData.widgetData.events !== undefined && navData.widgetData.events.length > 0) {
      await onLoad(this.cy);
      await tapNode(this.cy, navData.widgetData.events, runActions, runActionsState);
      await tapEdge(this.cy, navData.widgetData.events, runActions, runActionsState);
      await tabContext(this.cy);
      await mouseoverNode(this.cy);
      await mouseoverEdge(this.cy);
      await mouseoutNode(this.cy);
      await mouseoutEdge(this.cy);
    }
  }


  render() {
    const { navData } = this.props;

    if (!navData)
      return null;

    return (
      <>
        <WidgetProvider value={this}>
          {

            ![null, undefined].includes(this.props.navData.widgetData.menu)
              ? <MenuBar
                key={`menu-${navData.widgetData.menu.id}`}
                menu={navData.widgetData.menu}
              />
              : null
          }
          <div className="graph-container">
            <div style={{ width: '100%', height: '100%' }}>
              <div className="node_selected" style={{ height: '100%', width: '100%' }}>
                <div style={{ height: '100%', width: '100%' }} ref={this.cyRef} />
              </div>
            </div>
          </div>
        </WidgetProvider>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  runActionsState: state.actions
});



export default connect(
  mapStateToProps,
  null
)(withRouterHooks(withTranslation()(Graph)));
