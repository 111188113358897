import React from 'react';
import { connect } from 'react-redux';
import { withRouterHooks } from '../../routes/router.hooks';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { run as runActions } from '../../store/actions/run';
import { notifyError } from '../Toasts/toast.error';
import { withTranslation } from 'react-i18next';

class MenuBarButton extends React.Component<any, any> {

  buttonInstance: ButtonComponent;

  componentDidMount() {
    window.addEventListener('keydown', (event) => {
      const isFunctionKey = /^F\d{1,2}$/.test(event.key);
      const isEnterKey = event.key === 'Enter';
      if (isFunctionKey || isEnterKey) {
        this.handleKeyDown(event);
      }
    });
  }

  componentWillUnmount(): void {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  fetchDataForWidget = async (widget, widgetData, properties, validationFormObject) => {
    let data;
    let files;
    switch (widgetData.widget) {
    case 'Diagram':
      data = await widget.getContent();
      break;
    case 'View':
      if (widgetData.type === 'edit') {
        try {
          const viewerInstance = widget._currentValue.viewerInstance.current;
          data = {
            annotations: viewerInstance.annotationCollection,
            fields: await Promise.all(viewerInstance.formFieldCollection.map(async (field) => ({ ...field.properties, bounds: field.properties.bounds.properties, font: field.properties.font.properties }))),
            signatures: viewerInstance.signatureCollection,
          };
          files = await viewerInstance.saveAsBlob();
        } catch (error) {
          console.error('Error exporting form fields and annotations:', error);
        }
      } else {
        data = properties;
      }
      break;
    case 'Gantt':
      data = await widget.getCurrentGanttData();
      break;
    case 'TreeGrid':
    case 'Grid':
      data = widget.state.gridEditData;
      break;
    case 'Graph':
      data = await widget.getAllNodesAndEdges();
      break;
    case 'Form':
      if (validationFormObject?.validate() === false) {
        return null;
      }
      data = widget.state.formEdit_data;
      files = widget.state.formEdit_Files;
      break;
    case 'Scheduler':
      data = await widget.getCurrentViewEvents();
      break;
    default:
      return null;
    }

    return { data, files };
  };

  handleKeyDown = async (event) => {
    const { properties, validationFormObject, widget, element } = this.props;
    const widgetData = widget?.props?.navData?.widgetData ?? widget._currentValue.props.navData.widgetData;
    const response = await this.fetchDataForWidget(widget, widgetData, properties, validationFormObject);
    const eventApi = element.events?.find((eventObj) => eventObj?.type === 'onClick');

    if (eventApi) {
      const { shortcut, key } = eventApi;
      if (!key) {
        return null;
      }

      if (shortcut && shortcut === event.key) {
        this.buttonInstance.focusIn();
        return runActions(key, widgetData.dataID, { ...this.props.runActionsState.data.params, data: response.data }, response.files);
      }
    }
  };

  handleButtonClick = async (e) => {
    const { properties, handleNewTab, validationFormObject, widget, element } = this.props;
    const widgetData = widget?.props?.navData?.widgetData ?? widget._currentValue.props.navData.widgetData;
    const response = await this.fetchDataForWidget(widget, widgetData, properties, validationFormObject);
    const eventApi = element.events?.find((eventObj) => eventObj?.type === 'onSubmit' || eventObj?.type === 'onClick');

    if (eventApi) {
      const { key } = eventApi;
      if (!key) {
        notifyError('Event is missing');
        return null;
      }
      if (!e?.ctrlKey) {
        return runActions(key, widgetData.dataID, { ...this.props.runActionsState.data.params, data: response.data }, response.files);
      }
      handleNewTab(key, widgetData.dataID, element.label, e);
    }
  };



  render() {
    const { element } = this.props;
    const labelText = this.props.t(element.label);
    const shortcut = this.props.element.events?.find((eventObj) => eventObj?.type === 'onClick')?.shortcut ?? null;
    const shortcutText = shortcut !== null ? ` [${shortcut}]` : '';

    return (
      <ButtonComponent
        ref={g => this.buttonInstance = g}
        id={`button-${element.key}`}
        cssClass='e-custom'
        style={{ textTransform: 'none' }}
        content={labelText + shortcutText}
        disabled={element.disabled}
        key={`menu-item-${element.key}-random:${Math.random()}`}
        type={'button'}
        iconCss={element.icon.name !== '' ? `e-icons e-${element.icon.name}` : null}
        onClick={(e) => {
          this.handleButtonClick(e);
        }}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  runActionsState: state.actions
});



export default connect(mapStateToProps, null)(withRouterHooks(withTranslation()(MenuBarButton)));
