import { logger } from '../../../utils/logger';
import { runStaticQuery } from '../run.staticQuery';

export const getState = async (storeName: string, key: any, id: any) => {

  try {
    const parameters = { id: id, key: key };
    return runStaticQuery('getState', parameters);
  } catch (error) {
    logger.error('API getState error:  ', error);
  }
};