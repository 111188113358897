import React, { FC, useEffect, useState } from 'react';
import { removeModalWidget } from '../../store/workspaces/actions.widgets';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { run as runActions } from '../../store/actions/run';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setIsDialogOpen } from '../../store/workspaces/action.modals';
import { runDynamicQuery } from '../../backend/query/run.dynamicQuery';
import store from '../../store/store';
import './styles.css';

const ModalDialog: FC<any> = ({ navData, oldActionsParams }) => {
  const [contentData, setContentData] = useState(null);
  const runActionsState = useSelector((state: any) => state.actions);
  const { t } = useTranslation();
  const dispatch = useDispatch();


  useEffect(
    () => {
      dispatch(setIsDialogOpen(true));
      runDynamicQuery({ ...runActionsState.data.params, widgetKey: navData.renderID }, navData.widgetData)
        .then(
          (response) => {
            setContentData(response.targets[0]);
          }
        );
      return () => {
        dispatch(setIsDialogOpen(false));
      };
    },
    [navData.widgetData]
  );


  const getButtons = () => {

    const buttons: any = [];

    navData.widgetData.menu.elements.forEach(element => {
      buttons.push({
        buttonModel: {
          content: t(element.label),
          cssClass: 'e-custom modal-dialog-btn'
        },
        'click': () => {
          const eventKey = element.events.find(event => event.type === 'onClick').key;
          runActions(eventKey, navData.widgetData.dataID, { ...oldActionsParams, id: navData.widgetData.dataID });
        }
      });
    });

    buttons.push({
      buttonModel: {
        content: 'Cancel',
        cssClass: 'e-custom modal-dialog-btn'
      },
      'click': () => {
        store.dispatch(removeModalWidget(navData.widgetData.key, navData.widgetData.dataID, store.getState().workspaces.activeWorkspace.index));
      }
    });
    return buttons;
  };


  if (!contentData) {
    return null;
  }

  return (
    <DialogComponent
      isModal={true}
      height={navData.widgetData.height}
      width={navData.widgetData.width}
      enableResize={false}
      resizeHandles={[]}
      allowDragging={false}
      showCloseIcon={false}
      buttons={getButtons()}
      close={() => {
        store.dispatch(removeModalWidget(navData.widgetData.key, navData.widgetData.dataID, store.getState().workspaces.activeWorkspace.index));
      }}
      header={contentData.header}
      content={contentData.content}
    >
    </DialogComponent>
  );

};

export default ModalDialog;