import React from 'react';
import LayoutWidget1 from '../page.layout';
import Modal from '../../../components/modals/Modal';
import { runStaticQuery } from '../../../backend/query/run.staticQuery';
import { run as runActions } from '../../../store/actions/run';

class WorkspaceComponent extends React.Component<any, any> {

  async componentDidMount() {
    const { workspace } = this.props;
    const response = await runStaticQuery('workspace', null);
    const onLoadEvent = response.workspace.events.find((event) => event?.type === 'onLoad');
  
    if (onLoadEvent) {
      const key = onLoadEvent.key;

      if (workspace.data === null && workspace.isOpen) {
        await runActions(key, null, null);
      }
    }
  }


  render() {
    const {  workspace } = this.props;
    
    const getColumnClass: any = (width: number) => (width > 50) ? 'dynamicColumn spacy' : 'dynamicColumn tight';
    const getWidget: any = (columnIndex, rowIndex) => workspace?.widgets?.widgets.find(widget => widget.position.column === columnIndex && widget.position.row === rowIndex);
    const getModalWidget: any = workspace?.widgets?.widgets.find(widget => widget.modal === true);

    return (
      <>
        {
          workspace?.widgets?.layout
          && workspace?.widgets?.layout.columns
          && workspace?.widgets?.layout?.columns.map((column, columnIndex) =>
            <div className={`main-content ${getColumnClass(column.width)}`} style={{ width: `${column.width}%` }} key={'dynamicColumn-' + columnIndex}>
              <div className={'innerColumn'}>
                {
                  column.rows?.map((row, rowIndex) => {
                    const widget = getWidget(columnIndex + 1, rowIndex + 1);
                    return <div className={'dynamicRow'} key={`dynamicRow-${row.position}-${column.position}`} style={{ height: `${row.height}%` }}>
                      {
                        widget
                          ? (
                            <LayoutWidget1
                              runActionsState={workspace.data}
                              column={column}
                              row={row}
                              widget={widget}
                              oldActionsParams={workspace?.widgets?.oldActionsParams}
                              oldWidgetViewType={workspace?.widgets?.oldWidgetViewType}
                            />
                          )
                          : null
                      }
                    </div>;
                  })
                }
              </div>
            </div>
          )
        }
        {
          getModalWidget
            ? (
              <Modal
                widget={getModalWidget}
                oldActionsParams={workspace?.widgets?.oldActionsParams}
              />
            )
            : null

        }
      </>
    );
  }
}

export default WorkspaceComponent;