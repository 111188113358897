import React, { FC} from 'react';
import { useTranslation } from 'react-i18next';
import { Input} from 'semantic-ui-react';

const PasswordField: FC<any> = ({ field, isLocked, hiddenClass }) => {
  const isPasswordHidden = true;
  const { t } = useTranslation();
  
  return (
    <div className={`form-input-container ${hiddenClass}`}>
      <label className='form-input-label'>{t(field.label)}</label>
      <div className='form-input-element password-field'>
        <span className="e-input-group e-control-wrapper e-valid-input e-disabled">
          <Input
            fluid
            name="password"
            value='*************'
            type={isPasswordHidden ? 'password' : 'text'}
            style={{ minHeight: '28px', padding: '3px', border: 'none' }}
            disabled={isLocked}
          />
        </span>
      </div>        
    </div>
  );
};

export default PasswordField;