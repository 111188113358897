export const setDataCounter = async (args, $this, t) => {
  if (!$this.state.gridData) return null;
  let count;

  if ($this.gridViewInstance && $this.gridViewInstance.filterModule) {
    count = Object.keys($this.gridViewInstance.filterModule.actualPredicate).length ?? null === 0
      ? $this.state.gridData?.length ?? 0
      : $this.gridViewInstance?.getFilteredRecords()?.length ?? 0;
  } else {
    count = $this.state.gridData.length;
  }

  const itemCountString = t(count === 1 ? 'Item' : 'Items');
  $this.setState({ dataCounterString: `(${count} ${itemCountString})` });
};
