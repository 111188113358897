import React from 'react';
import { withRouterHooks } from '../../../routes/router.hooks';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  ContextMenu,
  Edit,
  Filter,
  Group,
  Inject,
  Page,
  Selection,
  ExcelExport,
  PdfExport,
  Resize,
  Toolbar,
  InfiniteScroll,
  IEditCell,
  Column,
  Sort
} from '@syncfusion/ej2-react-grids';
import { DropDownList } from '@syncfusion/ej2-react-dropdowns';
import { WidgetProvider } from '../../WidgetContext';
import MenuBar from '../../../components/MenuBar/MenuBar';
import { setDataCounter } from '../services/dataCounter/setDataCounter';
import { setGridData } from '../services/setGridData';
import { setCustomContextMenu } from '../services/setContextMenu';
import { contextMenuClick } from '../services/contextMenuClick';
import { withTranslation } from 'react-i18next';
// import DataCounter from '../services/dataCounter/dataCounter';
import { setBatchEditData } from './events/setBatchEditData';
import './../styles.css';
import { getSettingsUpdate } from './settings';
import { getEditType } from './events/getEditType';
import { insertFilterValues, setFilterValues } from '../services/setFilterValues';
import { setKeyDownRemoveFilter } from '../services/setKeydownEvent';

class GridWidgetUpdate extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      gridData: null,
      gridEditData: [],
      dataCounterString: ''
    };
  }

  gridUpdateInstance: GridComponent | null;

  componentDidMount() {
    setGridData(this)
      .then(() => {
        insertFilterValues(this);
      });
  }

  componentDidUpdate(prevProps) {
    const { navData } = this.props;

    if (prevProps.navData.renderID !== navData.renderID || prevProps.navData.widgetData.dataID !== navData.widgetData.dataID || prevProps.navData.widgetData.timestamp !== navData.widgetData.timestamp) {
      this.setState({ gridData: null });
      setGridData(this);
    }
  }

  componentWillUnmount() {
    this.setState({ gridData: null, gridEditData: [], dataCounterString: '' });
    window.removeEventListener('keydown', (event) => setKeyDownRemoveFilter(event, this.gridUpdateInstance));
  }


  created = () => {
    this.setState({ gridEditData: this.state.gridData });
    window.addEventListener('keydown', (event) => setKeyDownRemoveFilter(event, this.gridUpdateInstance));
  };

  actionComlepte = async (args) => {
    const { navData, runActionsState } = this.props;
    if (runActionsState && navData) {
      await setFilterValues(args, navData.widgetData.key);
    }
    await setDataCounter(args, this, this.props.t);
    await setBatchEditData(this, args);
  };

  // TODO outsource
  elem;
  dropdownTempObj = [];
  dropdownElement = {
    create: (args) => {
      const elem = document.createElement('input');
      elem.id = 'dropedit' + args.column.field;
      return elem;
    },
    destroy: () => {
      this.dropdownTempObj[0]?.ej2_instances[0]?.destroy();
      this.dropdownTempObj?.shift();
    },
    read: args => {
      const value = args.ej2_instances[0]?.value;
      this.dropdownTempObj?.push(args);
      return value;
    },
    write: (args) => {
      let dropDownData = null;
      if (this.props.navData.widgetData.columns !== undefined) {
        this.props.navData.widgetData.columns.forEach(element => {
          if (element.name === args.column.field && element.type === 'list') {
            dropDownData = element.dropDownData;
          }
        });
      }
      const dropdownTempObj = new DropDownList({
        dataSource: dropDownData.sort(),
        fields: { text: args.column.field, value: args.column.field },
        value: args.rowData[args.column.field],
        floatLabelType: 'Auto',
        popupHeight: '220px',
        placeholder: args.column.field.charAt(0).toUpperCase() + args.column.field.slice(1)
      });
      dropdownTempObj.appendTo(args.element);
    }
  };

  /* numeric cell*/
  numericCell: IEditCell = {
    params: { decimals: 1 }
  };

  /* boolean cell */
  booleanEditObject: CheckBoxComponent;
  booleanEditCell: IEditCell = {
    create: () => {
      this.elem = document.createElement('input');
      return this.elem;
    },
    destroy: () => {
      const element: any = this.elem;
      const checkedElement = element.checked;
      this.state.gridData.forEach(element => {
        if (checkedElement !== element.selected) {
          element.selected = checkedElement;
          element.test_boolean = checkedElement;
        }
      });
    },
    read: () => {
      const element: any = this.elem;
      return element.checked;
    },
    write: (args: { rowData: object, column: Column }) => {
      const _args: any = args;
      this.booleanEditObject = new CheckBoxComponent({
        label: _args.column.field,
        checked: _args.rowData.test_boolean
      });
      this.booleanEditObject.appendTo(this.elem);
    }
  };


  getEditTypeCell = (column: any, batchEditCell: any, numericCell: any) => {
    if (column.editable === true) {
      switch (column.type) {
      case 'list':
        return batchEditCell;
      case 'string':
        return null;
      case 'textarea':
        return null;
      case 'integer':
        return numericCell;
      default:
        return null;
      }
    } else {
      return null;
    }
  };


  render() {
    const { navData } = this.props;

    if (!navData?.widgetData || !this.state.gridData) {
      return null;
    }

    const settings = getSettingsUpdate(navData);

    return (
      <div className='widget-container'>
        <WidgetProvider value={this}>
          {
            navData.widgetData.menu
              ? <MenuBar
                key={`menu-${navData.widgetData.menu.id}`}
                menu={navData.widgetData.menu}
              />
              : null
          }
          {
            navData.widgetData.label !== '' ? <h3 className='widget-label'>{this.props.t(navData.widgetData.label)} </h3> : ''
          }
          <div className="widget-content">
            <GridComponent
              locale={JSON.parse(localStorage.getItem('language'))}
              id={`grid-${navData.widgetData.key}`}
              ref={g => this.gridUpdateInstance = g}
              key={`grid-${navData.widgetData.key}`}
              height={'100%'}
              rowHeight={28}
              className={`grid-widget${navData.widgetData.type === 'link' && navData.widgetData.rowSelection === 'single' ? ' e-link-grid' : ''}`}
              dataSource={this.state.gridData}
              allowExcelExport={true}
              allowPdfExport={true}
              allowFiltering={true}
              allowResizing={true}
              allowSelection={true}
              allowSorting={true}
              allowPaging={navData.widgetData.allowPaging}
              enableInfiniteScrolling={false}
              editSettings={settings.editSettings}
              filterSettings={settings.filterSettingsMenu}
              contextMenuItems={setCustomContextMenu(navData)}
              pageSettings={navData?.widgetData?.allowPaging ? settings.pageSettings : settings.pageSettingsInfinite}
              // infiniteScrollSettings={!this.props.allowPaging || gridRenderData.type !== 'update' ? this.infiniteOptions : null}
              created={this.created.bind(this)}
              contextMenuClick={(args) => contextMenuClick(args, this.gridUpdateInstance, navData ? setCustomContextMenu(navData) : null)}
              actionComplete={this.actionComlepte.bind(this)}
            >
              <ColumnsDirective>
                {navData.widgetData.columns.map((column) => {
                  switch (column.type) {
                  case 'boolean':
                    return null;
                  case 'code':
                    return (
                      <ColumnDirective
                        key={`field-${column.id}`}
                        headerText={this.props.t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                        width={column.minWidth}
                        minWidth={column.minWidth}
                        maxWidth={column.maxWidth}
                        customAttributes={{ class: [settings.customAttributes.class, 'code-cell'] }}
                        textAlign={'Center'}
                      />
                    );
                  default:
                    return (
                      <ColumnDirective
                        key={`field-${column.id}`}
                        field={column.name}
                        width={column.minWidth}
                        minWidth={column.minWidth}
                        maxWidth={column.maxWidth}
                        // customAttributes={this.customAttributes}
                        customAttributes={column.name === 'id' ? { class: [settings.customAttributes.class, 'id-cell'] } : settings.customAttributes}
                        headerText={this.props.t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                        filter={settings.filterMenu}
                        visible={!column.hide}
                        disableHtmlEncode={false}
                        edit={this.getEditTypeCell ? this.getEditTypeCell(column, this.dropdownElement, this.numericCell) : null}
                        isPrimaryKey={column.name === 'id'}
                        allowEditing={column.editable}
                        editType={getEditType(column)}
                        type={'string'}
                      />
                    );
                  }
                })}
              </ColumnsDirective>
              <Inject services={[ContextMenu, Filter, Page, ExcelExport, Edit, Group, PdfExport, Selection, Resize, Toolbar, InfiniteScroll, Sort]} />
            </GridComponent>
          </div>
          {/* {!navData?.widgetData?.allowPaging && (
            <DataCounter
              counter={this.state.dataCounterString}
            />
          )} */}
        </WidgetProvider>
      </div>
    );
  }
}

export default withRouterHooks(withTranslation()(GridWidgetUpdate));
