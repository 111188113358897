import axios from 'axios';

export const jwtDecrypt = async (token: any) => {

  return axios.post(
    process.env.REACT_APP_BACKEND_URL + 'services/dec',
    {
      data: token
    },
  ).then(
    (response) => {
      return response.data;
    }
  ).catch(
    (error) => {
      console.log('jwt decrypt error:  ', error);
    }
  );
};