import React, { FC, useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { notifyError } from '../../components/Toasts/toast.error';
import { withRouterHooks } from '../../routes/router.hooks';
import LoginTokenDialog from './tokenDialog';
import { FormValidator, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import getAuthenticationMode from '../../backend/query/user/getAuthenticationMode';
import login from '../../backend/query/user/login';
import { isCookieValid } from '../../utils/cookies';
import checkDBConnection from '../../backend/utils/checkDBConnection';
import { notifyWarning } from '../../components/Toasts/toast.warn';
import logo from '../../images/logo.png';
import packageJson from '../../../package.json';
import './styles.css';
import request from '../../request/request';

type LoginRequest = {
  authenticationMode: string;
  username: string;
  password: string;
  version: string;
  token: string;
}


const LoginPage: FC<any> = () => {

  const userNameRef = useRef(null);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [path, setPath] = useState('#');
  const [dialogVisible, setDialogVisible] = useState(false);
  const [authenticationMode, setAuthenticationMode] = useState(null);
  const [isDBConnected, setIsDBConnected] = useState(true);

  const version = `v${packageJson.version}`;
  const stage = process.env.NODE_ENV !== 'production' ? ` on stage ${process.env.NODE_ENV}` : '';
  console.log(path);


  useEffect(() => {
    userNameRef.current.focusIn();
    const options = {
      rules: {
        username: {
          required: [true, '* Please enter your name'],
        },
        password: {
          required: [true, '* Please enter your password'],
        },
      },
    };
    new FormValidator('#form', options);

    if (JSON.parse(localStorage.getItem('expired'))) {
      notifyWarning('session expired');
    }

    checkDBConnection()
      .then((response) => {
        if (!response.error) {
          getAuthenticationMode()
            .then((mode) => {
              setAuthenticationMode(mode);
            });
        } else {
          setIsDBConnected(false);
          notifyError(response.message);
        }
      });
    return () => {
      setIsDBConnected(true);
    };
  }, []);

 



  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      switch (authenticationMode) {
      case 'password-only':
        return await request<LoginRequest, any>('POST','auth/signin',{authenticationMode, username, password, version, token:''})
          .then((response) => {
            localStorage.setItem('session', JSON.stringify(response.data.session));
            localStorage.setItem('translations', JSON.stringify(response.data.translations));
            localStorage.setItem('translationsSyncfusion', JSON.stringify(response.data.translationsSyncfusion));
            localStorage.setItem('language', JSON.stringify(response.data.language));
            localStorage.setItem('expired', JSON.stringify(false));
            // localStorage.setItem('fingerprint', JSON.stringify(response.data.data.fingerprint));
            resetState(e);
            window.location.href = '/app';
          });
      case 'two-factor':
        if (isCookieValid('sessionCookie')) {
          return await login('password-only', username, password, version, '')
            .then((response) => {
              localStorage.setItem('session', JSON.stringify(response.data.data.session));
              localStorage.setItem('translations', JSON.stringify(response.data.data.translations));
              localStorage.setItem('translationsSyncfusion', JSON.stringify(response.data.data.translationsSyncfusion));
              localStorage.setItem('language', JSON.stringify(response.data.data.language));
              localStorage.setItem('expired', JSON.stringify(false));
              // localStorage.setItem('fingerprint', JSON.stringify(response.data.data.fingerprint));
              resetState(e);
              window.location.href = '/app';
            });
        } else {
          return await login('password-only', username, password, version, '')
            .then((response) => {
              if (response.data.data.session) {
                setDialogVisible(true);
              }
            });
        }
      default:
        return null;
      }
    } catch (error) {
      if (error.response) {
        localStorage.removeItem('session');
        notifyError(error.response.data.message);
      }
    }
  };


  const resetState = (e) => {
    e.preventDefault();
    setUsername('');
    setPassword('');
  };


  // const handelLinkClick = () => {
  //   if (username === '') {
  //     return notifyError('To reset your password please enter your user name');
  //   } else {
  //     history('/request-password');
  //   }
  //   return;
  // };


  return (
    <>
      <div className='login-container'>
        <div className='login-logo'>
          <img src={logo} alt="Logo" height={200} width={250} />
        </div>
        <h1 className="login-form-title" >Login</h1>
        <div className={isDBConnected ? 'login-form-container' : 'login-form-container-deactivated'}>
          <form
            id="form"
            className='login-form'
          >
            <TextBoxComponent
              ref={userNameRef}
              name="username"
              className='login-form-input'
              value={username}
              change={(e) => {
                if (e.value.length !== 0) {
                  setPath('/request-password');
                } else {
                  setPath('#');
                }
                setUsername(e.value);
                localStorage.setItem('user', e.value);
              }}
              placeholder="Name"
              floatLabelType="Always"
              data-msg-containerid="error-username"
            />
            <div id="error-username" />
            <TextBoxComponent
              type="password"
              name="password"
              className='login-form-input'
              value={password}
              change={(e) => { setPassword(e.value); }}
              placeholder="Password"
              floatLabelType="Always"
              data-msg-containerid="error-password"
            />
            <div id="error-password" />
            <div className="login-buttons">
              <ButtonComponent
                cssClass='e-custom'
                style={{ textTransform: 'none' }}
                content={'Login'}
                onClick={(e) => handleSubmit(e)}
              />
              <ButtonComponent
                cssClass='e-custom'
                style={{ textTransform: 'none' }}
                content={'Reset'}
                onClick={(e) => resetState(e)}
              />
            </div>
            <LoginTokenDialog
              dialogVisible={dialogVisible}
              username={username}
              password={password}
              resetState={resetState}
              setDialogVisible={setDialogVisible}
              version={version}
              authenticationMode={authenticationMode}
            />
          </form>
        </div>
        <div className={isDBConnected ? 'login-footer' : 'login-footer-deactivated'}>
          {/* <Link to={path}
            onClick={() => handelLinkClick()}
          >Forgot your password?</Link> */}
          <footer>
            <div className="version-tag">running graph-designer {version}{stage}</div>
          </footer>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default withRouterHooks(LoginPage);