import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { run as runActions } from '../../store/actions/run';
import { notifyWarning } from '../../components/Toasts/toast.warn';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';


export const MessageContext = ({ post, navData, runActionsState }) => {

  const { t } = useTranslation();

  return (
    <Dropdown
      text="..."
      icon={null}
      className='message-dropdown'
    >
      <Dropdown.Menu>
        {navData.widgetData.menu?.elements?.map((element) => {
          const eventKey = element.events?.find(event => event?.type === 'onClick')?.key;
          return (
            <Dropdown.Item
              key={element.key}
              text={t(element.label)}
              onClick={() => {
                if (!eventKey) {
                  notifyWarning('event is missing');
                  return null;
                }
                runActions(eventKey, post.id, runActionsState, null);
              }}
            />
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

MessageContext.propTypes = {
  post: PropTypes.object,
  navData: PropTypes.any,
  runActionsState: PropTypes.any,
};

export default MessageContext;