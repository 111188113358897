import {
  runActionsPending,
  runActionsReceived,
  runActionsError
} from './actions';
import store from '../store';
import { runWorkflow } from '../../backend/workflow/runWorkflow';
import { notifyWarning } from '../../components/Toasts/toast.warn';
import { updateWorkspaceActions } from '../workspaces/action.workspaces';
import { fetchWidgets } from '../workspaces/actions.widgets';



export const run = async (key: number, id: number, params: any = {}, files: any = null) => {

  if (!key) {
    notifyWarning('Event is missing');
    return null;
  }

  return Promise.all([
    await store.dispatch(runActionsPending()),
    await runWorkflow(key, id, params, files, false)
      .then(
        async (response) => {
          if (!response) {
            store.dispatch(runActionsError());
            return null;
          }
          const workspaces = store.getState().workspaces;
          await store.dispatch(runActionsReceived(response));
          fetchWidgets(
            response.key,
            response.id,
            response.params ?? {},
            response.relation,
            response.source,
            response.target
          );
          
          await store.dispatch(updateWorkspaceActions(workspaces.activeWorkspace.index, response));

          Object.values(response.widgetsContent).forEach((widget: any) => {
            console.log(
              `%c ${widget.type} (key: ${widget.key}) %c `,
              `background:${!widget ? '#FF3333' : '#FF8800'}; color: #000`,
              'background: transparent; color: black',
              {
                params: widget.params,
                response: widget.data
              }
            );
          });

          return response;
        }
      )
      .catch(() => {
        store.dispatch(runActionsError());
      }),
  ]);
};
