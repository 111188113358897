import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import ModuleNavigation from '../../components/modules/modules.navigation';
import RichtextDialog from '../../widgets/form/fields/richtext/dialog/dialog.richtext';
import UploaderWidget from '../../widgets/upload/Upload';
import Loader from '../../components/Loader/Loader';
import WorkspacesMainstage from './workspaces/workspaces';
import { ToastContainer } from 'react-toastify';
import { notifyError } from '../../components/Toasts/toast.error';
import { useIdleTimer } from 'react-idle-timer';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';



const ModulePage = () => {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [remaining, setRemaining] = useState(null);
  const themes: any = useSelector((state: any) => state.getThemes);
  const modalStates = useSelector((state: any) => state.workspaces.instances[state.workspaces.activeWorkspace.index]?.modals);
  const runActionsState: any = useSelector((state: any) => state.actions);
  const workspaces = useSelector((state: any) => state.workspaces);
  const loader = useSelector((state: any) => state.loader);
  const timeout = 7200000;
  
  useEffect(() => {
    setInterval(() => {
      setRemaining(getRemainingTime());
    }, 10);
  }, []);

  const handleLeftSidebar = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleOnIdle = () => {
    if (remaining === 300000) {
      notifyError(' Noch 5 Minuten verbleibend bis zur automatischen Abmeldung ');
    }
    if (remaining < 300 || remaining === null) {
      localStorage.removeItem('session');
      localStorage.setItem('expired', JSON.stringify(true));
      window.location.reload();
    }
  };

  const { getRemainingTime } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
    crossTab: true,
    // crossTab: {
    //  type: 'localStorage',
    //  channelName: 'idle-timer',
    //  fallbackInterval: 2000,
    //  responseTime: 100,
    //  removeTimeout: 1000 * 60,
    //  emitOnAllTabs: true
    //}
  });


  return (
    <>
      <div className="main-container" style={{ backgroundColor: themes?.data?.colors?.background?.hex }}>
        <div className={`navigation-container ${isMenuOpen ? 'opened' : 'closed'}`}>
          {runActionsState.pending === true || loader === true ? <Loader /> : null}
          {workspaces.instances.map((workspace, index) => (
            <div
              key={index}
              className={`workspace-content${workspaces.activeWorkspace.index === index ? '' : ' hidden'}`}
            >
              <ModuleNavigation workspace={workspace} isMenuOpen={isMenuOpen} handleLeftSidebar={handleLeftSidebar.bind(this)} />
            </div>
          ))}
        </div>
        <div className={`module-container ${isMenuOpen ? 'navigation-container opened' : 'navigation-container closed'}`} style={{ height: '100%' }}>
          <Grid columns={1} style={{ height: '100vh', marginTop: '0rem' }}>
            <Grid.Column style={{ padding: 0, margin: 0, height: '100%' }}>
              <WorkspacesMainstage/>
            </Grid.Column>
          </Grid>
        </div>
        <ToastContainer />
        {modalStates?.imageDialog?.isOpen ? <RichtextDialog /> : null}
        {modalStates?.multiUpload?.isOpen ? <UploaderWidget /> : null}
      </div>
    </>
  );
};

export default ModulePage;