import React from 'react';
import { connect } from 'react-redux';
import { withRouterHooks } from '../../../../../routes/router.hooks';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { withTranslation } from 'react-i18next';
import { closeRichtextDialog } from '../../../../../store/workspaces/action.modals';
import { Tab } from 'semantic-ui-react';
import { notifyError } from '../../../../../components/Toasts/toast.error';
import { runStaticQuery } from '../../../../../backend/query/run.staticQuery';
import DiagramGrid from './grids/grid.diagram';
import ImageGrid from './grids/grid.image';
import LinkGrid from './grids/grid.link';
import LinkForm from './forms/form.link';
import ImageForm from './forms/form.image';
import './styles.css';

class RichtextDialog extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedElement: null,
      externURL: null
    };
  }
  dialogInstance: DialogComponent | null;

  buttons: any = [
    {
      buttonModel: {
        content: 'ok',
        cssClass: 'e-flat',
        isPrimary: true
      },
      'click': async () => {
        const { selectedElement, externURL } = this.state;

        const inputTextElement: any = document.querySelector('input.textbox-link-text');

        if (inputTextElement?.value === '') {
          notifyError('Text input is missing');
          return null;
        }
        if (externURL !== null) {
          let url;
          if (externURL.url !== '') {
            try {
              url = new URL(externURL.url);
            } catch (_) {
              notifyError('URL is not valid');
              return null;
            }
          }
          switch (externURL.type) {
          case 'image':
            if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url) === false) {
              notifyError('URL is not a valid image');
              return null;
            }
            this.props.modalStates.imageEvent(this.state.selectedElement, url?.href);
            this.props.closeRichtextDialog();
            return null;
          case 'link':
            this.props.modalStates.linkEvent(null, inputTextElement?.value, url.href);
            this.props.closeRichtextDialog();
            return null;
          default:
            return null;
          }
        }

        const parameters = {
          ...selectedElement,
          link: {
            name: inputTextElement?.value ?? selectedElement?.target?.name ?? selectedElement?.name
          }
        };

        runStaticQuery('mergeElements', parameters)
          .then(async (response) => {
            if (response.error !== true)
              console.log(
                '%c StoredProcedure (mergeElements) %c',
                'background: #F9E79F; color: #000',
                'background: transparent; color: black;',

                {
                  params: parameters,
                  response: { uuid: response.uuid }
                }
              );
            switch (this.props.modalStates.eventType) {
            case 'image_grid':
              return this.props.modalStates.imageEvent(this.state.selectedElement);
            case 'link_grid':
              return this.props.modalStates.linkEvent(selectedElement, inputTextElement?.value ?? selectedElement.text, null);
            case 'diagram_grid':
              return this.props.modalStates.diagramEvent(selectedElement);
            default:
              return null;
            }
          }
          )
          .finally(
            () => {
              this.props.closeRichtextDialog();
            }
          )
          .catch((error: any) =>
            console.error('RichtextDialog:  ', error)
          );
      }
    },
    {
      buttonModel: {
        content: 'cancel',
        cssClass: 'e-flat',
        isPrimary: false,
      },
      'click': () => {
        this.props.closeRichtextDialog();
      }
    },
  ];


  getListRender = (type, tabType) => {
    const { modalStates } = this.props;

    switch (type) {
    case 'image_grid':
      return <ImageGrid
        params={modalStates.imageDialog.params}
        $this={this}
        tabType={tabType}
      />;
    case 'link_grid':
      return <LinkGrid
        params={modalStates.imageDialog.params}
        $this={this}
        tabType={tabType}
      />;
    case 'diagram_grid':
      return <DiagramGrid
        params={modalStates.imageDialog.params}
        $this={this}
        tabType={tabType}
      />;
    case 'link':
      return <LinkForm
        params={modalStates.imageDialog.params}
        $this={this}
      />;
    case 'image':
      return <ImageForm
        params={modalStates.imageDialog.params}
        $this={this}
      />;
    default:
      return null;
    }
  };

  setHeader = (type) => {
    switch (type) {
    case 'image_grid':
      return 'get linked image';
    case 'link_grid':
      return 'get link';
    case 'diagram_grid':
      return 'get diagram';
    case 'link':
      return 'set external link';
    case 'image':
      return 'set external image';
    default:
      return null;
    }
  };


  public render() {
    const { modalStates } = this.props;

    if (!modalStates.imageDialog.params) return null;

    const panes = [
      { menuItem: 'New', render: () => <Tab.Pane> {this.getListRender(modalStates.eventType, 'new')}</Tab.Pane> },
      { menuItem: 'Existing', render: () => <Tab.Pane> {this.getListRender(modalStates.eventType, 'existing')}</Tab.Pane> }
    ];

    return (
      <div id='container'>
        <DialogComponent
          open={modalStates.imageDialog.isOpen}
          id={`dialog-${!modalStates.imageDialog.params}`}
          className={'dialog-container'}
          width={modalStates.eventType === 'link' || modalStates.eventType === 'image' ? '500px' : '1000px'}
          height={modalStates.eventType === 'link' || modalStates.eventType === 'image' ? '240px' : '950px'}
          isModal={true}
          target='#container'
          header={this.setHeader(modalStates.eventType)}
          closeOnEscape={true}
          buttons={this.buttons}
          ref={dialog => this.dialogInstance = dialog!}
        >
          <div>
            {modalStates.eventType === 'link' || modalStates.eventType === 'image'
              ?
              this.getListRender(modalStates.eventType, 'new')
              :
              <Tab
                panes={panes}
                menu={{ text: true }}
              />
            }
          </div>
        </DialogComponent>
      </div>);
  }
}

const mapStateToProps = (state: any) => ({
  modalStates: state.workspaces.instances[state.workspaces.activeWorkspace.index]?.modals
});

const mapDispatchToProps = (dispatch: any) => ({
  closeRichtextDialog: () => dispatch(closeRichtextDialog())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouterHooks(withTranslation()(RichtextDialog)));

