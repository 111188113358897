import React, { useEffect, useState, useRef } from 'react';
import ChatBody from './chat.body';
import ChatFooter from './chat.footer';
import ChatFilterInput from './chat.filter';
import { getPostsData } from './services/getPostsData';
import { getPostsDataInfiniteScroll } from './services/getPostsData.scroll';
import { useSelector } from 'react-redux';
import { setChatOpen, setPosts } from '../../store/workspaces/action.messenger';
import store from '../../store/store';
import './styles.css';
import PropTypes from 'prop-types';
import { setLastVisitAPI } from '../../backend/services/chat/setLastVisit';


const Chat = ({ navData, runActionsState }) => {
  const workspaceState = useSelector((state: any) => state.workspaces.instances[state.workspaces.activeWorkspace.index]);
  const workspaceInstances = useSelector((state: any) => state.workspaces.instances);
  const matchingInstance = workspaceInstances.find(instance => instance.isActive);

  const [isReply, setIsReply] = useState(false);
  const [replyTarget, setReplyTarget] = useState('');

  const lastMessageRef = useRef(null);
  const targetDivRef = useRef(null);


  useEffect(() => {
    const chatId = workspaceState?.data?.id ?? runActionsState.id;
    setTimeout(() => {
      getPostsData(workspaceState?.data, navData, lastMessageRef);
      setLastVisitAPI({id: chatId});
      store.dispatch(setChatOpen(true, chatId));
    }, 500);
    return () => {
      setLastVisitAPI({id: chatId});
      store.dispatch(setChatOpen(false, null));
      store.dispatch(setPosts([]));
    };
  }, [runActionsState]);


  useEffect(() => {
    const lastVisitIntervall = async () => {
      if (matchingInstance && matchingInstance.chat.isOpen) {
        const chatId = matchingInstance?.data?.id ?? runActionsState.id;
        await setLastVisitAPI({ id: chatId });
      }
    };
    const intervalId = setInterval(lastVisitIntervall, 3000);
    return () => clearInterval(intervalId);
  }, [matchingInstance]);




  const handleScroll = async () => {
    if (targetDivRef.current.scrollTop === 0 && !workspaceState.chat.filterMode) {
      await getPostsDataInfiniteScroll(workspaceState.chat.posts, targetDivRef);
    }
  };


  return (
    <div className="widget-container">
      <div className='filter-container'>
        <ChatFilterInput
          runActionsState={workspaceState?.data}
          targetDivRef={targetDivRef}
          handleScroll={handleScroll}
          navData={navData}
          lastMessageRef={lastMessageRef}
          filterMode={workspaceState?.chat.filterMode}
        />
      </div>
      <div className="widget-content">
        <ChatBody
          navData={navData}
          posts={workspaceState?.chat.posts}
          isReply={isReply}
          setIsReply={setIsReply}
          setReplyTarget={setReplyTarget}
          lastMessageRef={lastMessageRef}
          targetDivRef={targetDivRef}
          handleScroll={handleScroll}
          filterMode={workspaceState?.chat.filterMode}
          runActionsState={workspaceState?.data}
        />
        <ChatFooter
          posts={workspaceState?.chat.posts}
          isReply={isReply}
          setIsReply={setIsReply}
          replyTarget={replyTarget}
          runActionsState={workspaceState?.data}
        />
      </div>
    </div>
  );
};

Chat.propTypes = {
  navData: PropTypes.object,
  oldActionsParams: PropTypes.object,
  oldWidgetViewType: PropTypes.string,
  runActionsState: PropTypes.any,
};

export default Chat;
