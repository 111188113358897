import React, { Component } from 'react';
import { MapsComponent, Inject, LayersDirective, LayerDirective, Marker } from '@syncfusion/ej2-react-maps';
import { runDynamicQuery } from '../../backend/query/run.dynamicQuery';
import { run as runActions } from '../../store/actions/run';
import { WidgetProvider } from '../WidgetContext';
import MenuBar from '../../components/MenuBar/MenuBar';

class MapWidget extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      data: null
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { navData, runActionsState } = this.props;
    if (
      navData.widgetData.renderID !== prevProps.navData.widgetData.renderID ||
      navData.widgetData.dataID !== prevProps.navData.widgetData.dataID ||
      runActionsState.data.params?.key !== prevProps.runActionsState.data.params?.key ||
      runActionsState.data.params.timestamp !== prevProps.runActionsState.data.params.timestamp
    ) {
      this.fetchData();
    }
  }

  async fetchData() {
    const { runActionsState, navData } = this.props;
    const response = await runDynamicQuery(
      {
        params: {
          ...runActionsState.data.params,
          ...(runActionsState.data.params?.response || {})
        },
        widgetKey: navData.widgetData.key,
        ...(runActionsState.data.params.response || {})
      },
      navData.widgetData
    );

    const shapes = {
      type: 'FeatureCollection',
      crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
      features: response.targets
    };

    this.setState({ data: shapes });
  }

  onClick = (event) => {
    const { shapeData } = event;
    const { id } = shapeData;
    const { runActionsState, navData } = this.props;
    const key = navData.widgetData.events.find(event => event.type === 'onClick').key;
    runActions(key, id, { ...runActionsState.data.params }, null);
  };

  onMapsLoad = () => {
    // Maps loaded event
  };

  render() {
    const { data } = this.state;

    return (
      <div className='widget-container' >
        <WidgetProvider value={this}>
          {
            this.props.navData.widgetData.menu
              ? <MenuBar
                key={`menu-${this.props.navData.widgetData.menu.id}`}
                menu={this.props.navData.widgetData.menu}
                widgetType={this.props.navData.widgetData.widget}
              />
              : null
          }
          <MapsComponent
            locale={JSON.parse(localStorage.getItem('language'))}
            id="maps"
            loaded={this.onMapsLoad}
            width='100%'
            height='100%'
            shapeSelected={this.onClick}>
            <Inject services={[Marker]} />
            <LayersDirective>
              <LayerDirective
                shapeData={data}
                shapePropertyPath="name"
                shapeDataPath="Country"
                // dataSource={datasource}
                shapeSettings={{
                  fill: '#C3E6ED',
                  border: {
                    color: 'black',
                    width: 0.3
                  }
                }}
              />
            </LayersDirective>
          </MapsComponent>
        </WidgetProvider>
      </div>
    );
  }
}

export default MapWidget;
