import {
  WORKSPACE_RENAME,
  WORKSPACE_OPEN,
  WORKSPACE_REMOVE,
  WORKSPACE_SET_ACTIVE_INDEX,
  UPDATE_WORKSPACE_ACTIONS,
  UPDATE_WORKSPACE_WIDGETS,
  WORKSPACE_SET_IS_NEW,
  REMOVE_WORKSPACE_MODAL_WIDGET,
  BREADCRUMB_ADD_PAGE,
  BREADCRUMB_UPDATE_PAGES,
  BREADCRUMB_GO_BACK,
  BREADCRUMB_CLEAR,
  BREADCRUMB_SET_GRID_FILTER,
  CLOSE_MULTIUPLOAD_MODAL,
  OPEN_MULTIUPLOAD_MODAL,
  OPEN_RICHTEXT_MODAL,
  SET_TEXT_SELECTION,
  CLOSE_RICHTEXT_MODAL,
  SET_DIALOG_OPEN,
  SET_MODAL_OPEN,
  SET_CHAT_OPEN,
  SET_CHAT_POSTS,
  SET_CHAT_FILTER_MODE,
  ADD_CHAT_POSTS_TOP,
  ADD_CHAT_POSTS_BOTTOM,
  SET_CHAT_BADGE_VISIBLE,
  SET_CHAT_VISIBLE,
  GET_MODULES,
  GET_NAVIGATION,
  SET_SELECTED_MODULE
} from './types';
import initialState from './initialState';
import { notifyWarning } from '../../components/Toasts/toast.warn';

const shiftHelper = [];

const reducer = (state = initialState, action) => {

  const { instances, activeWorkspace } = state;
  const activeBreadcrumbInstance = instances[state.activeWorkspace.index]?.breadcrumb || { shiftHelper: [], historyPages: [], currentPage: null };
  const activeNavigationInstance = instances[state.activeWorkspace.index].navigation;

  switch (action.type) {

  // Actions

  case WORKSPACE_OPEN: {

    if (instances.length >= 10) {
      notifyWarning('Max. 10 workspaces');
      return state;
    }

    const newWorkspace = {
      name: 'workspace',
      isOpen: true,
      isNewTab: true,
      isActive: false,
      data: action.params || null,
      breadcrumb: action.widgetContext !== 'modules'
        ? activeBreadcrumbInstance
        : {
          shiftHelper: [],
          historyPages: [],
          currentPage: null,
        },
      widgets: action.currentWidgets || null,
      modals: {
        imageDialog: {
          isOpen: false,
          params: null
        },
        confirmModal: {
          isOpen: false,
        },
        title: null,
        content: null,
        imageEvent: null,
        linkEvent: null,
        eventType: null,
        selectedText: '',
        multiUpload: {
          isOpen: false,
          widgetData: {}
        },
        dialog: {
          isOpen: false
        }
      },
      chat: {
        isOpen: false,
        id: null,
        visible: true,
        posts: [],
        filterMode: false,
        badge: {
          visible: false,
          content: null
        }
      },
      navigation: activeNavigationInstance
    };
    

    return {
      ...state,
      instances: instances.concat(newWorkspace),
    };
  }

  case WORKSPACE_REMOVE: {

    const updatedActiveWorkspace = {
      index: activeWorkspace.index === 0 ? action.index : activeWorkspace.index - 1,
      data: null,
    };
    
    return {
      ...state,
      instances: instances.filter((workspace, index) => index !== action.index),
      activeWorkspace: updatedActiveWorkspace,
    };
  }
    
  case WORKSPACE_SET_ACTIVE_INDEX: {
    const updatedInstances = state.instances.map((instance, index) => {
      return {
        ...instance,
        isActive: action.index === index
      };
    });

    return {
      ...state,
      instances: updatedInstances,
      activeWorkspace: {
        index: action.index,
        data: instances[action.index]
      }
    };
  }

  case UPDATE_WORKSPACE_ACTIONS: {
    const updatedInstances = [...instances];
    updatedInstances[action.index].data = action.newWorkspaceAction;

    return {
      ...state,
      instances: updatedInstances,
      activeWorkspace: {
        index: action.index,
        data: action.newTab
      }
    };
  }

  
  case UPDATE_WORKSPACE_WIDGETS: {
    const updatedInstances = [...instances];
    updatedInstances[action.index].widgets = {
      widgets: action.widgets,
      layout: action.layout || instances[action.index].widgets.layout,
      oldActionsParams: {...instances[action.index]?.widgets?.oldActionsParams, ...action?.oldActionsParams}
    };

    return {
      ...state,
      instances: updatedInstances
    };
  }

  case WORKSPACE_SET_IS_NEW: {
    const updatedTabs = [...instances];
    updatedTabs[action.index].isNewTab = action.isNewTab;
    
    return {
      ...state,
      instances: updatedTabs
    };
  }

  case REMOVE_WORKSPACE_MODAL_WIDGET: {
    const updatedWorkspaces = instances.map((instance, index) => {
      if (index === action.index) {
        const updatedWidgets = instance.widgets.widgets.filter(widget => widget.key !== action.key);
        instance.widgets.widgets = updatedWidgets;
      }
      return instance;
    });
  
    return {
      ...state,
      instances: updatedWorkspaces,
    };
  }

  case WORKSPACE_RENAME: {
    const updatedWorkspaces = state.instances.map((instance, index) => {
      if (index === state.activeWorkspace.index) {
        instance.name = action.newName;
      }
      return instance;
    });
  
    return {
      ...state,
      instances: updatedWorkspaces,
    };
  }

  // Breadcrumb

  case BREADCRUMB_UPDATE_PAGES:
    return {
      ...state,
      instances: instances.map((instance, index) => {
        if (index === state.activeWorkspace.inde) {
          return {
            ...instance,
            breadcrumb: {
              shiftHelper: instance.breadcrumb.shiftHelper,
              historyPages: action.historyPagesData,
              currentPage: action.currentPageData,
            }
          };
        }
        return instance;
      }),
    };

  case BREADCRUMB_ADD_PAGE:

    return {
      ...state,
      instances: instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            breadcrumb: {
              shiftHelper: instance.breadcrumb.historyPages.length >= 10 ? [ instance.breadcrumb.historyPages.shift(), ...instance.breadcrumb.shiftHelper ]: instance.breadcrumb.shiftHelper,
              historyPages: [...instance.breadcrumb.historyPages, action.data],
              currentPage: action.data
            }
          };
        }
        return instance;
      }),
    };

  case BREADCRUMB_GO_BACK:{

    const newHistory = activeBreadcrumbInstance.historyPages.splice(0, activeBreadcrumbInstance.historyPages.length - (activeBreadcrumbInstance.historyPages.length - action.pageIndex - 1));

    while (newHistory.length < 10 && activeBreadcrumbInstance.shiftHelper.length > 0) {
      newHistory.unshift(activeBreadcrumbInstance.shiftHelper.shift());
    }
  
    return {
      ...state,
      instances: instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            breadcrumb: {
              shiftHelper: activeBreadcrumbInstance.shiftHelper,
              historyPages: newHistory,
              currentPage: newHistory[newHistory.length - 1]
            }
          };
        }
        return instance;
      }),
    };
  }

  case BREADCRUMB_CLEAR:
    return {
      ...state,
      instances: instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            breadcrumb: {
              shiftHelper: shiftHelper,
              historyPages: [],
              currentPage: []
            }
          };
        }
        return instance;
      }),
    };

  case BREADCRUMB_SET_GRID_FILTER: {
    return {
      ...state,
      instances: state.instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            breadcrumb: {
              ...instance.breadcrumb,
              historyPages: instance.breadcrumb.historyPages.map((page, pageIndex) => {
                if (pageIndex === instance.breadcrumb.historyPages.length - 1) {
                  return {
                    ...page,
                    grid: {
                      filterValues: action.filterValues,
                      key: action.key,
                    },
                  };
                }
                return page;
              }),
            },
          };
        }
        return instance;
      }),
    };
  }


  // Modals

  case OPEN_MULTIUPLOAD_MODAL:
    return {
      ...state,
      instances: state.instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            modals: {
              ...instance.modals, 
              multiUpload: {
                isOpen: true,
                widgetData: action.widgetData
              }
            },
          };
        }
        return instance;
      }),
    };


  case CLOSE_MULTIUPLOAD_MODAL:
    return {
      ...state,
      instances: state.instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            modals: {
              ...instance.modals, 
              multiUpload: {
                isOpen: false
              }
            },
          };
        }
        return instance;
      }),
    };


  case SET_TEXT_SELECTION:
    return {
      ...state,
      instances: state.instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            modals: {
              ...instance.modals, 
              selectedText: action.selectedText
            },
          };
        }
        return instance;
      }),
    };
    

  case OPEN_RICHTEXT_MODAL:
    return {
      ...state,
      instances: state.instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            modals: {
              ...instance.modals, 
              imageDialog: {
                isOpen: true,
                params: action.params
              },
              title: action.title,
              content: action.content,
              imageEvent: action.imageEvent,
              linkEvent: action.linkEvent,
              diagramEvent: action.diagramEvent,
              eventType: action.eventType
            },
          };
        }
        return instance;
      }),
    };
  

  case CLOSE_RICHTEXT_MODAL:
    return {
      ...state,
      instances: state.instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            modals: {
              ...instance.modals, 
              imageDialog: {
                isOpen: false,
                params: null
              },
              title: null,
              content: null,
              imageEvent: null,
              linkEvent: null,
              eventType: null
            },
          };
        }
        return instance;
      }),
    };

  case SET_DIALOG_OPEN:
    return {
      ...state,
      instances: state.instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            modals: {
              ...instance.modals, 
              dialog: {
                isOpen: action.isOpen
              }
            },
          };
        }
        return instance;
      }),
    };

  case SET_MODAL_OPEN:
    return {
      ...state,
      instances: state.instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            modals: {
              ...instance.modals, 
              modal: {
                isOpen: action.isOpen
              }
            },
          };
        }
        return instance;
      }),
    };

    // Chat
  case SET_CHAT_OPEN:
    return {
      ...state,
      instances: state.instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            chat: {
              ...instance.chat, 
              isOpen: action.isOpen,
              id: action.id
            },
          };
        }
        return instance;
      }),
    };

  case SET_CHAT_POSTS:
    return {
      ...state,
      instances: state.instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            chat: {
              ...instance.chat, 
              posts: action.posts
            },
          };
        }
        return instance;
      }),
    };

  case SET_CHAT_FILTER_MODE:
    return {
      ...state,
      instances: state.instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            chat: {
              ...instance.chat, 
              filterMode: action.filterMode
            },
          };
        }
        return instance;
      }),
    };

  case ADD_CHAT_POSTS_TOP:
    return {
      ...state,
      instances: state.instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            chat: {
              ...instance.chat, 
              posts: [...action.loadedPosts, ...instance.chat.posts]
            },
          };
        }
        return instance;
      }),
    };

  case ADD_CHAT_POSTS_BOTTOM:
    return {
      ...state,
      instances: state.instances.map((instance) => {
        if (action.loadedPosts.chatId === instance.chat.id) {
          return {
            ...instance,
            chat: {
              ...instance.chat, 
              posts: [...instance.chat.posts, action.loadedPosts]
            },
          };
        }
        return instance;
      }),
    };

  case SET_CHAT_BADGE_VISIBLE:
    return {
      ...state,
      badge: {
        visible: action.isVisible,
        content: action.content
      }
    };

  case SET_CHAT_VISIBLE:
    return {
      ...state,
      instances: state.instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            chat: {
              ...instance.chat, 
              visible: action.isVisible
            },
          };
        }
        return instance;
      }),
    };

    // Modules
  case GET_MODULES:
    return {
      ...state,
      instances: state.instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            navigation: {
              ...instance.navigation, 
              modules: {...instance.navigation.modules, ...action.data}
            },
          };
        }
        return instance;
      }),
    };

  case SET_SELECTED_MODULE:
    return {
      ...state,
      instances: state.instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            navigation: {
              ...instance.navigation, 
              modules: {...instance.navigation.modules, selectedModule: action.key}
            },
          };
        }
        return instance;
      }),
    };

    // Navigation treeView
  case GET_NAVIGATION:
    return {
      ...state,
      instances: state.instances.map((instance, index) => {
        if (index === state.activeWorkspace.index) {
          return {
            ...instance,
            navigation: {
              ...instance.navigation, 
              treeData: action.data
            },
          };
        }
        return instance;
      }),
    };
  
  default:
    return state;
  }
};

export default reducer;
