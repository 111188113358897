import React from 'react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { notifyError } from '../Toasts/toast.error';
import { writeErrorToLogFile } from '../../utils/logErrorLogfile';
import './styles.css';

class ErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      reloadCount: 0,
      writeLog: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  handleReloadClick = () => {
    this.setState((prevState) => ({
      reloadCount: prevState.reloadCount + 1,
    }));
  };

  componentDidUpdate() {
    const { error, reloadCount, writeLog } = this.state;

    if (error && reloadCount === 0) {
      notifyError(`An error occurred at ${this.props.currentPage?.widget} (key:${this.props.currentPage?.renderID}) . Please try to reload`);
      this.setState((prevState) => ({
        reloadCount: prevState.reloadCount + 1,
      }));
    }

    if (error && reloadCount === 3 && !writeLog) {
      writeErrorToLogFile(error.message, error.stack).then(() => {
        this.setState({ writeLog: true });
      });
    }
  }

  render() {
    const { error, reloadCount } = this.state;

    if (error && reloadCount < 3) {
      return (
        <div className="boundary-container">
          <ButtonComponent
            cssClass="e-custom"
            iconCss="e-icons e-large e-refresh"
            style={{ textTransform: 'none' }}
            onClick={this.handleReloadClick}
          />
        </div>
      );
    } else if (error && reloadCount === 3) {
      return (
        <div className="boundary-container-error">
          {this.props.currentPage && (
            <p className="error-boundary-widget">Error at {this.props.currentPage?.widget} (key: {this.props.currentPage?.renderID}): </p>
          )}
          <p className="error-boundary-message">{error?.message}</p>
          <p className="error-boundary-stack">{error?.stack}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
