import React from 'react';
import Handlebars from 'handlebars';
import { marked } from 'marked';
import MenuBar from '../../../components/MenuBar/MenuBar';
import { run as runActions } from '../../../store/actions/run';
import { service as linkService } from './service/linkService';
import { HtmlEditor, Inject, RichTextEditorComponent, Resize, MarkdownEditor } from '@syncfusion/ej2-react-richtexteditor';
import { runDynamicQuery } from '../../../backend/query/run.dynamicQuery';
import { WidgetProvider } from '../../WidgetContext';
import './style.css';

class View extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      content: ''
    };
  }

  viewInstance: any;

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.navData.widgetData.dataID !== this.props.navData.widgetData.dataID ||
      prevProps.navData.widgetData.timestamp !== this.props.navData.widgetData.timestamp
    ) {
      this.fetchData();
    }
  }

  fetchData() {
    const { navData, runActionsState } = this.props;

    runDynamicQuery({ ...runActionsState, widgetKey: navData.renderID }, navData.widgetData)
      .then((response) => {
        switch (response.type) {
        case 'HTML':{
          const template = Handlebars.compile(response?.content ?? ' ');
          const result = template(response?.data);
          this.setState({ content: result });
          break;
        }
        case 'Markdown':
          this.setState({ content: response.content ? marked(response.content) : ' ' });
          break;
        default:
          this.setState({ content: response?.content ?? ' ' });
          break;
        }
        return;
      })
      .then(() => {
        this.setClickEventLink();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  setClickEventLink() {
    const regexExpUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    let uuid;

    const eventKey = this.props.navData.widgetData.events?.find((event) => event?.type === 'onClick')?.key;
    setTimeout(() => {
      document.querySelectorAll('a').forEach((element) => {
        if (element.classList.contains('disable-link')) {
          element.classList.remove('disable-link');
        }

        if (element.href) {
          element.addEventListener('click', (event) => {
            event.preventDefault();
            const url = new URL(element.href);
            uuid = url.pathname.slice(1);
            if (regexExpUUID.test(uuid)) {
              event.preventDefault();
              const params = {
                uuid: uuid
              };
              linkService('system', params)
                .then((response) => {
                  runActions(eventKey, response.id, null);
                })
                .catch((error) => console.log(error));
            } else {
              window.open(url.href, '_blank');
              return null;
            }
          });
        }
      });
    }, 500);
  }

  render() {
    const { navData } = this.props;
    const { content } = this.state;

    if (!content) return null;

    return (
      <div className='widget-container'>
        <WidgetProvider value={this}>
          {navData.widgetData.menu ? (
            <MenuBar key={`menu-${navData.widgetData.menu.id}`} menu={navData.widgetData.menu} widgetType={navData.widgetData.widget} />
          ) : null}
          {/* <h3 className='widget-label'>{t(navData.label)} </h3> */}
          <RichTextEditorComponent
            readonly={true}
            height={'calc(100% - 50px)'}
            ref={(richtexteditor) => {
              this.viewInstance = richtexteditor;
            }}
            value={content}
            editorMode={'HTML'}
          >
            <Inject services={[HtmlEditor, MarkdownEditor, Resize]} />
          </RichTextEditorComponent>
          <style>{navData.widgetData.code}</style>
        </WidgetProvider>
      </div>
    );
  }
}

export default View;