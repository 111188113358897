import './styles.css';
import * as React from 'react';
import { SnapConstraints } from '@syncfusion/ej2-react-diagrams';
import { ItemDirective, ItemsDirective, ToolbarComponent, ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import { withRouterHooks } from '../../routes/router.hooks';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ColorPickerComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';

class DiagramToolBarComponent extends React.Component<any, any> {

  dropdownInstance: any;
  hSpacingInstance: any;
  vSpacingInstance: any;


  alignNodes(args: ClickEventArgs) {
    this.props.diagramInstance.align(args, this.props.diagramInstance.selectedItems.nodes, 'Selector');
    this.props.diagramInstance.dataBind();
  }


  alignAnnotationsHorizontal(args: ClickEventArgs) {
    this.props.diagramInstance.selectedItems.nodes.map(
      node => {
        node.annotations.map(
          annotation => {
            annotation.horizontalAlignment = args;
          }
        );
      }
    );
    this.props.diagramInstance.dataBind();
  }


  alignAnnotationsVertical(args: ClickEventArgs) {
    this.props.diagramInstance.selectedItems.nodes.map(
      node => {
        node.annotations.map(
          annotation => {
            annotation.verticalAlignment = args;
          }
        );
      }
    );
    this.props.diagramInstance.dataBind();
  }


  toggleSnapping(args) {
    if (args.target.checked) {
      this.props.diagramInstance.snapSettings = { constraints: SnapConstraints.All };
    } else {
      this.props.diagramInstance.snapSettings = { constraints: SnapConstraints.None };
    }
    this.props.diagramInstance.dataBind();
  }


  toggleHierarchicalView(args) {
    this.props.$this.setState({
      nodes: JSON.parse(this.props.diagramInstance.saveDiagram()).nodes,
      connectors: JSON.parse(this.props.diagramInstance.saveDiagram()).connectors,
    });
    if (args.target.checked) {
      this.props.$this.setState({ hierarchicalView: 'HierarchicalTree' });
      this.hSpacingInstance.enabled = true;
      this.vSpacingInstance.enabled = true;
      this.dropdownInstance.disabled = false;
    } else {
      this.props.$this.setState({ hierarchicalView: null });
      this.hSpacingInstance.enabled = false;
      this.vSpacingInstance.enabled = false;
      this.dropdownInstance.disabled = true;
    }
  }

  toggleGrabableView(args) {
    if (args.target.checked) {
      this.props.$this.setState({ grabbable: true });
    } else {
      this.props.$this.setState({ grabbable: false });
    }
  }



  changeBackgroundColor(args) {
    this.props.diagramInstance.selectedItems.nodes.map(
      node => {
        node.style.fill = args.value;
      }
    );
    this.props.diagramInstance.dataBind();
  }


  changeTextColor(args) {
    this.props.diagramInstance.selectedItems.nodes.map(
      node => {
        node.annotations.map(
          annotation => {
            annotation.style.color = args.value;
          }
        );
      }
    );
    this.props.diagramInstance.dataBind();
  }


  bringToFront() {
    this.props.diagramInstance.bringToFront();
  }

  sendToBack() {
    this.props.diagramInstance.sendToBack();
  }

  bringForward() {
    this.props.diagramInstance.bringForward();
  }

  sendBackward() {
    this.props.diagramInstance.sendBackward();
  }


  orientationSelect(args: any) {
    switch (args.item.properties.text) {
    case 'TopToBottom':
      return this.updatelayout(args.item.properties.text);
    case 'BottomToTop':
      return this.updatelayout(args.item.properties.text);
    case 'LeftToRight':
      return this.updatelayout(args.item.properties.text);
    case 'RightToLeft':
      return this.updatelayout(args.item.properties.text);
    default:
      return null;
    }
  }

  updatelayout(orientation) {
    this.props.diagramInstance.layout.orientation = orientation;
    this.props.diagramInstance.dataBind();
    this.props.diagramInstance.doLayout();
  }



  render() {
    if (!this.props.navData || !this.props.diagramInstance) return null;

    return (
      <ToolbarComponent id={`diagram-toolbar-${this.props.navData.widgetData.key}`} className='diagram-toolbar'>
        <ItemsDirective>
          <ItemDirective
            template={() => {
              return (
                <CheckBoxComponent
                  label='show grid'
                  checked={true}
                  onChange={this.toggleSnapping.bind(this)}
                />);
            }}
          />
          <ItemDirective type='Separator' />
          <ItemDirective prefixIcon='e-align-bottom-3' cssClass='rotate-90' click={this.alignNodes.bind(this, 'Left')} />
          <ItemDirective prefixIcon='e-align-top-3' cssClass='rotate-90' click={this.alignNodes.bind(this, 'Right')} />
          <ItemDirective prefixIcon='e-align-middle-3' cssClass='rotate-90' click={this.alignNodes.bind(this, 'Center')} />
          <ItemDirective type='Separator' />
          <ItemDirective prefixIcon='e-align-top-3' click={this.alignNodes.bind(this, 'Top')} />
          <ItemDirective prefixIcon='e-align-bottom-3' click={this.alignNodes.bind(this, 'Bottom')} />
          <ItemDirective prefixIcon='e-align-middle-3' click={this.alignNodes.bind(this, 'Middle')} />
          <ItemDirective type='Separator' />
          <ItemDirective prefixIcon='e-align-left' click={this.alignAnnotationsHorizontal.bind(this, 'Right')} />
          <ItemDirective prefixIcon='e-align-right' click={this.alignAnnotationsHorizontal.bind(this, 'Left')} />
          <ItemDirective prefixIcon='e-align-center' click={this.alignAnnotationsHorizontal.bind(this, 'Center')} />
          <ItemDirective type='Separator' />
          <ItemDirective prefixIcon='e-align-left' cssClass='rotate-90' click={this.alignAnnotationsVertical.bind(this, 'Bottom')} />
          <ItemDirective prefixIcon='e-align-right' cssClass='rotate-90' click={this.alignAnnotationsVertical.bind(this, 'Top')} />
          <ItemDirective prefixIcon='e-align-center' cssClass='rotate-90' click={this.alignAnnotationsVertical.bind(this, 'Center')} />
          <ItemDirective type='Separator' />
          <ItemDirective prefixIcon='e-bring-to-front' click={this.bringToFront.bind(this)} />
          <ItemDirective prefixIcon='e-send-to-back' click={this.sendToBack.bind(this)} />
          <ItemDirective prefixIcon='e-bring-forward' click={this.bringForward.bind(this)} />
          <ItemDirective prefixIcon='e-send-backward' click={this.sendBackward.bind(this)} />
          <ItemDirective type='Separator' />
          <ItemDirective
            template={() => {
              return (
                <div>
                  <span className="e-label diagram-toolbar label">background:</span>
                  <ColorPickerComponent
                    value={'#cfcfcf'}
                    change={this.changeBackgroundColor.bind(this)}
                  />
                </div>
              );
            }} />
          <ItemDirective
            template={() => {
              return (
                <div>
                  <span className="e-label diagram-toolbar label">text:</span>
                  <ColorPickerComponent
                    value={'#000'}
                    change={this.changeTextColor.bind(this)}
                  />
                </div>
              );
            }}
          />
          <ItemDirective type='Separator' />
          <ItemDirective template={() => {
            return (
              <div>
                <DropDownButtonComponent
                  id="dropdown-toolbar-diagram"
                  items={[
                    {
                      text: 'TopToBottom'
                    },
                    {
                      text: 'BottomToTop'
                    },
                    {
                      text: 'LeftToRight'
                    },
                    {
                      text: 'RightToLeft'
                    }]
                  }
                  select={this.orientationSelect.bind(this)}
                  ref={(dropdownRef) => (this.dropdownInstance = dropdownRef)}
                  disabled={true}
                >
                  orientation
                </DropDownButtonComponent>
              </div>
            );
          }} />
          <ItemDirective type='Separator' />
          <ItemDirective
            template={() => {
              return (
                <div>
                  <NumericTextBoxComponent
                    enabled={false}
                    ref={(hSpacingRef) => (this.hSpacingInstance = hSpacingRef)}
                    id="hSpacing"
                    style={{ width: '100px' }}
                    min={20}
                    max={100}
                    step={2}
                    value={40}
                    change={() => {
                      this.props.diagramInstance.layout.horizontalSpacing = Number(
                        this.hSpacingInstance.value
                      );
                      this.props.diagramInstance.dataBind();
                    }}
                  />
                </div>
              );
            }} />
          <ItemDirective
            template={() => {
              return (
                <div>
                  <NumericTextBoxComponent
                    enabled={false}
                    ref={(vSpacingRef) => (this.vSpacingInstance = vSpacingRef)}
                    id="vSpacing"
                    style={{ width: '100px' }}
                    min={20}
                    max={100}
                    step={2}
                    value={30}
                    change={() => {
                      this.props.diagramInstance.layout.verticalSpacing = Number(
                        this.vSpacingInstance.value
                      );
                      this.props.diagramInstance.dataBind();
                    }}
                  />
                </div>
              );
            }} />
          <ItemDirective
            template={() => {
              return (
                <CheckBoxComponent
                  label='hierarchical'
                  disabled={this.props.isLane ?? true}
                  checked={false}
                  onChange={this.toggleHierarchicalView.bind(this)}
                />);
            }}
          />
          <ItemDirective
            template={() => {
              return (
                <CheckBoxComponent
                  label='tangible'
                  checked={false}
                  onChange={this.toggleGrabableView.bind(this)}
                />);
            }}
          />
        </ItemsDirective>
      </ToolbarComponent>
    );
  }
}

export default withRouterHooks(DiagramToolBarComponent);