import store from '../../../store/store';
import { run as runActions } from '../../../store/actions/run';
import {
  goBackBreadcrumbAction,
  updateBreadcrumbItems,
} from '../../../store/workspaces/action.breadcrumb';

export const historyBack = async (params) => {
  const itemIndex = parseInt(params?.step) || 0;
  const breadcrumbHistoryPages = store.getState().workspaces.instances[store.getState().workspaces.activeWorkspace.index].breadcrumb.historyPages;
  const newHistory = await breadcrumbHistoryPages.slice(0, -itemIndex);
  const newCurrentPage =
    itemIndex > 0
      ? newHistory[newHistory.length - 1]
      : breadcrumbHistoryPages[breadcrumbHistoryPages.length - 1];

  if (!newCurrentPage) {
    return null;
  }

  if (itemIndex > 0) {    
    await store.dispatch(updateBreadcrumbItems(newHistory, newCurrentPage));
    await store.dispatch(goBackBreadcrumbAction(itemIndex));
  }

  const { key, id, source, relation, target } = newCurrentPage;
  runActions(key, id, { source, relation, target });
};
