import { runStaticQuery } from '../../../../../../../backend/query/run.staticQuery';
import { jwtDecrypt } from '../../../../../../../backend/services/tools/service.jwtDecrypt';


export const getData = async ($this: any) => {

  let userUUID = null;

  await jwtDecrypt(JSON.parse(localStorage.getItem('session')))
    .then((response) => {
      userUUID = response.data.user.uuid;
    })
    .catch((error) => {
      console.log('jwtDecrypt error', error);
    });

  runStaticQuery(
    $this.props.tabType === 'existing' ? 'existingImage' : 'imagesQuery',
    {
      ...$this.props.params,
      user: userUUID
    }
  )
    .then((response) => {
      console.log(
        `%c StoredProcedure (${$this.props.tabType === 'existing' ? 'getExistingImages' : 'getImages'}) %c`,
        'background: #F9E79F; color: #000',
        'background: transparent; color: black;',
        {
          params: $this.props.params,
          response: response
        }
      );
      const arr = Object.values(response.elements);
      const results = arr.filter(element => {
        return element !== null;
      });
      return $this.setState({ gridData: results });

    });
};