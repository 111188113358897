import axios from 'axios';
import { notifyError } from '../../../../components/Toasts/toast.error';
import { notifyWarning } from '../../../../components/Toasts/toast.warn';
import { fileDownload } from './function.download';

export const fileView = async (params: any) => {

  const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}services/file/view`, {
    responseType: 'json',
    params: { absolutePath: params.absolutePath ?? params?.params?.absolutePath },
    headers: { Accept: 'application/pdf' },
  });

  if (response.data.error || !response.data.type) {
    notifyError(response.data.message);
    return null;
  }

  const window_img = window.open('about:blank');
  const { absolutePath, originalName } = params;
  const container = document.createElement('div');
  container.style.cssText = 'height: 100%; width: 100%; display: flex; align-items: center; justify-content: center;';

  switch (response.data.type) {
  case 'image/gif':
  case 'image/jpeg':
  case 'image/png':
  case 'image/svg':
  case 'image/webp':
    setTimeout(() => {
      const img = document.createElement('img');
      img.src = `data:${response.data.type};base64,${response.data.data}`;
      img.style.maxHeight = '100%';
      container.appendChild(img);
      window_img.document.body.appendChild(container);
    }, 100);
    break;
  case 'application/pdf':
    setTimeout(() => {
      const iframe = document.createElement('iframe');
      iframe.src = `data:application/pdf;base64,${response.data.data}`;
      iframe.style.cssText = 'width: 100%; height: 100%; border: none;';
      container.appendChild(iframe);
      window_img.document.body.appendChild(container);
    }, 100);
    break;
  case 'video/mp4':
    setTimeout(() => {
      const video = document.createElement('video');
      video.src = `data:video/mp4;base64,${response.data.data}`;
      video.style.cssText = 'max-width: 100%; max-height: 100%;';
      video.setAttribute('controls', 'true');
      video.setAttribute('autoplay', 'true');
      container.appendChild(video);
      window_img.document.body.appendChild(container);
    }, 100);
    break;
  default:
    if (!absolutePath) {
      return notifyWarning('Absolute path is missing');
    }
    if (!originalName) {
      return notifyWarning('Original name is missing');
    }
    await fileDownload({ ...response.data, absolutePath, originalName });
  }
};
