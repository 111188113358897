import React from 'react';
import { DatePicker, DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import moment from 'moment-timezone';

import { withTranslation } from 'react-i18next';

class DateField extends React.Component<any, any> {

  private datepickerInstance: DatePicker;
  private dateValue: Date = new Date(moment((parseInt(this.props.value))).format('YYYY-MM-DD'));
  flag = true;
  initvalue;


  onChange = (args: any) => {
    const { setNewValue } = this.props;
    setNewValue(args.element.value);
  };

  setInitData = () => {
    const { value, setNewValue } = this.props;
    if (value) {
      if (this.flag === true) {
        setTimeout(function () {
          setNewValue(value);
        }, 500);
      }
      this.flag = false;
    }
  };

  render() {
    const { field, isLocked, value, hiddenClass } = this.props;

    this.setInitData();
    if(value){
      this.initvalue = value;
    }

    return (
      <div className={`form-input-container ${hiddenClass}`}>
        <label className='form-input-label'>{this.props.t(field.label)}</label>
        <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
        <div
          className='form-input-element '
          // style={{ backgroundColor: setColorField() }}
        >
          <DatePickerComponent
            ref={(scope) => { (this.datepickerInstance as DatePicker | null) = scope; }}
            className='form-input-element'
            name="date"
            value={field.type === 'timestamp' ? this.dateValue : this.initvalue}
            change={this.onChange.bind(this)}
            enabled={!isLocked}
            format='yyyy-MM-dd'
          />
        </div>
      </div>
    );
  }
}
export default (withTranslation()(DateField));