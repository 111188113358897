import React from 'react';
import { withRouterHooks } from '../../../routes/router.hooks';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  ContextMenu,
  Filter,
  Inject,
  Page,
  Selection,
  ExcelExport,
  PdfExport,
  Resize,
  Toolbar,
  CommandColumn,
  InfiniteScroll,
  Sort
} from '@syncfusion/ej2-react-grids';
import { withTranslation } from 'react-i18next';
import { setGridData } from '../services/setGridData';
import { insertFilterValues, setFilterValues } from '../services/setFilterValues';
// import { setDataCounter } from '../services/dataCounter/setDataCounter';
import { WidgetProvider } from '../../WidgetContext';
import MenuBar from '../../../components/MenuBar/MenuBar';
import { commandClick } from './events/commandClick';
import { connect } from 'react-redux';
import { setCustomContextMenu } from '../services/setContextMenu';
import { cellSelecting } from './events/cellSelecting';
import { contextMenuClick } from '../services/contextMenuClick';
import { getSettingsView } from './settings';
// import DataCounter from '../services/dataCounter/dataCounter';
import { setKeyDownRemoveFilter } from '../services/setKeydownEvent';


class GridWidgetView extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      gridData: null,
      dataCounterString: ''
    };
  }

  gridViewInstance: GridComponent | null;

  componentDidMount() {
    setGridData(this)
      .then(() => {
        insertFilterValues(this);
      });
  }

  created = () => {
    window.addEventListener('keydown', (event) => setKeyDownRemoveFilter(event, this.gridViewInstance));
  };

  componentDidUpdate(prevProps) {
    const { navData } = this.props;

    if (prevProps.navData.renderID !== navData.renderID || prevProps.navData.widgetData.dataID !== navData.widgetData.dataID || prevProps.navData.widgetData.timestamp !== navData.widgetData.timestamp) {
      this.setState({ gridData: null });
      setGridData(this);
    }
  }

  componentWillUnmount() {
    this.setState({ gridData: null, dataCounterString: '' });
    window.removeEventListener('keydown', (event) => setKeyDownRemoveFilter(event, this.gridViewInstance));
  }


  actionComlepte = async (args) => {
    const { navData, runActionsState } = this.props;
    if (runActionsState && navData) {
      await setFilterValues(args, navData.widgetData.key);
      // await setDataCounter(args, this, this.props.t);
    }
  };

  setColumnTemplate = (row) => {
    const { elements = [] } = this.props.navData.widgetData.menu?.inline ?? {};

    const commandItems = elements.map(
      (button) => {
        const name = button.name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');

        const disabled = row.buttons?.filter(
          obj => {
            return obj.name === name;
          }
        )[0].disabled ?? false;

        const key = button.events.filter(
          obj => {
            return obj.type === 'onClick';
          }
        )[0].key ?? null;

        const params = {
          key: key,
          id: row.id
        };

        return (
          <button
            key={`${name}-${button.id}`}
            className={`e-control e-btn ${disabled ? 'e-disabled' : ''}`}
            onClick={(e) => commandClick(params, e)}
            disabled={disabled}
          >
            <span
              className={`e-icons e-${button.icon.name}`}
            >
              {button.label}
            </span>
          </button>

        );
      }
    );

    return commandItems;
  };

  render() {
    const { navData, runActionsState, globalKeyEvent } = this.props;

    if (!navData?.widgetData || !this.state.gridData) {
      return null;
    }

    const settings = getSettingsView(navData);

    return (
      <div className='widget-container'>
        <WidgetProvider value={this}>
          {
            navData.widgetData.menu
              ? <MenuBar
                key={`menu-${navData.widgetData.menu.id}`}
                menu={navData.widgetData.menu}
              />
              : null
          }
          {
            navData.widgetData.label !== '' ? <h3 className='widget-label'>{this.props.t(navData.widgetData.label)} </h3> : ''
          }
          <div className="widget-content">
            <GridComponent
              locale={JSON.parse(localStorage.getItem('language'))}
              ref={g => this.gridViewInstance = g}
              id={`grid-${navData.widgetData.key}`}
              key={`grid-${navData.widgetData.key}`}
              height={navData.widgetData.height ? navData.widgetData.height : '100%'}
              rowHeight={28}
              dataSource={this.state.gridData}
              allowExcelExport={true}
              allowPdfExport={true}
              allowFiltering={true}
              allowResizing={true}
              allowSelection={true}
              allowSorting={true}
              allowPaging={navData.widgetData.allowPaging}
              enableInfiniteScrolling={!navData?.widgetData?.allowPaging}
              selectionSettings={settings.selectionSettings ?? {}}
              filterSettings={settings.filterSettingsMenu}
              contextMenuItems={setCustomContextMenu(navData)}
              pageSettings={navData?.widgetData?.allowPaging ? settings.pageSettings : settings.pageSettingsInfinite}
              // infiniteScrollSettings={!this.props.allowPaging || gridRenderData.type !== 'update' ? this.infiniteOptions : null}
              cellSelecting={(args) => cellSelecting(args, navData, globalKeyEvent, runActionsState)}
              contextMenuClick={(args) => contextMenuClick(args, this.gridViewInstance, navData ? setCustomContextMenu(navData) : null)}
              // commandClick={commandClick.bind(this)}
              actionComplete={this.actionComlepte.bind(this)}
              created={this.created.bind(this)}
            >
              <ColumnsDirective>
                {navData.widgetData.columns.map((column) => {
                  switch (column.type) {
                  case 'boolean':
                    return null;
                  case 'code':
                    return (
                      <ColumnDirective
                        key={`field-${column.id}`}
                        headerText={this.props.t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                        width={column.minWidth}
                        minWidth={column.minWidth}
                        maxWidth={column.maxWidth}
                        customAttributes={{ class: [settings.customAttributes.class, 'code-cell'] }}
                        // commands={navData ? setCommandItems(navData) : null}
                        template={navData ? this.setColumnTemplate.bind(this) : null}
                        textAlign={'Center'}
                      />
                    );
                  default:
                    return (
                      <ColumnDirective
                        key={`field-${column.id}`}
                        field={column.name}
                        width={column.minWidth}
                        minWidth={column.minWidth}
                        maxWidth={column.maxWidth}
                        // customAttributes={this.customAttributes}
                        customAttributes={column.name === 'id' ? { class: [settings.customAttributes.class, 'id-cell'] } : settings.customAttributes}
                        headerText={this.props.t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                        filter={settings.filterMenu}
                        visible={!column.hide}
                        disableHtmlEncode={false}
                        isPrimaryKey={column.name === 'id'}
                        type={'string'}
                      />
                    );
                  }
                })}
              </ColumnsDirective>
              <Inject services={[ContextMenu, Filter, Page, ExcelExport, PdfExport, Selection, Resize, Toolbar, CommandColumn, InfiniteScroll, Sort]} />
            </GridComponent>
          </div>
          {/* {!navData?.widgetData?.allowPaging && (
            <DataCounter
              counter={this.state.dataCounterString}
            />
          )} */}
        </WidgetProvider>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  globalKeyEvent: state.keyEvent,
  runActionsState: state.actions
});


export default connect(
  mapStateToProps,
  null
)(withRouterHooks(withTranslation()(GridWidgetView)));

