import { runStaticQuery } from '../../../../../../../backend/query/run.staticQuery';


export const getData = async ($this: any) => {
  runStaticQuery(
    $this.props.tabType === 'existing' ? 'existingPages' : 'pages',
    $this.props.params
  )
    .then((response) => {
      console.log(
        `%c StoredProcedure (${$this.props.tabType === 'existing' ? 'getExistingPages' : 'getPages'}) %c`,
        'background: #F9E79F; color: #000',
        'background: transparent; color: black;',

        {
          params: $this.props.params,
          response: { uuid: response.uuid }
        }
      );
      const arr = Object.values(response.elements ?? []);
      const results = arr.filter(element => {
        return element !== null;
      });
      return $this.setState({ gridData: results });

    });
};