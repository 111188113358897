import axios from 'axios';
import download from 'js-file-download';
import { logger } from '../../../../utils/logger';

const fileDownload = async (params: any) => {
  return axios.get(
    process.env.REACT_APP_BACKEND_URL + 'services/file/download',
    {
      responseType: 'blob',
      params: { absolutePath: params.absolutePath ?? params.params?.absolutePath },
      headers: {
        Accept: 'application/pdf',
      }
    }
  ).then(
    (response) => {
      download(response.data, params.originalName ?? params.params?.originalName);
    }
  ).catch(
    (error) => {
      logger.params({ params }).error('failed to call file/download', error);
    }
  );
};

const base64Download = async (params: any) => {
  try {
    const {
      data,
      name
    } = params.file;

    const buffer = await Buffer.from(data, 'base64');
    await download(buffer, name);

  } catch {
    (error) => {
      logger.params({ params }).error('failed to call file/base64Download', error);
    };
  }
};

export { fileDownload, base64Download };