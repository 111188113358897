import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const DefaultField: FC<any> = ({ field }) => {
  // TODO value type anzeigen
  const { t } = useTranslation();
    
  return (
    <div className='form-input-container'>
      <label className='form-input-label'>{t(field.label)}</label>
      <div>Field Type nicht vorhanden</div>
    </div>
  );
};
export default DefaultField;