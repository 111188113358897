import { addPostsScrollTop } from '../../../store/workspaces/action.messenger';
import { getPosts } from '../../../backend/services/chat/post.get';
import store from '../../../store/store';

export const getPostsDataInfiniteScroll = async (posts, targetDivRef) => {

  const params = {
    id: posts[0]?.id,
    limit: 2
  };

  try {
    const response = await getPosts(params);
    const loadedMessages = response?.message;

    if (loadedMessages.length !== 1) {
      const currentScrollHeight = targetDivRef?.current?.scrollHeight;
      const currentScrollTop = targetDivRef?.current?.scrollTop;

      await store.dispatch(addPostsScrollTop([...loadedMessages.slice(0, loadedMessages.length - 1)]));

      const newScrollHeight = targetDivRef?.current?.scrollHeight;
      const scrollHeightDiff = newScrollHeight - currentScrollHeight;

      targetDivRef.current.scrollTop = currentScrollTop + scrollHeightDiff - 199;
    }
  } catch (error) {
    console.log('Error getting posts: ', error);
  }
};