import React from 'react';
import Card from './Gallery.card';
import { run as runActions } from '../../store/actions/run';
import { runDynamicQuery } from '../../backend/query/run.dynamicQuery';
import './styles.css';

class GalleryWidget extends React.Component<any, any> {

  constructor(props) {
    super(props);

    this.state = {
      imageData_data: null
    };
  }

  componentDidMount() {
    const { navData, runActionsState } = this.props;

    if (navData) {
      runDynamicQuery({ widgetKey: navData.widgetData.key, ...runActionsState }, navData.widgetData)
        .then((response: any) => {
          this.setState({ imageData_data: response.targets });
        });
    }
  }

  componentDidUpdate(prevProps) {
    const { navData, runActionsState } = this.props;

    if (prevProps.navData.widgetData.renderID !== navData.widgetData.renderID || prevProps.navData.widgetData.dataID !== navData.widgetData.dataID || prevProps.runActionsState.key !== runActionsState.key || prevProps.runActionsState.timestamp !== runActionsState.timestamp) {

      runDynamicQuery({ widgetKey: navData.widgetData.key, ...runActionsState }, navData.widgetData)
        .then((response: any) => {
          this.setState({ imageData_data: response.targets });
        });
    }
  }

  handleClick = async (imageID) => {
    const { navData, runActionsState } = this.props;
    runActions(navData.widgetData.events.find(event => event.type === 'onClick').key, imageID, runActionsState);
  };

  setCounter = () => {
    const string = this.state.imageData_data.length === 1 ? ' item' : ' items';
    return `(${this.state.imageData_data.length} ${string})`;
  };


  render() {
    const { imageData_data } = this.state;

    if (!imageData_data) {
      return null;
    }

    return (
      <>
        <div className="gallery">
          <div className="gallery-container wrap">
            {imageData_data.map((image) => {
              return (
                <div key={`gallery-card-wrapper-${image.id}`} className="gallery-item" onClick={() => this.handleClick(image.id)}>
                  <Card
                    key={`gallery-item-${image.id}`}
                    absolutePath={image.absolutePath}
                    imageName={image.name}
                  />
                </div>);
            })}
          </div>
        </div>
        <div className='gallery-counter'>
          {this.setCounter()}
        </div>
      </>
    );
  }
}

export default GalleryWidget;
