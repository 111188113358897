import { toast } from 'react-toastify';
import './styles.css';

export const notifySuccess = (toastContent) => {
  toast.success(toastContent, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true
  });
};
