import { getState } from '../../backend/query/static/run.getState';
import { openMultiUpload } from '../workspaces/action.modals';
import store from '../store';
import {
  UPDATE_WORKSPACE_WIDGETS,
  REMOVE_WORKSPACE_MODAL_WIDGET
} from './types';


export const updateWidgets = (index: number, widgets, layout, oldActionsParams) => {
  return {
    type: UPDATE_WORKSPACE_WIDGETS,
    index: index,
    widgets: widgets,
    layout: layout,
    oldActionsParams: oldActionsParams
  };
};

export const removeModalWidget = (key: number, id: string, index: number) => {
  return {
    type: REMOVE_WORKSPACE_MODAL_WIDGET,
    key: key,
    id: id,
    index: index
  };
};


export const fetchWidgets = async (key, id, params, relation, source, target) => {
 
  try {
    const response = await getState('system', key, id ?? null);
    const { widgets, layout } = response || {};
    
    if (widgets) {
      for (const element of widgets) {
        if (element.widget === 'Upload') {
          store.dispatch(openMultiUpload(element));
        } else {
          const activeTabIndex = store.getState().workspaces.activeWorkspace.index;
          const oldWidgets = store.getState().workspaces.instances[activeTabIndex]?.widgets?.widgets || [];
          const shouldLoadWidgets = oldWidgets.length === 0 || layout;
          const widgetMap = new Map();
            
          if (!shouldLoadWidgets) {
            oldWidgets.forEach((oldWidget) => {
              const key = `${oldWidget.position.column}-${oldWidget.position.row}-${oldWidget.modal}`;
              widgetMap.set(key, oldWidget);
            });
          }
            
          widgets.forEach((newWidget) => {
            newWidget.dataID = id;
            const key = `${newWidget.position.column}-${newWidget.position.row}-${newWidget.modal}`;
            widgetMap.set(key, newWidget);
          });
            
          const newWidgets = Array.from(widgetMap.values());
          const updateParams = { relation, source, target, params };
    
          await store.dispatch(updateWidgets(activeTabIndex, newWidgets, layout, updateParams));
        }
      }
    }
  } catch (error) {
    console.error(`fetchWidgets error: ${error}`);
  }
};
    