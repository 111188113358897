import React from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { withRouterHooks } from '../../routes/router.hooks';
import MenuBar from '../../components/MenuBar/MenuBar';
import CustomForm from './Form.list';
import { withTranslation } from 'react-i18next';
import { runDynamicQuery } from '../../backend/query/run.dynamicQuery';
import { FormValidator, FormValidatorModel } from '@syncfusion/ej2-inputs';
import './styles.css';
import { WidgetProvider } from '../WidgetContext';


class FormWidget extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      hidden: true,
      hiddenNew: true,
      newPassword: '',
      confirmPassword: '',
      previewBase64: null,

      formData_data: null,
      formData_pending: false,
      formData__success: false,
      formData_error: false,


      formEdit_data: [],
      formEdit_pending: false,
      formEdit_success: false,
      formEdit_error: false,

      formOnSubmitFunc: null,

      formLoaded: false
    };
  }

  validationFormObject: any;

  async componentDidMount() {
    const { widgetData } = this.props.navData;
    const { key, rules } = widgetData;
    await this.fetchFormDataData()
      .then(
        () => {
          if (rules) {
            const options: FormValidatorModel = {
              rules: rules
            };
            setTimeout(
              () => {
                this.validationFormObject = new FormValidator(`#form-${key}`, options);
              },
              500
            );

          }
        }
      );
  }


  componentDidUpdate(prevProps) {
    const { navData } = this.props;

    if (prevProps.navData.renderID !== navData.renderID || prevProps.navData.widgetData.dataID !== navData.widgetData.dataID || prevProps.navData.widgetData.timestamp !== navData.widgetData.timestamp) {
      this.fetchFormDataData();
    }

  }

  componentWillUnmount() {
    this.setState({
      formData_data: null,
      formData_pending: false,
      formData__success: false,
      formData_error: false,

      formEdit_data: [],
      formEdit_pending: false,
      formEdit_success: false,
      formEdit_error: false,
      formEdit_Files: null,

      formOnSubmitFunc: null
    });
  }


  fetchFormDataData = async () => {
    const { navData, runActionsState } = this.props;

    this.setState({ formData_pending: true });

    const newParams = { ...navData.params };
    newParams.source = { ...newParams.source, id: navData.widgetData.dataID };

    await runDynamicQuery({ ...runActionsState?.data?.params, widgetKey: navData.widgetData.key }, navData.widgetData)
      .then((response) => {

        this.initFormEditData();

        const newEditData = [];
        Object.keys(response.targets[0]).map(function (key) {
          navData.widgetData.sections.map((s) => {
            s.fieldSets.map((fs) => {
              fs.fields.map((field) => {
                if (field.attribute.name === key) {
                  const o = { _key: field.attribute.key ?? null };
                  o[key] = response.targets[0][key] ?? null;
                  newEditData.push(o);
                  return null;
                }
                return null;
              });
              return null;
            });
            return null;
          });
          return null;
        });

        this.setState({ formData__success: true, formData_data: response.targets[0], formEdit_data: newEditData, formLoaded: true });
      })
      .catch(() => this.setState({ formData__success: false, formData_data: null, formData_error: true }))
      .finally(() => this.setState({ formData_pending: false }));
  };

  initFormEditData = () => {
    this.setState({
      formEdit_data: [],
      formEdit_pending: false,
      formEdit_success: false,
      formEdit_error: false,
      formEdit_Files: null,
      formOnSubmitFunc: null
    });
  };

  setFormEditData = (item) => {
    const { formEdit_data } = this.state;
    const index = formEdit_data.findIndex(x => x._key === item._key);

    // TODO: find reason for missing entries inside formEdit_data
    if (index === -1) {
      formEdit_data.push(item);
    } else {
      formEdit_data[index] = item;
    }

    this.setState({ formEdit_data: formEdit_data });
  };

  setFormEditFile = (file) => {
    this.setState({ formEdit_Files: file });
  };

  setFormOnSubmitFunc = (func: any) => {
    this.setState({ formOnSubmitFunc: func });
  };

  handleOnSubmit = async () => {
    const { formOnSubmitFunc } = this.state;
    formOnSubmitFunc();
  };


  render() {
    const { navData } = this.props;
    const { formData__success, formData_data, formEdit_data, formLoaded } = this.state;

    if (!formData__success) {
      return null;
    }
    
    return (
      <WidgetProvider value={this}>
        <form
          id={`form-${navData.widgetData.key}`}
          onSubmit={this.handleOnSubmit}
        >
          {
            navData.widgetData.menu
              ? <MenuBar
                menu={navData.widgetData.menu}
                initFormEditData={this.initFormEditData}
                setFormOnSubmitFunc={this.setFormOnSubmitFunc}
                validationFormObject={formLoaded && this.validationFormObject}
              />
              : null
          }
          {
            navData.widgetData.label !== '' ? <h3 className='widget-label'>{this.props.t(navData.widgetData.label)} </h3> : ''
          }          
          <div className="form-container" style={{ height: '100%' }}>
            <Grid divided='vertically' className="form-content">
              <CustomForm
                renderData={navData.widgetData}
                data={formData_data}
                params={{ key: navData.widgetData.key, id: navData.widgetData.dataID }}
                editData={formEdit_data}
                setFormEditData={this.setFormEditData}
                setFormEditFile={this.setFormEditFile}
                viewType={navData.widgetData.type}
              />
            </Grid>
          </div>
        </form>
      </WidgetProvider>
    );
  }
}

const mapStateToProps = (state: any) => ({
  runActionsState: state.actions
});

const mapDispatchToProps = () => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouterHooks(withTranslation()(FormWidget)));