export const getToolbarSettings = (type) => {
  const settings = {
    edit: {
      toolbarItems: [
        'UndoRedoTool', 
        'PageNavigationTool', 
        'MagnificationTool', 
        'PanTool', 
        'SelectionTool', 
        'CommentTool', 
        'SubmitForm', 
        'AnnotationEditTool', 
        'FormDesignerEditTool', 
        'FreeTextAnnotationOption', 
        'InkAnnotationOption', 
        'ShapeAnnotationOption', 
        'StampAnnotation', 
        'SignatureOption', 
        'SearchOption', 
        'PrintOption', 
        'DownloadOption'
      ],
      annotationToolbarItems: [
        'HighlightTool', 
        'UnderlineTool', 
        'StrikethroughTool', 
        'ColorEditTool', 
        'OpacityEditTool', 
        'AnnotationDeleteTool', 
        'StampAnnotationTool', 
        'HandWrittenSignatureTool', 
        'InkAnnotationTool', 
        'ShapeTool', 
        'CalibrateTool', 
        'StrokeColorEditTool', 
        'ThicknessEditTool', 
        'FreeTextAnnotationTool', 
        'FontFamilyAnnotationTool', 
        'FontSizeAnnotationTool', 
        'FontStylesAnnotationTool', 
        'FontAlignAnnotationTool', 
        'FontColorAnnotationTool', 
        'CommentPanelTool'
      ],
      formDesignerToolbarItems: [
        'TextboxTool', 
        'PasswordTool', 
        'CheckBoxTool', 
        'RadioButtonTool', 
        'DropdownTool', 
        'ListboxTool', 
        'DrawSignatureTool', 
        'DeleteTool'
      ]
    },
    view: {
      toolbarItems: [
        'PageNavigationTool', 
        'MagnificationTool', 
        'PanTool', 
        'SelectionTool', 
        'SearchOption', 
        'PrintOption', 
        'DownloadOption'
      ],
      annotationToolbarItems: [],
      formDesignerToolbarItems: []
    }
  };

  return settings[type] || [];
};
