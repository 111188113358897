import React from 'react';
import { connect } from 'react-redux';
import { withRouterHooks } from '../../../../routes/router.hooks';
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, RichTextEditorModel, Table, Resize, MarkdownEditor, MarkdownFormatter, RichTextEditor } from '@syncfusion/ej2-react-richtexteditor';
import { withTranslation } from 'react-i18next';
import { setTextSelection } from '../../../../store/workspaces/action.modals';
import { marked } from 'marked';
import { createElement, isNullOrUndefined } from '@syncfusion/ej2-base';
import { getToolbarSetting } from './toolbar/toolbar.settings';
import { setDropdownButtonToolbar } from './toolbar/toolbar.dropdown';
import { run as runActions } from '../../../../store/actions/run';
import { service as linkService } from '../../../View/text/service/linkService';



class RichTextField extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      openImageDialog: false,
      previewClick: true,
    };
  }
  rteObj: any;
  formatter = new MarkdownFormatter({ listTags: { OL: '1., 2., 3.' } });
  private textArea: HTMLTextAreaElement;
  private mdsource: HTMLElement;
  private mdSplit: HTMLElement;
  private htmlPreview: HTMLElement;



  componentDidMount(): void {
    const Editable: boolean = ['create', 'edit'].includes(this.props.viewType);
    this.setDisableLinkClass(Editable);
  }

  componentDidUpdate(): void {
    document.querySelectorAll('.e-rte-image').forEach((element: any) => {
      element.classList.remove('e-rte-image');
    });

    const Editable: boolean = ['create', 'edit'].includes(this.props.viewType);
    this.setDisableLinkClass(Editable);
  }

  componentWillUnmount(): void {
    this.setDisableLinkClass(false);
  }


  onCreate = () => {
    if (this.props.field.type === 'markdown') {
      this.textArea = this.rteObj.contentModule.getEditPanel() as HTMLTextAreaElement;
      this.textArea.addEventListener('keyup', () => { this.markdownConversion(); });
      const rteObj: RichTextEditor = this.rteObj;
      this.mdsource = document.getElementById('preview-code');
      this.mdsource.addEventListener('click', (e: MouseEvent) => {
        this.fullPreviewMarkdown({ mode: true, type: 'preview' });
        if ((e.currentTarget as HTMLElement).classList.contains('e-active')) {
          rteObj.toolbarModule.baseToolbar.toolbarObj.enableItems(18, false);
          rteObj.toolbarModule.baseToolbar.toolbarObj.enableItems(19, false);
          this.rteObj.disableToolbarItem([
            'Bold', 'Italic', 'Underline', 'StrikeThrough', 'FontName', 'FontSize', 'FontColor', 'BackgroundColor', 'LowerCase', 'UpperCase', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList', 'Outdent', 'Indent', 'ClearFormat', 'Print', 'SourceCode', 'FullScreen', 'CreateTable', '|', 'Undo', 'Redo'
          ]);
          (e.currentTarget as HTMLElement).parentElement.nextElementSibling.classList.add('e-overlay');
        } else {
          rteObj.toolbarModule.baseToolbar.toolbarObj.enableItems(18, true);
          rteObj.toolbarModule.baseToolbar.toolbarObj.enableItems(19, true);
          this.rteObj.enableToolbarItem([
            'Bold', 'Italic', 'Underline', 'StrikeThrough', 'FontName', 'FontSize', 'FontColor', 'BackgroundColor', 'LowerCase', 'UpperCase', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList', 'Outdent', 'Indent', 'ClearFormat', 'Print', 'SourceCode', 'FullScreen', 'CreateTable', '|', 'Undo', 'Redo'
          ]);
          (e.currentTarget as HTMLElement).parentElement.nextElementSibling.classList.remove('e-overlay');
        }
      });
      this.mdSplit = document.getElementById('MD_Preview');
      this.mdSplit.addEventListener('click', () => {
        return null;
      });
    }


    setDropdownButtonToolbar(
      this.rteObj,
      this.props.setTextSelection,
      this.props.params,
      this.props.field,
      this.setDisableLinkClass
      // this.selection
    )
      .then((response: any) => {
        response.dropDownButtonIntern.appendTo(`#dropdown-intern-${this.props.field.attribute.key}`);
        response.dropDownButtonExtern.appendTo(`#dropdown-extern-${this.props.field.attribute.key}`);
      });
  };



  setDisableLinkClass = (inactive: boolean) => {
    const regexExpUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    const eventKey = this.props.renderData.events?.find((event) => event?.type === 'onClick')?.key;

    document.querySelectorAll('a').forEach((element: HTMLAnchorElement) => {
      if (element.href) {
        const url = new URL(element.href);
        const uuid = url.pathname.slice(1);

        if (regexExpUUID.test(uuid) || url.href) {
          if (inactive) {
            element.classList.add('disable-link');
          } else {
            element.classList.remove('disable-link');

            if (element.href) {
              element.addEventListener('click', (event: Event) => {
                event.preventDefault();
                const url = new URL(element.href);
                const uuid = url.pathname.slice(1);

                if (regexExpUUID.test(uuid)) {
                  event.preventDefault();
                  const params = {
                    uuid: uuid
                  };

                  linkService('system', params)
                    .then((response) => {
                      runActions(eventKey, response.id, null);
                    })
                    .catch((error) => console.log(error));
                } else {
                  window.open(url.href, '_blank');
                  return null;
                }
              });
            }
          }
        }
      }
    });

  };


  markdownConversion = () => {
    if (this.mdSplit.classList.contains('e-active')) {
      this.htmlPreview.innerHTML = marked((this.rteObj.contentModule.getEditPanel() as HTMLTextAreaElement).value);
    }
  };

  fullPreviewMarkdown = (e) => {
    const id: string = this.rteObj.getID() + 'html-preview';
    this.htmlPreview = this.rteObj.element.querySelector('#' + id);
    if ((this.mdsource.classList.contains('e-active')) && e.mode) {
      this.mdsource.classList.remove('e-active');
      this.mdsource.parentElement.title = 'Preview';
      this.textArea.style.display = 'block';
      this.textArea.style.width = '100%';
      this.htmlPreview.style.display = 'none';
    } else {
      this.mdsource.classList.add('e-active');
      if (!this.htmlPreview) {
        this.htmlPreview = createElement('div', { className: 'e-content' });
        this.htmlPreview.id = id;
        this.textArea.parentNode.appendChild(this.htmlPreview);
      }
      if (e.type === 'preview') {
        this.textArea.style.display = 'none'; this.htmlPreview.classList.add('e-pre-source');
      } else {
        this.htmlPreview.classList.remove('e-pre-source');
        this.textArea.style.width = '50%';
      }
      this.htmlPreview.style.display = 'block';
      this.htmlPreview.innerHTML = marked((this.rteObj.contentModule.getEditPanel() as HTMLTextAreaElement).value);
      this.mdsource.parentElement.title = 'Code View';
    }
  };

  actionComplete = (e: any) => {
    if (e.targetItem === 'Maximize' && isNullOrUndefined(e.args)) {
      this.fullPreviewMarkdown({ mode: true, type: '' });
    }
    else if (!this.mdSplit.parentElement.classList.contains('e-overlay')) {
      if (e.targetItem === 'Minimize') {
        this.textArea.style.display = 'block';
        this.textArea.style.width = '100%';
        if (this.htmlPreview) {
          this.htmlPreview.style.display = 'none';
        }
        this.mdSplit.classList.remove('e-active');
        this.mdsource.classList.remove('e-active');
      }
      this.markdownConversion();
    }
    this.rteObj.toolbarModule.refreshToolbarOverflow();
  };


  getChanges = (args: RichTextEditorModel) => {
    const { setNewValue } = this.props;
    setNewValue(args.value);
  };

  // onBlur = () => {
  //   this.rteObj.toolbarSettings.enable = false;
  // }

  // onFocus = () => {
  //   this.rteObj.toolbarSettings.enable = true;
  // }

  render() {
    const { viewType, field, value, hiddenClass } = this.props;
    const Editable: boolean = ['create', 'edit'].includes(viewType);

    if (Editable) {
      return (
        <div className={`form-input-container ${hiddenClass}`}>
          <label className='form-input-label'>{this.props.t(field.label)}</label>
          <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
          <div
            style={{ /*backgroundColor: setColorField(),*/ width: '100%' }}
          >
            <RichTextEditorComponent
              id={`markdown-preview-${field.attribute.key}`}
              ref={(richtexteditor) => { this.rteObj = richtexteditor; }}
              formatter={field.editorMode === 'markdown' ? this.formatter : null}
              height={field.height ?? 450}
              toolbarSettings={{ enable: true, ...getToolbarSetting(field) }}
              editorMode={field.type === 'markdown' ? field.type.charAt(0).toUpperCase() + field.type.slice(1) : 'HTML'}
              enableResize={true}
              showTooltip={false}
              // onBlur={this.onBlur.bind(this)}
              // onFocus={this.onFocus.bind(this)}
              value={value ? value : ''}
              change={this.getChanges.bind(this)}
              created={this.onCreate.bind(this)}
              actionComplete={field.type === 'markdown' ? this.actionComplete.bind(this) : null}
            >
              <Inject services={[Toolbar, HtmlEditor, MarkdownEditor, Image, Link, Table, QuickToolbar, Resize]} />
            </RichTextEditorComponent>
          </div>
        </div>
      );
    } else {
      return (
        <div className={`form-input-container ${hiddenClass}`}>
          <label className='form-input-label'>{this.props.t(field.label)}</label>
          <div className='parsedHTML richtext-container' style={{ height: value !== null ? field.height + 'px' : '70px', width: '100%', overflowY: 'auto' }}>
            <div>
              {
                value
                  ? <RichTextEditorComponent
                    readonly={true}
                    ref={(richtexteditor) => { this.rteObj = richtexteditor; }}
                    value={field.type === 'markdown' ? marked(value) : value}
                    editorMode={'HTML'}
                    className={'richtext-view'}
                    // toolbarSettings={{ items: ['Print'], enableFloating: true }}
                  >
                    <Inject services={[HtmlEditor, MarkdownEditor, Resize]} />
                  </RichTextEditorComponent>
                  : ''
              }
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  setTextSelection: (selectedText: any) => dispatch(setTextSelection(selectedText))
});

export default connect(
  null,
  mapDispatchToProps
)(withRouterHooks(withTranslation()(RichTextField)));
