import axios from 'axios';
import { notifyDebugError } from '../../components/Toasts/toast.debugError';
import { verifySessionToken } from '../services/tools/service.verifySession';
import { notifyWarning } from '../../components/Toasts/toast.warn';


export const runStaticQuery = async (queryAlias: any, params: any) => {

  await verifySessionToken(localStorage.getItem('session'))
    .then((response) => {
      if (response.expired) {
        notifyWarning(response.message);
        localStorage.removeItem('session');
        localStorage.setItem('expired', JSON.stringify(true));
        window.location.reload();
      }
    });

  const res = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}services/queries`,
    {
      data:
        JSON.stringify({
          params,
          queryAlias
        }),
      session: localStorage.getItem('session')
    }
  );

  if (res.data.data.error && res.data.data.message) {
    notifyDebugError(res.data.data.message, queryAlias);
    console.log(
      `%c Static: ${queryAlias} %c`,
      'background: #f33; color: #FFF',
      'background: transparent; color: #000',
      {
        params,
        response: res.data.data
      }
    );
  }

  return res.data.data;

};