import { ChangeEventArgs, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import React from 'react';
import { withTranslation } from 'react-i18next';


class BooleanFieldSync extends React.Component<any, any> {


  checkBoxInstance: CheckBoxComponent;

  onChange(args: ChangeEventArgs): void {
    const { setNewValue } = this.props;
    setNewValue(args.checked);
  }

  render() {
    const { field, value, isLocked, hiddenClass } = this.props;

    return (
      <div className={`form-input-container ${hiddenClass}`}>
        <label className='form-input-label'>{this.props.t(field.label)}</label>
        <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
        <div
          className='form-input-element boolean-field'
        >
          <CheckBoxComponent
            // label={this.props.t(field.label)}
            checked={value === ('false' || 'undefined') ? false : value}
            ref={(scope) => { this.checkBoxInstance = scope; }}
            change={this.onChange.bind(this)}
            disabled={isLocked}
          />


        </div>
      </div>
    );
  }
}
export default (withTranslation()(BooleanFieldSync));
