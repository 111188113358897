import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { renameWorkspace } from '../../../store/workspaces/action.workspaces';

const WorkspaceTabTextbox = ({ activeWorkspaceIndex, workspace, index }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isActiveWorkspace = activeWorkspaceIndex === index;

  useEffect(() => {
    if (!isActiveWorkspace) {
      setIsEditing(false);
    }
  }, [isActiveWorkspace]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const toggleEditing = () => {
    if (isActiveWorkspace) {
      setIsEditing(true);
    }
  };

  const handleDivBlur = () => {
    if (workspace.name.trim() === '') {
      dispatch(renameWorkspace(activeWorkspaceIndex, 'workspace'));
    }
    setIsEditing(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleDivBlur();
    }
  };

  return (
    <div onFocus={toggleEditing} onBlur={isActiveWorkspace ? handleDivBlur : null}>
      {isEditing && isActiveWorkspace ? (
        <input
          ref={inputRef}
          value={workspace.name}
          onKeyPress={handleKeyPress}
          onChange={(e) => dispatch(renameWorkspace(activeWorkspaceIndex, e.target.value))}
          className="workspace-button-input"
          style={{ width: isEditing ? `${(workspace.name.length + 4) * 7}px` : null }}
        />
      ) : (
        <div onDoubleClick={toggleEditing}>{workspace.name}</div>
      )}
    </div>
  );
};

WorkspaceTabTextbox.propTypes = {
  activeWorkspaceIndex: PropTypes.number,
  workspace: PropTypes.object,
  index: PropTypes.number,
};

export default WorkspaceTabTextbox;
