import React from 'react';
import { DateTimePicker, DateTimePickerComponent, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { withTranslation } from 'react-i18next';
import { loadCldr } from '@syncfusion/ej2-base';
import * as gregorian from 'cldr-data/main/de/ca-gregorian.json';
import * as numbers from 'cldr-data/main/de/numbers.json';
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as weekData from 'cldr-data/supplemental/weekData.json';
import { L10n } from '@syncfusion/ej2-base';
import { jwtDecrypt } from '../../../backend/services/tools/service.jwtDecrypt';

DateTimePickerComponent.Inject(MaskedDateTime);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
L10n.load({
  'de': {
    'datetimepicker': {
      placeholder: 'Wählen Sie ein Datum und eine Uhrzeit aus',
      today: 'heute'
    }
  }
});

class DateTimeField extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      timeFormat: null,
      dateFormat: null,
      timezone: null
    };
  }
  dateTimepickerInstance: DateTimePickerComponent;


  setDateTimeValue: any = (value: any) => {

    if (value) {
      const date = typeof (value) === 'string' ? new Date(value) : new Date(Date.UTC(value.year, value.month - 1, value.day, value.hour, value.minute, value.second));
      // let intl = new Internationalization();
      // let dFormatter = intl.getDateFormat({
      //   skeleton: 'medium',
      //   type: 'dateTime',
      //   format: `${this.state.dateFormat} ${this.state.timeFormat}`
      // });
      return date;
      //return dFormatter(date);
    }
    return null;
  };

  onChange = (args: any) => {
    const { setNewValue } = this.props;
    setNewValue(new Date(args?.value).toISOString() ?? new Date().toISOString());
  };

  // onChange = (args: any) => {
  //   const { setNewValue } = this.props;
  //   if (args?.value) {
  //     setNewValue({
  //       year: args.value.getUTCFullYear(),
  //       month: args.value.getUTCMonth(),
  //       day: args.value.getUTCDay(),
  //       hour: args.value.getUTCHours(),
  //       minute: args.value.getUTCMinutes(),
  //       second: args.value.getUTCSeconds(),
  //     });
  //   }
  // }


  componentDidMount(): void {
    jwtDecrypt(JSON.parse(localStorage.getItem('session')))
      .then((response) => {
        this.setState({
          timeFormat: response.data.user.timeFormat,
          dateFormat: response.data.user.dateFormat,
          timezone: response.data.user
        });
      })
      .catch((error) => {
        console.log('jwtDecrypt error', error);
      });
  }


  render() {
    const { field, isLocked, value, hiddenClass } = this.props;

    return (
      <div className={`form-input-container ${hiddenClass}`}>
        <label className='form-input-label'>{this.props.t(field.label)}</label>
        <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
        <div
          className='form-input-element '
        >
          <DateTimePickerComponent
            ref={(scope) => { (this.dateTimepickerInstance as DateTimePicker | null) = scope; }}
            firstDayOfWeek={1}
            openOnFocus={true}
            className='form-input-element'
            name="dateTime"
            placeholder={value ? this.setDateTimeValue(value) : ''}
            change={this.onChange.bind(this)}
            format={'yyyy-MM-dd HH:mm'}
            value={this.setDateTimeValue(value)}
            enabled={!isLocked}
            timeFormat={this.state.timeFormat}
            enableMask={true}
          />
        </div>
      </div>
    );
  }
}
export default (withTranslation()(DateTimeField));