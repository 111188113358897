import React, { useRef } from 'react';
import { enableRipple } from '@syncfusion/ej2-base';
enableRipple(true);
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const StructuredListField = ({ field, data, setNewValue, isLocked, hiddenClass, t }) => {
  const structuredListFieldInstance: any = useRef(null);
  const fields = {
    dataSource: data[field.attribute.name] ?? [],
    id: 'id',
    parentID: 'parent',
    text: 'name',
    hasChildren: 'hasChildren'
  };

  const nodeChecked = () => {
    setNewValue(structuredListFieldInstance.current.getAllCheckedNodes());
  };

  const checkedNodes: any = () => {
    const checked = fields.dataSource ?? []
      .filter(field => field.selected === true)
      .map(field => field.id.toString());

    return checked;
  };

  const created = () => {
    setNewValue(structuredListFieldInstance.current.getAllCheckedNodes());
  };

  return (
    <div className={`form-input-container ${hiddenClass}`}>
      <label className='form-input-label'>{t(field.label)}</label>
      <div className='form-input-element structured-list-field' style={{ height: field.height + 'px' }} >
        <TreeViewComponent
          fields={fields}
          ref={structuredListFieldInstance}
          disabled={isLocked}
          className='form-input-element structured-list-tree'
          showCheckBox={true}
          nodeChecked={nodeChecked}
          checkedNodes={checkedNodes()}
          created={created.bind(this)}
          allowMultiSelection={true}
          fullRowSelect={false}
        />
      </div>
    </div>
  );
};

StructuredListField.propTypes = {
  field: PropTypes.object,
  data: PropTypes.any,
  setNewValue: PropTypes.func,
  isLocked: PropTypes.bool,
  hiddenClass: PropTypes.any,
  t: PropTypes.func,
};
export default withTranslation()(StructuredListField);

