export const sendPost = async (message: any) => {
  try {
    await fetch(`${process.env.REACT_APP_BACKEND_URL}services/messenger/send-post`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    });
  } catch (error) {
    console.error('Error sending message:', error);
  }
};
