import React, { useRef } from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const EmailField = ({ field, isLocked, value, hiddenClass, setNewValue }) => {
  const textboxInstance = useRef();
  const { t } = useTranslation();

  const onChange = (args) => {
    setNewValue(args.value);
  };

  return (
    <div className={`FormInputContainer ${isLocked ? hiddenClass : 'form-input-container'}`}>
      <label className='form-input-label'>{t(field.label)}</label>
      {!isLocked ? (
        <>
          <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
          <div className='form-input-element'>
            <TextBoxComponent
              id={`attribute-${field.attribute.key}`}
              ref={textboxInstance}
              className='form-input-element'
              value={value}
              change={onChange}
              enabled={!isLocked}
              type="text"
              name={field.name}
              data-msg-containerid={`error-${field.attribute.key}`}
            />
          </div>
          <div id={`error-${field.attribute.key}`} />
        </>
      ) : (
        <div className='form-input-element email-field'>
          <span className="e-input-group e-control-wrapper e-valid-input email-link-container-disabled">
            <a href={`mailto:${value}`}>{value}</a>
          </span>
        </div>
      )}
    </div>
  );
};

EmailField.propTypes = {
  field: PropTypes.object,
  isLocked: PropTypes.bool,
  value: PropTypes.string,
  hiddenClass: PropTypes.string,
  setNewValue: PropTypes.func,
};

export default EmailField;
