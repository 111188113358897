import React, { useEffect, useState } from 'react';
import { withRouterHooks } from '../../routes/router.hooks';
import { Image } from 'semantic-ui-react';
import { run as runActions } from '../../store/actions/run';
import { runStaticQuery } from '../../backend/query/run.staticQuery';
import { useTranslation } from 'react-i18next';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { jwtDecrypt } from '../../backend/services/tools/service.jwtDecrypt';
import { useSelector } from 'react-redux';
import { setEventSourceHeartBeat } from '../../backend/services/heartbeat/heartbeat';
import './styles.css';

const HeaderMenu = () => {
  const [menu, setMenu] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const badge = useSelector((state: any) => state.workspaces.badge);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await jwtDecrypt(JSON.parse(localStorage.getItem('session')));
        setCurrentUser(response.data);

        const resp = await runStaticQuery('navigation', {
          key: null,
          uuid: 'ce6b3e7f-e0e8-4b14-9e5d-299634fd17de',
        });
        setMenu(resp?.navigation?.elements);

      } catch (error) {
        console.log('Error fetchData Headermenu', error);
      }
    };

    fetchData();
    setEventSourceHeartBeat();
  }, []);


  const handleClick = (args) => {
    const eventKey = args?.item?.key;

    if (eventKey) {
      runActions(eventKey, args?.item?.properties?.id, null);
    }
  };

  if (currentUser && menu) {
    return (
      <div className="header-menu">
        <DropDownButtonComponent
          items={
            menu &&
            menu.map((element) => ({
              text: t(element.label),
              id: element.key,
              key: element.events.find((event) => event?.type === 'onClick')?.key,
            }))
          }
          iconPosition="Left"
          className="header-dropdown-item"
          select={handleClick}
        >
          <div style={{ display: 'flex' }}>
            <Image
              avatar
              src={currentUser?.user?.image}
              className="header-avatar"
            />
            <div style={{ marginTop: '6px' }}>
              {currentUser.user.name}
            </div>
          </div>
        </DropDownButtonComponent>
        <div className={`chat-badge${badge?.content ? '' : ' empty'}`}>{badge?.content ?? '0'}</div>
      </div>
    );
  } else {
    return null;
  }
};

export default withRouterHooks(HeaderMenu);
