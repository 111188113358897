import React, { FC } from 'react';
//import { useSelector } from 'react-redux'
import Base64Field from './fields/field.base64';
import ListField from './fields/field.list';
import SelectField from './fields/field.select';
import DateField from './fields/field.date';
import BooleanFieldSync from './fields/field.boolean';
import TextAreaFieldSync from './fields/field.textArea';
import RichTextField from './fields/richtext/field.richtext';
import CodeField from './fields/field.code';
import FloatField from './fields/field.float';
import PasswordField from './fields/field.password';
// import BinaryField from './fields/BinaryField'
import DefaultField from './fields/field.default';
import PasswordEditField from './fields/field.passwordEdit';
import { Button } from 'semantic-ui-react';
import FileUploadField from './fields/field.upload';
import EmailField from './fields/field.email';
import HTMLField from './fields/field.html';
import StringField from './fields/field.string';
import IntegerField from './fields/field.integer';
import DateTimeField from './fields/field.dateTime';
import ColorPickerField from './fields/field.colorPicker';
import StructuredListField from './fields/field.structuredList';

const FormField: FC<any> = ({ field, data, params, editData, setFormEditData, setFormEditFile, viewType, renderData }) => {

  //const getState = useSelector(state => state.getState);

  if (!field || !data) {
    return null;
  }

  const setNewValue = (value) => {
    const obj = { _key: field.attribute.key };
    obj[field.attribute.name] = value;
    setFormEditData(obj);
  };

  const setNewFileToUpload = (file: any, hash: string) => {
    setNewValue(hash);
    setFormEditFile(file);
  };

  const setNewOptionsValue = (option) => {
    const obj = { _key: field.attribute.key };
    obj[field.attribute.name] = { ...option, selected: true };
    setFormEditData(obj);
  };

  /**
   * Checks if the Field is disabled or locked for editing
   * @param field
   * @returns boolean
   */
  const getIsLocked = (field) => {
    if (viewType === 'view') return true;
    return field.locked ? field.locked : false;
  };

  const getIsHiddenClass = (field) => {
    return field.hidden ? ' hidden' : null;
  };

  // const setColorField = () => {
  //     let backgroundColorField = ''
  //     getState.data.widgets.forEach(element => {
  //         if (element.widget === "Form" && element.type === 'view') {
  //             // backgroundColorField = "rgb(248, 248, 248)"
  //         }
  //     });
  //     return backgroundColorField
  // }

  switch (field.type) {
  case 'string':
    return (
      <StringField
        // setColorField={setColorField}
        field={field}
        value={getValue(field, data, editData)}
        isLocked={getIsLocked(field)}
        hiddenClass={getIsHiddenClass(field)}
        setNewValue={setNewValue}
        viewType={viewType}
      />
    );

  case 'list':
    return (
      <ListField
        // setColorField={setColorField}
        field={field}
        isLocked={getIsLocked(field)}
        hiddenClass={getIsHiddenClass(field)}
        setFormEditData={setNewOptionsValue}
        options={data[decapitalize(field.attribute.name + '')]}
      />
    );

  case 'select':
    return (
      <SelectField
        field={field}
        value={editData[decapitalize(field.label + '')]}
        isLocked={getIsLocked(field)}
        hiddenClass={getIsHiddenClass(field)}
        setNewValue={setNewValue}
        options={data[decapitalize(field.attribute.name + '')]}
      />
    );

  case 'multiSelect':
    return (
      <SelectField
        field={field}
        value={editData[decapitalize(field.label + '')]}
        isLocked={getIsLocked(field)}
        hiddenClass={getIsHiddenClass(field)}
        setNewValue={setNewValue}
        options={data[decapitalize(field.attribute.name + '')]}
      />
    );

  case 'integer':

    if (field.label === 'ID') {
      return (
        <IntegerField
          // setColorField={setColorField}
          field={field}
          value={getValue(field, data, null)}
          isLocked={getIsLocked(field)}
          hiddenClass={getIsHiddenClass(field)}
          setNewValue={setNewValue}
        />
      );
    }

    return (
      <IntegerField
        // setColorField={setColorField}
        field={field}
        value={getValue(field, data, editData)}
        isLocked={getIsLocked(field)}
        hiddenClass={getIsHiddenClass(field)}
        setNewValue={setNewValue}
      />
    );

  case 'date':
    return (
      <DateField
        // setColorField={setColorField}
        field={field}
        value={getValue(field, data, editData)}
        isLocked={getIsLocked(field)}
        hiddenClass={getIsHiddenClass(field)}
        setNewValue={setNewValue}
      />
    );

  case 'datetime':
    return (
      <DateTimeField
        // setColorField={setColorField}
        field={field}
        value={getValue(field, data, editData)}
        isLocked={getIsLocked(field)}
        hiddenClass={getIsHiddenClass(field)}
        setNewValue={setNewValue}
      />
    );

  case 'boolean':
    return (
      <BooleanFieldSync
        // setColorField={setColorField}
        field={field}
        value={getValue(field, data, editData)}
        isLocked={getIsLocked(field)}
        hiddenClass={getIsHiddenClass(field)}
        setNewValue={setNewValue}
      />
    );
  case 'textarea':
    return (
      <TextAreaFieldSync
        // setColorField={setColorField}
        field={field}
        value={getValue(field, data, editData)}
        isLocked={getIsLocked(field)}
        hiddenClass={getIsHiddenClass(field)}
        setNewValue={setNewValue}
      />
    );
  case 'richtext':
    return (
      <RichTextField
        // setColorField={setColorField}
        editorMode={'HTML'}
        viewType={viewType}
        field={field}
        value={getValue(field, data, editData)}
        params={params}
        setNewValue={setNewValue}
        hiddenClass={getIsHiddenClass(field)}
        renderData={renderData}
      />
    );
  case 'markdown':
    return (
      <RichTextField
        // setColorField={setColorField}
        editorMode={'Markdown'}
        viewType={viewType}
        field={field}
        value={getValue(field, data, editData)}
        params={params}
        setNewValue={setNewValue}
        hiddenClass={getIsHiddenClass(field)}
        renderData={renderData}
      />
    );
  case 'cypher':
  case 'diagram':
    return (
      <CodeField
        // setColorField={setColorField}
        viewType={viewType}
        field={field}
        value={getValue(field, data, editData)}
        setNewValue={setNewValue}
        hiddenClass={getIsHiddenClass(field)}
        isLocked={getIsLocked(field)}
      />
    );
  case 'code':
    return (
      <CodeField
        // setColorField={setColorField}
        viewType={viewType}
        field={field}
        value={getValue(field, data, editData)}
        setNewValue={setNewValue}
        hiddenClass={getIsHiddenClass(field)}
      />
    );
  case 'HTML':
    return (
      <HTMLField
        // setColorField={setColorField}
        viewType={viewType}
        field={field}
        value={getValue(field, data, editData)}
        hiddenClass={getIsHiddenClass(field)}
      />
    );
  case 'color':
    return (
      <ColorPickerField
        // setColorField={setColorField}
        viewType={viewType}
        field={field}
        value={getValue(field, data, editData)}
        setNewValue={setNewValue}
        hiddenClass={getIsHiddenClass(field)}
      />
    );
  case 'float':
    return (
      <FloatField
        // setColorField={setColorField}
        field={field}
        value={getValue(field, data, editData)}
        isLocked={getIsLocked(field)}
        setNewValue={setNewValue}
        hiddenClass={getIsHiddenClass(field)}
      />
    );
  case 'timestamp':
    return (
      <DateField
        // setColorField={setColorField}
        field={field}
        value={getValue(field, data, editData)}
        isLocked={getIsLocked(field)}
        setNewValue={setNewValue}
        hiddenClass={getIsHiddenClass(field)}
      />
    );
  case 'json':
    return (
      <CodeField
        // setColorField={setColorField}
        viewType={viewType}
        field={field}
        value={getValue(field, data, editData)}
        setNewValue={setNewValue}
        hiddenClass={getIsHiddenClass(field)}
      />
    );
  case 'url':
    return (
      <StringField
        // setColorField={setColorField}
        field={field}
        value={getValue(field, data, null)}
        isLocked={getIsLocked(field)}
        setNewValue={setNewValue}
        hiddenClass={getIsHiddenClass(field)}
        viewType={viewType}
      />
    );

  case 'email':
    return (
      <EmailField
        // setColorField={setColorField}
        field={field}
        value={getValue(field, data, null)}
        isLocked={getIsLocked(field)}
        setNewValue={setNewValue}
        hiddenClass={getIsHiddenClass(field)}
      />
    );
  case 'password':
    switch (viewType) {
    case 'view':
      return (
        <PasswordField
          // setColorField={setColorField}
          field={field}
          value={getValue(field, data, editData)}
          isLocked={getIsLocked(field)}
          hiddenClass={getIsHiddenClass(field)}
        />
      );
    case 'edit':
      return (
        <PasswordEditField
          // setColorField={setColorField}
          field={field}
          setNewValue={setNewValue}
          hiddenClass={getIsHiddenClass(field)}
        />
      );
    case 'create':
      return (
        <PasswordEditField
          // setColorField={setColorField}
          field={field}
          setNewValue={setNewValue}
          hiddenClass={getIsHiddenClass(field)}
        />
      );
    default:
      return <span>viewType nicht gesetzt</span>;
    }

  case 'base64':
    return (<Base64Field
      field={field}
      value={getValue(field, data, editData)}
      hiddenClass={getIsHiddenClass(field)}
    />);

  case 'button':
    return (
      <Button
        field={field}
        label={field.label}
        value={getValue(field, data, editData)}
        hiddenClass={getIsHiddenClass(field)}
      />
    );
  case 'binary':
    switch (viewType) {
    case 'view':
      return (<Base64Field
        field={field}
        value={getValue(field, data, editData)}
        hiddenClass={getIsHiddenClass(field)}
      />);
    case 'edit':
      if (field.locked === true) {
        return (<Base64Field
          field={field}
          value={getValue(field, data, editData)}
          hiddenClass={getIsHiddenClass(field)}
        />);
      } else {
        return (
          <FileUploadField
            // setColorField={setColorField}
            field={field}
            setNewFileToUpload={setNewFileToUpload}
            hiddenClass={getIsHiddenClass(field)}
          />
        );
      }
    case 'create':
      return (
        <FileUploadField
          // setColorField={setColorField}
          field={field}
          setNewFileToUpload={setNewFileToUpload}
          hiddenClass={getIsHiddenClass(field)}
        />
      );
    default:
      return <span>viewType nicht gesetzt</span>;
    }
  case 'structured list':
    return (
      <StructuredListField
        field={field}
        data={data}
        setNewValue={setNewValue}
        isLocked={getIsLocked(field)}
        hiddenClass={getIsHiddenClass(field)}
      />
    );


  default:
    return (<DefaultField field={field} />);
  }
};

/**
 * Gets the right value for the single input type
 * @param field
 * @param data
 * @param editData
 */
const getValue = (field, data, editData) => {
  if (!data) {
    return null;
  }

  const foundInEditData = editData ? editData.find(o => Object.prototype.hasOwnProperty.call(o, field.attribute.name)) : false;

  // TODO find better solution
  if (field.attribute.name === 'id') {
    // const dataDoubleCaseForID = editData ? editData.find(o => Object.keys(o).length === 1) : false;
    // return dataDoubleCaseForID ? dataDoubleCaseForID.id : null
    return data.id;
  }

  if (foundInEditData) {
    return foundInEditData[field.attribute.name];
  } else {
    if (data[field.attribute.name] == null) {
      return '';
    } else {
      return data[field.attribute.name];
    }
  }
};

/**
 * Find & return selected item label in the list
 * @param selectList
 */
// const getSelectedListLabel = (selectList) => {
//     if (!selectList || !Array.isArray(selectList)) return null
//     const selected = selectList.filter(item => item.selected)[0];
//     return selected ? selected.label : null;
// }

/**
 * Make only first letter to lowercase for finding the cases where attribute{ name: ValueChain } fits data[valueChain]
 */
const decapitalize = (string) => {
  if (typeof string === 'string') {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }
};

export default FormField;