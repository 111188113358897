import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';
import Loader from '../../../components/Loader/Loader';
import SparkMD5 from 'spark-md5';

const FileUploadField: FC<any> = ({ field, setNewFileToUpload, isLocked, hiddenClass }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [hash, setHash] = useState('');

  async function getHashAsync(file: File) {
    return new Promise((resolve, reject) => {
      const blobSlice = File.prototype.slice,
        chunkSize = 2097152,
        chunks = Math.ceil(file.size / chunkSize),
        spark = new SparkMD5.ArrayBuffer(),
        fileReader = new FileReader();
      let currentChunk = 0;
      let hash = '';

      fileReader.onload = function (e) {
        setIsLoading(true);
        spark.append(e.target.result);
        currentChunk++;

        if (currentChunk < chunks) {
          loadNext();
        } else {
          hash = spark.end();
          setIsLoading(false);
          resolve(hash);
        }
      };

      fileReader.onerror = function () {
        reject('Something went wrong reading the file.');
      };

      function loadNext() {
        const start = currentChunk * chunkSize,
          end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;

        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
      }

      loadNext();
    }
    );
  }

  async function getHash(file: File) {
    const hash = await getHashAsync(file);
    setHash(String(hash));
    setNewFileToUpload(file, hash);
  }

  return (
    <div className={`form-input-container ${hiddenClass}`}>
      {isLoading && <Loader />}
      <label className='form-input-label'>{t(field.label)}</label>
      <span
        className='validation-label' id={`validation-label-${field.attribute.key}`}
      ></span>
      <div className='form-input-element '>
        <Input
          className="file-upload-field"
          style={{ widht: '100%' }}
          type='file'
          placeholder='Search...'
          onChange={(event) => {
            const file: File = event.target.files[0];
            getHash(file);
          }
          }
          disabled={isLocked}
        />
        <Input className="hide" value={hash} id="hash-value" />
      </div>
    </div>
  );
};
export default FileUploadField;