import React, { createContext, useEffect, useRef, useState } from 'react';
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Annotation,
  FormFields,
  Inject,
  FormDesigner
} from '@syncfusion/ej2-react-pdfviewer';
import { WidgetProvider } from '../../WidgetContext';
import { runDynamicQuery } from '../../../backend/query/run.dynamicQuery';
import { serviceFilePreview } from '../../../backend/services/tools/service.filePreview';
import { notifyInfo } from '../../../components/Toasts/toast.info';
import { getToolbarSettings } from './settings/toolbar';
import MenuBar from '../../../components/MenuBar/MenuBar';
import PropTypes from 'prop-types';

const PDFViewer = ({ navData, runActionsState }) => {
  const viewerInstance: any = useRef();
  const [document, setDocument] = useState(null);
  const [documentBase64, setDocumentBase64] = useState(null);

  const PDFViewerContext = createContext(
    {
      props: {navData, runActionsState},
      viewerInstance
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await runDynamicQuery({ ...runActionsState, widgetKey: navData.renderID }, navData.widgetData);
        if (response.target?.fileType !== 'PDF') {
          return notifyInfo(`Filetype ${response.target?.fileType} is not supported`);
        }
        setDocument(response.target);
        const responsePreview = await serviceFilePreview(`/services/file/preview/?absolutePath=${response.target.absolutePath}`);
        if (!responsePreview) {
          return;
        }
        setDocumentBase64(`data:application/pdf;base64,${responsePreview.data}`);
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };
    fetchData();
  }, [navData]);


  const created = () => {
    if (!documentBase64 && !viewerInstance) {
      return;
    }
    viewerInstance.current.load(documentBase64, null);
  };


  if (!document || !documentBase64) {
    return null;
  }

  return (
    <div className='widget-container'>
      <WidgetProvider value={PDFViewerContext}>
        {
          navData.widgetData.menu
            ? <MenuBar
              key={`menu-${navData.widgetData.menu.id}`}
              menu={navData.widgetData.menu}
            />
            : null
        }
        <PdfViewerComponent
          id="container"
          ref={viewerInstance}
          documentPath="FormDesigner.pdf"
          serviceUrl="https://services.syncfusion.com/react/production/api/pdfviewer"
          height={'calc(100% - 50px)'}
          toolbarSettings={getToolbarSettings(navData.widgetData.type)}
          enableFormDesignerToolbar={true}
          created={created}
          designerMode={true}
          downloadFileName={document.name}
        >
          <Inject services={[
            Toolbar,
            Magnification,
            Navigation,
            LinkAnnotation,
            BookmarkView,
            ThumbnailView,
            Print,
            TextSelection,
            TextSearch,
            Annotation,
            FormFields,
            FormDesigner
          ]}
          />
        </PdfViewerComponent>
      </WidgetProvider>
    </div>
  );
};

PDFViewer.propTypes = {
  navData: PropTypes.object,
  runActionsState: PropTypes.object,
};

export default PDFViewer;
