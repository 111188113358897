import React from 'react';
import Header from '../../../components/Header/Header';
import WorkspaceComponent from './workspace';
import { useDispatch, useSelector } from 'react-redux';
import { removeWorkspace, setActiveWorkspace, setIsNewWorkspace } from '../../../store/workspaces/action.workspaces';
import { run as runActions } from '../../../store/actions/run';
import { callFunctions } from '../../../backend/functions/call.functions';
import WorkspaceTabTextbox from './workspace.textbox';
import { getModules } from '../../../store/workspaces/action.modules';


const WorkspacesMainstage = () => {
  const workspaces = useSelector((state: any) => state.workspaces);
  const dispatch = useDispatch<any>();

  const handleTabClick = async (workspace, index) => {
    await dispatch(setActiveWorkspace(index));
  
    if (workspace.isNewTab) {
      await dispatch(getModules());
      await runActions(workspace.data.key, workspace.data.id, workspace.data);
      if (workspace.data.functions) {
        await callFunctions(workspace.data.functions, null);
        await dispatch(setIsNewWorkspace(index, false));
      }
    }
  };

  const handleTabCloseClick = async (index) => {
    await dispatch(removeWorkspace(index));
    const newIndex = index === 0 ? 0 : index - 1;
    await dispatch(setActiveWorkspace(newIndex));
    const newActiveWorkspace = workspaces.instances[newIndex];
  
    if (newActiveWorkspace.isNewTab) {
      const { key, id, functions } = newActiveWorkspace.data;
      await runActions(key, id, newActiveWorkspace.data);
      if (functions) {
        await callFunctions(functions, null);
        await dispatch(setIsNewWorkspace(newIndex, false));
      }
    }
  };

  return (
    <>
      <div className='workspace-button-bar'>
        {workspaces.instances.map((workspace, index) => {
          if (workspace.isOpen) {
            return (
              <div 
                key={index} 
                className={`workspace-button-container${workspaces.activeWorkspace.index === index ? ' active' : ''}`}
                id="workspace-cm-target"
              > 
                <button
                  key={index}
                  className='workspace-button'
                  onClick={() => handleTabClick(workspace, index)}
                >
                  <WorkspaceTabTextbox 
                    activeWorkspaceIndex={workspaces.activeWorkspace.index} 
                    workspace={workspace} 
                    index={index}/>
                </button>
                {workspaces.instances.length > 1 && (
                  <button
                    className='workspace-button e-icons e-close'
                    onClick={() => setTimeout(() => handleTabCloseClick(index), 500)}
                  />
                )}
              </div>
            );
          } else {
            return null; 
          }
        })}
      </div>
      <Header />
      {workspaces.instances.map((workspace, index) => (
        <div
          key={index}
          className={`workspace-content${workspaces.activeWorkspace.index === index ? '' : ' hidden'}`}
        >
          <WorkspaceComponent workspace={workspace} />
        </div>
      ))}
    </>
  );
};


export default WorkspacesMainstage;