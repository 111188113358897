export const onRowDrag = (args: any, $this: any) => {

  const dropEventArg: any = args;
  const fromIndex = dropEventArg?.fromIndex;
  const toIndex = dropEventArg?.dropIndex;

  $this.state.gridData?.sort((x, y) => {
    const obj1 = x['sequence'];
    const obj2 = y['sequence'];

    if (obj1 < obj2) {
      return -1;
    }
    if (obj1 > obj2) {
      return 1;
    }
    return 0;
  });
  $this.state.gridData?.splice(toIndex, 0, $this.state.gridData.splice(fromIndex, 1)[0]);

  const dragAndDropData = $this.state.gridData?.map(element => {
    const index = $this.state.gridData?.indexOf(element);
    element.sequence = index;
    return element;
  });

  $this.setState({ gridEditData: dragAndDropData });
};