import React, { FC, useEffect } from 'react';
import LayoutWidget1 from '../../pages/module/page.layout';
import { removeModalWidget } from '../../store/workspaces/actions.widgets';
import { Modal as SModal } from 'semantic-ui-react';
import store from '../../store/store';
import { setModalOpen } from '../../store/workspaces/action.modals';
import { useSelector } from 'react-redux';
import './styles.css';

const Modal: FC<any> = ({ widget, oldActionsParams }) => {
  const modalStates = useSelector((state: any) => state.workspaces.instances[state.workspaces.activeWorkspace.index].modals);

  useEffect(() => {
    store.dispatch(setModalOpen(true));
    return () => {
      store.dispatch(setModalOpen(false));
    };
  }, []);

  return (
    <SModal
      open={modalStates.modal?.isOpen || false}
      centered={true}
      closeOnDimmerClick={false}
      id={`form-${widget.key}`}
      className='form'
      height={widget.height}
      width={widget.width}
      style={{
        height: widget.height,
        width: widget.width,
        overflow: 'hidden'
      }}
    >
      <SModal.Header>
        <div
          className={'e-icons e-close label-icon modal-close-icon'}
          onClick={() => { store.dispatch(removeModalWidget(widget.key, widget.dataID, store.getState().workspaces.activeWorkspace.index)); }}
        />
      </SModal.Header>
      <SModal.Content>
        <SModal.Description>
          <LayoutWidget1
            column={widget.position.column}
            row={widget.position.row}
            widget={widget}
            oldActionsParams={oldActionsParams}
          />
        </SModal.Description>
      </SModal.Content>
    </SModal>
  );

};

export default Modal;