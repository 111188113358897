import {
  ACTIONS_PENDING,
  ACTIONS_SUCCESS,
  ACTIONS_ERROR
} from './types';
import initialState from './initialState';

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
  case ACTIONS_PENDING:
    return {
      ...state,
      pending: true,
      success: false,
      error: false
    };

  case ACTIONS_SUCCESS:
    return {
      ...state,
      pending: false,
      success: true,
      error: false,
      data: {
        params: action.parameter 
      }
    };

  case ACTIONS_ERROR:
    return {
      ...state,
      pending: false,
      success: false,
      error: true
    };

  default:
    return state;
  }
};

export default reducer;
