//import getModulesQuery from "./getModules.query";
import { logger } from '../../../utils/logger';
import { runStaticQuery } from '../run.staticQuery';

export const getModules = async () => {
  try {
    return await runStaticQuery('modules', {});
  } catch (error) {
    logger.error('API getModules error:  ', error);
  }
};