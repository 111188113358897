import axios from 'axios';

export const getSyncfusionLicense = () => {

  return axios.post(
    process.env.REACT_APP_BACKEND_URL + 'tools/sync-license'
  ).then(
    (response) => {
      return response.data;
    }
  ).catch(
    (error) => {
      console.log('getSyncfusionLicense error:  ', error);
    }
  );
};