import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import getThemesReducer from './getThemes/reducer';
import actionsReducer from './actions/reducer';
import loaderReducer from './loader/reducer';
import workspacesReducer from './workspaces/reducer';
import thunk from 'redux-thunk';

const combiReducers = combineReducers(
  {
    getThemes: getThemesReducer,
    actions: actionsReducer,
    loader: loaderReducer,
    workspaces: workspacesReducer
  }
);

const composeEnhancers = (process.env.NODE_ENV !== 'production' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  combiReducers,
  compose(
    applyMiddleware(thunk),
    composeEnhancers()
  )
);

export default store;
