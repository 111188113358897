import React from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { withTranslation } from 'react-i18next';


class StringField extends React.Component<any, any> {

  private textboxInstance: TextBoxComponent;

  onChange = (args: any) => {
    const { setNewValue } = this.props;
    setNewValue(args.value);
  };

  render() {
    const { field, isLocked, value, hiddenClass } = this.props;

    return (
      <div className={`form-input-container ${hiddenClass}`}>
        <label className='form-input-label'>{this.props.t(field.label)}</label>
        <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
        <div
          className='form-input-element '
        >
          <TextBoxComponent
            id={`attribute-${field.attribute.key}`}
            ref={(scope) => { (this.textboxInstance as TextBoxComponent | null) = scope; }}
            className='form-input-element'
            value={value}
            input={this.onChange.bind(this)}
            enabled={!isLocked}
            type="text"
            name={field.name}
            data-msg-containerid={`error-${field.attribute.key}`}
          />
        </div>
        <div id={`error-${field.attribute.key}`} />
      </div>
    );
  }
}
export default (withTranslation()(StringField));