
import { runDynamicQuery } from '../../../backend/query/run.dynamicQuery';

const fetchTreeGridData: any = ($this, params: any, widgetData: any) => {

  $this.setState({ treeData_pending: true });

  return runDynamicQuery(params, widgetData)
    .then(
      (response) => {
        $this.setState({ treeData_pending: false, treeData_data: response });
        return response;
      }
    )
    .catch(
      (error: any) => console.log(' ERROR getTreeGridData', error)
    );

};

export default fetchTreeGridData;