import React from 'react';
import { connect } from 'react-redux';
import { ContextMenu, Edit, Filter, DayMarkers, GanttComponent, Inject, Resize, RowDD, Selection, Sort, SplitterSettingsModel, TimelineSettingsModel, Toolbar, LabelSettingsModel } from '@syncfusion/ej2-react-gantt';
import MenuBar from '../../components/MenuBar/MenuBar';
import { run as runActions } from '../../store/actions/run';
import { withTranslation } from 'react-i18next';
import { runDynamicQuery } from '../../backend/query/run.dynamicQuery';
import { WidgetProvider } from '../WidgetContext';
import './styles.css';
import moment from 'moment-timezone';

class GanttWidget extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      ganttData_Data: null
    };
  }
  ganttInstance: GanttComponent | null;
  projectStartDate: Date;
  projectEndDate: Date;
  toolbar: any = [
    {
      text: 'Zoom to day',
      tooltipText: 'Zoom to day',
      id: 'ZoomToDayToolbarButton',
      prefixIcon: 'e-zoomin'
    },
    {
      text: 'Zoom to default',
      tooltipText: 'Zoom to default',
      id: 'ZoomToDefaultToolbarButton',
      prefixIcon: 'e-zoomout'
    },
    'ZoomToFit'
  ];
  labelSettings: LabelSettingsModel = {
    taskLabel: '${progress}%'
  };
  splitterSettings: SplitterSettingsModel = {
    position: '400px',
    separatorSize: 5,
    view: 'Default'
  };
  timelineSettings: TimelineSettingsModel = {
    timelineUnitSize: 25,
    weekStartDay: 1,
    topTier: {
      format: 'MMM, yyyy',
      unit: 'Week'
    },
    bottomTier: {
      format: 'dd',
      unit: 'Day',
      count: 1
    }
  };

  contextMenuItems: any = [
    this.props.navData.widgetData.menu?.context?.elements.map(
      (o) => {
        return {
          text: this.props.t(o.label),
          target: '.e-content',
          id: o.events.find(event => event.type === 'onClick')?.key.toString()
        };
      }
    )
  ];

  componentDidMount() {
    const { navData, runActionsState } = this.props;

    runDynamicQuery({ ...runActionsState?.data?.params, widgetKey: navData.renderID }, navData.widgetData)
      .then((response) => {
        const stages = [];
        response.data.forEach(
          stage => {
            stages.push(
              {
                ...stage,
                duration: moment.duration(stage.duration, stage.unit).asDays(),
                unit: 'days'
              }
            );
          }
        );
        this.setState({ ganttData_Data: { ...response, data: stages } });
      });
  }

  componentDidUpdate(prevProps) {
    const { navData, runActionsState } = this.props;

    if (prevProps.navData.renderID !== navData.renderID || prevProps.navData.widgetData.dataID !== navData.widgetData.dataID || prevProps.navData.widgetData.timestamp !== navData.widgetData.timestamp) {
      runDynamicQuery({ ...runActionsState?.data?.params, widgetKey: navData.renderID }, navData.widgetData)
        .then((response) => {
          const stages = [];
          response.data.forEach(
            stage => {
              stages.push(
                {
                  ...stage,
                  duration: moment.duration(stage.duration, stage.unit).asDays(),
                  unit: 'days'
                }
              );
            }
          );

          this.setState({ ganttData_Data: { ...response, data: stages } });
        });
    }
  }

  getCurrentGanttData = () => {
    const instanceData = this.ganttInstance.currentViewData;

    const ganttEditData = instanceData ? instanceData.map((element: any) => {
      return {
        id: element.id,
        // dependency: element.dependency,
        duration: element.ganttProperties.duration,
        durationUnit: element.ganttProperties.unit,
        startDate: element.ganttProperties.startDate,
        endDate: element.ganttProperties.endDate,
        fixed: element.taskData.fixed,
        name: element.ganttProperties.taskName,
        progress: element.ganttProperties.progress,
        sequence: element.taskData.sequence,
        predecessors: element.ganttProperties.predecessor
      };
    }) : null;
    return ganttEditData;
  };

  taskBarSelect(args: any) {
    const { navData } = this.props;
    const eventKey = navData.widgetData.events.find(event => event.type === 'onClick')?.key;
    runActions(eventKey, args?.data?.id, null);
  }

  toolbarClick(args) {
    switch (args.item.properties.id) {
    case 'ZoomToDefaultToolbarButton':
      this.ganttInstance.timelineSettings = {
        topTier: {
          format: 'MMM, yyyy',
          unit: 'Week'
        },
        bottomTier: {
          format: 'dd',
          unit: 'Day',
          count: 1
        }
      };
      break;
    case 'ZoomToDayToolbarButton':
      this.ganttInstance.timelineSettings = {
        topTier: {
          format: 'dd, MMM',
          unit: 'Day'
        },
        bottomTier: {
          format: 'HH',
          unit: 'Hour',
          count: 6
        }
      };
      break;
    default:
      break;
    }

    this.ganttInstance.scrollToDate(String(this.ganttInstance.projectStartDate));
  }



  milestoneTemplate() {
    return (
      <div className='e-gantt-milestone'>
        <div className='e-milestone-top'></div>
        <div className='e-milestone-bottom'></div>
      </div>
    );
  }

  actionBegin = (args: any) => {
    if (args.data?.taskData?.fixed) {
      args.cancel = true;
    }
  };

  contextMenuClick(args: any) {
    const eventKey = args.item.properties.id;
    const itemID = args.rowData.id;
    runActions(eventKey, itemID, { ...this.props.oldActionsParams });
  }

  contextMenuOpen() {
    // const record = args.rowData;
    // if (!record.hasChildRecords) {
    //     args.hideItems.push('Hide Column');
    // }
  }

  dataBound() {
    // this.ganttInstance.fitToProject();
    // this.ganttInstance.selectRow(0);
    this.ganttInstance.scrollToDate(String(this.ganttInstance.projectStartDate));
  }

  render() {
    const { navData } = this.props;
    const { ganttData_Data } = this.state;

    if (!ganttData_Data) return null;
    return (
      <div className='widget-container'>
        <WidgetProvider value={this}>
          {
            navData.widgetData.menu
              ? <MenuBar
                key={`menu-${navData.widgetData.menu.id}`}
                menu={navData.widgetData.menu}
              />
              : null
          }
          {
            navData.widgetData.label !== '' ? <h3 className='widget-label'>{this.props.t(navData.widgetData.label)} </h3> : ''
          }
          <div className='widget-content' id='syncfusionGanttView'>
            <GanttComponent
              locale={JSON.parse(localStorage.getItem('language'))}
              className='taskbar-container'
              ref={gantt => this.ganttInstance = gantt}
              height='100%'
              dateFormat={ganttData_Data.options.dateFormat}
              highlightWeekends={true}
              workWeek={ganttData_Data.project.workWeek}
              includeWeekend={false}
              dayWorkingTime={ganttData_Data.project.dayWorkingTime}
              projectStartDate={ganttData_Data.project.startDate}
              projectEndDate={ganttData_Data.project.endDate}
              dataSource={ganttData_Data.data}
              holidays={ganttData_Data.holidays}
              taskFields={navData.widgetData.taskFields}
              labelSettings={this.labelSettings}
              timelineSettings={this.timelineSettings}
              editSettings={navData.widgetData.editOptions}
              toolbarClick={this.toolbarClick.bind(this)}
              gridLines='Both'
              splitterSettings={this.splitterSettings}
              toolbar={this.toolbar}
              allowSelection={true}
              allowSorting={true}
              allowFiltering={true}
              allowResizing={true}
              enableContextMenu={true}
              allowRowDragAndDrop={true}
              onTaskbarClick={this.taskBarSelect.bind(this)}
              milestoneTemplate={this.milestoneTemplate.bind(this)}
              dataBound={this.dataBound.bind(this)}
              actionBegin={this.actionBegin.bind(this)}
              contextMenuClick={this.contextMenuClick.bind(this)}
              contextMenuOpen={this.contextMenuOpen.bind(this)}
              contextMenuItems={this.contextMenuItems[0]}
              taskMode='Auto' // important for calculating durations
            >
              <Inject services={[Selection, Toolbar, Filter, Sort, Resize, Edit, ContextMenu, RowDD, DayMarkers]} />
            </GanttComponent>
          </div>
        </WidgetProvider>
      </div >
    );
  }
}


const mapStateToProps = (state: any) => ({
  runActionsState: state.actions
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()((GanttWidget)));
