import { ChangeEventArgs, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import React from 'react';
import { withTranslation } from 'react-i18next';

class TextAreaFieldSync extends React.Component<any, any> {

  textboxInstance: any;

  onChange(args: ChangeEventArgs): void {
    const { setNewValue } = this.props;
    setNewValue(args.value);
  }

  onCreate(): void {
    const { field } = this.props;

    this.textboxInstance.respectiveElement.style.height = field.height + 'px';
  }

  render() {
    const { field, value, isLocked, hiddenClass } = this.props;

    return (
      <div className={`form-input-container ${hiddenClass}`}>
        <label className='form-input-label'>{this.props.t(field.label)}</label>
        <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
        <TextBoxComponent
          multiline={true}
          value={value === null ? '' : value}
          ref={(scope) => { this.textboxInstance = scope; }}
          change={this.onChange.bind(this)}
          created={this.onCreate.bind(this)}
          enabled={!isLocked}
          name={field.name}
          data-msg-containerid={`error-${field.attribute.key}`}
        />
        <div id={`error-${field.attribute.key}`} />
      </div>
    );
  }
}
export default (withTranslation()(TextAreaFieldSync));
