import React from 'react';
import { toast } from 'react-toastify';
import './styles.css';

export const notifyWarning = (toastContent) => {
  toast.warn(toastContent, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true
  });
};


export const notifyWarningWithParams = (toastContent, params) => {
  toast.warn(<>
    <div>{toastContent}</div>
    <div className='toast-text-bold'>{params}</div>
  </>, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true
  });
};