import React, { createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import store from '../store/store';
import { runWorkflow } from '../backend/workflow/runWorkflow';
import { openNewWorkspace, setActiveWorkspace, removeWorkspace, setIsNewWorkspace } from '../store/workspaces/action.workspaces';
import { callFunctions } from '../backend/functions/call.functions';
import { run as runActions } from '../store/actions/run';
import { runStaticQuery } from '../backend/query/run.staticQuery';

const InteractionContext = createContext();

export const useInteraction = () => {
  return useContext(InteractionContext);
};

const InteractionProvider = ({ children }) => {

  const newTab = async () => {
    const response = await runStaticQuery('workspace', null);
    const onLoadEvent = response.workspace.events.find((event) => event?.type === 'onLoad');

    if (onLoadEvent) {
      const key = onLoadEvent.key;
      const response = await runWorkflow(key, null, {}, null, true);
      await store.dispatch(openNewWorkspace(response, 'modules'));
    }
  };

  const loadTab = async (params) => {
    const response = await runWorkflow(params.key, params.id, { ...store.getState().actions.data.params ?? {} }, null, true);
    const currentWidgets = store.getState().workspaces.instances[store.getState().workspaces.activeWorkspace.index].widgets;
    await store.dispatch(openNewWorkspace(response, currentWidgets, params.context));
  };

  const focusTab = async (index) => {
    const { workspaces } = store.getState();
    const workspace = workspaces.instances[index];
    if (workspace) {
      await store.dispatch(setActiveWorkspace(index));

      if (workspace.isNewTab) {
        await runActions(workspace.data.key, workspace.data.id, workspace.data);
        if (workspace.data.functions) {
          await callFunctions(workspace.data.functions, null);
          await store.dispatch(setIsNewWorkspace(index, false));
        }
      }
    }
  };

  const closeTab = async () => {
    const { workspaces } = store.getState();
    const { activeWorkspace } = workspaces;

    if (workspaces.instances.length === 1) {
      return null;
    }

    await store.dispatch(removeWorkspace(activeWorkspace.index));
    await store.dispatch(setActiveWorkspace(activeWorkspace.index + (activeWorkspace.index === 0 ? 0 : -1)));
    const newActiveWorkspace = workspaces.instances[activeWorkspace.index + (activeWorkspace.index === 0 ? 0 : -1)];

    if (newActiveWorkspace.isNewTab) {
      await runActions(newActiveWorkspace.data.key, newActiveWorkspace.data.id, newActiveWorkspace.data);
      if (newActiveWorkspace.data.functions) {
        await callFunctions(newActiveWorkspace.data.functions, null);
        await store.dispatch(setIsNewWorkspace(newActiveWorkspace.index, false));
      }
    }
  };

  const isModalOpen = () => {
    const workspaces = store.getState().workspaces.instances; // ToDo: refactor workspaces state
    const workspace = workspaces.find(workspace => workspace.isActive === true);
    const modals = workspace.modals;
    const isOpen = Object.values(modals).some(modal => modal?.isOpen === true);
    return isOpen;
  };

  const handleMouseDown = async (event) => {
    if (event.detail.event.ctrlKey) {
      await loadTab(event.detail);
    }
  };

  const handleKeyDown = (event) => {
    if (event.altKey && event.key === 'n') {
      if (!isModalOpen())
        newTab();
    }
    if (event.altKey && event.key === 'Delete') {
      closeTab();
    }
    if (event.altKey && event.key.match(/[0-9]/)) {
      if (!isModalOpen()) {
        const getIndex = eventKey => eventKey === 0 ? 9 : eventKey - 1;
        focusTab(getIndex(parseInt(event.key)));
      }
    }
  };

  useEffect(() => {
    const onMouseDown = (event) => {
      handleMouseDown(event);
    };

    const onKeyDown = (event) => {
      handleKeyDown(event);
    };

    window.addEventListener('custom-mousedown', onMouseDown);
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('custom-mousedown', onMouseDown);
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [handleMouseDown, handleKeyDown]);

  const contextValue = {
    handleMouseDown,
    handleKeyDown,
  };

  return (
    <InteractionContext.Provider value={contextValue}>
      {children}
    </InteractionContext.Provider>
  );
};

InteractionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InteractionProvider;
