import { SET_MODAL_OPEN, CLOSE_RICHTEXT_MODAL, OPEN_RICHTEXT_MODAL, SET_TEXT_SELECTION, OPEN_MULTIUPLOAD_MODAL, CLOSE_MULTIUPLOAD_MODAL, SET_DIALOG_OPEN } from './types';

// Upload

export const openMultiUpload = (widgetData) => {
  return {
    type: OPEN_MULTIUPLOAD_MODAL,
    widgetData: widgetData
  };
};

export const closeMultiUpload = (widgetData) => {
  return {
    type: CLOSE_MULTIUPLOAD_MODAL,
    widgetData: widgetData
  };
};

// Richtext

export const closeRichtextDialog = () => {
  return {
    type: CLOSE_RICHTEXT_MODAL
  };
};

export const setTextSelection = (selectedText: any) => {
  return {
    type: SET_TEXT_SELECTION,
    selectedText: selectedText
  };
};

export const openRichtextDialog = (params: any, imageEvent: any, linkEvent: any, diagramEvent: any, eventType: any) => {
  return {
    type: OPEN_RICHTEXT_MODAL,
    params: params,
    imageEvent,
    linkEvent,
    diagramEvent,
    eventType
  };
};

// Dialog

export const setIsDialogOpen = (isOpen: boolean) => {
  return {
    type: SET_DIALOG_OPEN,
    isOpen: isOpen
  };
};


// Modal

export const setModalOpen = (isOpen: boolean) => {
  return {
    type: SET_MODAL_OPEN,
    isOpen: isOpen
  };
};
