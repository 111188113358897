import { getBreadcrumbItem } from '../../backend/query/static/run.breadcrumb';
import { runStaticQuery } from '../../backend/query/run.staticQuery';
import store from '../store';
import {
  BREADCRUMB_ADD_PAGE,
  BREADCRUMB_UPDATE_PAGES,
  BREADCRUMB_GO_BACK,
  BREADCRUMB_CLEAR,
  BREADCRUMB_SET_GRID_FILTER
} from './types';


export const addPageBreadcrumbAction = (pageLabel, pageParams, config) => ({
  type: BREADCRUMB_ADD_PAGE,
  data: {
    label: pageLabel,
    ...pageParams,
    config,
  }
});
  
export const updateBreadcrumbItems = (historyPagesData, currentPageData) => ({
  type: BREADCRUMB_UPDATE_PAGES,
  historyPagesData,
  currentPageData
});



export const setBreadcrumbItems = async (params) => {
  try {
    const response = await getBreadcrumbItem('system', params.key, params.id);
    const workspaceInstance = store.getState().workspaces.instances[store.getState().workspaces.activeWorkspace.index];
    const currentPage = workspaceInstance.breadcrumb.currentPage || {};
  
    if (currentPage?.key !== params.key && (params.key || currentPage?.id !== params.id)) {     
      await store.dispatch(addPageBreadcrumbAction(response.label.name, params, response));

      const nodesIDs = workspaceInstance?.breadcrumb?.historyPages?.map((element) => element.id) || [];
      const responseNodes = await runStaticQuery('validateNodeIds', { ids: nodesIDs }); 
      const filteredPages = workspaceInstance?.historyPages?.filter((x) => !responseNodes?.miss?.includes(x.id)) || [];
      const currentPageData = filteredPages[filteredPages?.length - 1] || []; 
      await store.dispatch(updateBreadcrumbItems(filteredPages, currentPageData));
    }
  } catch (error) {
    console.error(`setBreadcrumbItems: ${error}`);
  }
};


export const goBackBreadcrumbAction = (pageIndex) => ({
  type: BREADCRUMB_GO_BACK,
  pageIndex
});

  
export const clearBreadcrumb = () => ({
  type: BREADCRUMB_CLEAR,
  historyPagesData: [],
  currentPageData: []
});

export const setGridFilterValuesToBreadcrumb = (filterValues, key) => ({
  type: BREADCRUMB_SET_GRID_FILTER,
  filterValues,
  key,
});