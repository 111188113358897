const WORKSPACE_OPEN = 'WORKSPACE_OPEN';
const WORKSPACE_REMOVE = 'WORKSPACE_REMOVE';
const WORKSPACE_SET_ACTIVE_INDEX = 'WORKSPACE_SET_ACTIVE_INDEX';
const WORKSPACE_SET_IS_NEW = 'WORKSPACE_SET_IS_NEW';
const WORKSPACE_RENAME = 'WORKSPACE_RENAME';
const UPDATE_WORKSPACE_ACTIONS = 'UPDATE_TAB_ACTIONS';

const UPDATE_WORKSPACE_WIDGETS = 'UPDATE_WORKSPACE_WIDGETS';
const REMOVE_WORKSPACE_MODAL_WIDGET = 'REMOVE_WORKSPACE_MODAL_WIDGET';

const BREADCRUMB_UPDATE_PAGES = 'BREADCRUMB_UPDATE_PAGES';
const BREADCRUMB_ADD_PAGE = 'BREADCRUMB_ADD_PAGE';
const BREADCRUMB_GO_BACK = 'BREADCRUMB_GO_BACK';
const BREADCRUMB_CLEAR = 'BREADCRUMB_CLEAR';
const BREADCRUMB_ADD_NEW_INSTANCE = 'BREADCRUMB_ADD_NEW_INSTANCE';
const BREADCRUMB_SET_GRID_FILTER  = 'BREADCRUMB_SET_GRID_FILTER';

const CLOSE_RICHTEXT_MODAL = 'CLOSE_RICHTEXT_MODAL';
const OPEN_RICHTEXT_MODAL = 'OPEN_RICHTEXT_MODAL';
const SET_TEXT_SELECTION = 'SET_TEXT_SELECTION';
const OPEN_MULTIUPLOAD_MODAL = 'OPEN_MULTIUPLOAD_MODAL';
const CLOSE_MULTIUPLOAD_MODAL = 'CLOSE_MULTIUPLOAD_MODAL';
const SET_DIALOG_OPEN = 'SET_DIALOG_OPEN';
const SET_MODAL_OPEN = 'SET_MODAL_OPEN';

const SET_CHAT_OPEN = 'SET_CHAT_OPEN';
const SET_CHAT_FILTER_MODE = 'SET_CHAT_FILTER_MODE';
const SET_CHAT_POSTS = 'SET_CHAT_POSTS';
const ADD_CHAT_POSTS_TOP = 'ADD_CHAT_POSTS';
const ADD_CHAT_POSTS_BOTTOM = 'ADD_CHAT_POSTS_BOTTOM';
const SET_CHAT_BADGE_VISIBLE = 'SET_CHAT_BADGE_VISIBLE';
const SET_CHAT_VISIBLE = 'SET_CHAT_VISIBLE';


const GET_MODULES = 'GET_MODULES';
const SET_SELECTED_MODULE='SET_SELECTED_MODULE';

const GET_NAVIGATION = 'GET_NAVIGATION';


export {
  WORKSPACE_RENAME,
  WORKSPACE_OPEN,
  WORKSPACE_REMOVE,
  WORKSPACE_SET_ACTIVE_INDEX,
  UPDATE_WORKSPACE_ACTIONS,
  UPDATE_WORKSPACE_WIDGETS,
  WORKSPACE_SET_IS_NEW,
  REMOVE_WORKSPACE_MODAL_WIDGET,
  BREADCRUMB_UPDATE_PAGES,
  BREADCRUMB_ADD_PAGE,
  BREADCRUMB_GO_BACK,
  BREADCRUMB_CLEAR,
  BREADCRUMB_ADD_NEW_INSTANCE,
  BREADCRUMB_SET_GRID_FILTER,
  CLOSE_RICHTEXT_MODAL, 
  OPEN_RICHTEXT_MODAL, 
  SET_TEXT_SELECTION, 
  OPEN_MULTIUPLOAD_MODAL, 
  CLOSE_MULTIUPLOAD_MODAL, 
  SET_DIALOG_OPEN,
  SET_MODAL_OPEN,
  SET_CHAT_OPEN,
  SET_CHAT_POSTS,
  SET_CHAT_FILTER_MODE,
  ADD_CHAT_POSTS_TOP,
  ADD_CHAT_POSTS_BOTTOM,
  SET_CHAT_BADGE_VISIBLE,
  SET_CHAT_VISIBLE,
  GET_MODULES,
  SET_SELECTED_MODULE,
  GET_NAVIGATION
};
