import React from 'react';
import { connect } from 'react-redux';
import Graph from './graph.widget';
import { getGraphData } from '../../backend/query/static/run.style';


class GraphWidget extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      graphRender_data: null,

      graphData_data: null,
      graphData_pending: false,
      graphData__success: false,
      graphData_error: false
    };
  }

  componentDidMount() {
    const { navData } = this.props;

    this.setState({ graphRender_data: navData.widgetData });
    this.fetchGraphDataData();
  }

  componentDidUpdate(prevProps) {
    const { navData } = this.props;

    if (prevProps.navData.renderID !== navData.renderID || prevProps.navData.widgetData.dataID !== navData.widgetData.dataID || prevProps.navData.widgetData.timestamp !== navData.widgetData.timestamp) {
      this.setState({ graphRender_data: navData.widgetData });
      this.fetchGraphDataData();
    }
  }

  componentWillUnmount() {
    this.setState({
      graphRender_data: null,

      graphData_data: null,
      graphData_pending: false,
      graphData__success: false,
      graphData_error: false
    });
  }

  fetchGraphDataData = () => {
    const { navData, runActionsState } = this.props;
    this.setState({ graphData_pending: true });

    getGraphData({ ...runActionsState?.data?.params, widgetKey: navData.renderID }, navData.widgetData)
      .then((response: any) => {
        this.setState({ graphData__success: true, graphData_data: response.objects });
      })
      .catch(() => this.setState({ graphData__success: false, graphData_data: null, graphData_error: true }))
      .finally(() => this.setState({ graphData_pending: false }));
  };

  render() {
    const { navData, oldActionsParams } = this.props;
    const { graphRender_data, graphData_data, graphData__success } = this.state;

    if (!graphData__success || !graphRender_data || !graphData_data)
      return null;

    return (
      <>
        <Graph
          navData={navData}
          renderData={graphRender_data}
          data={graphData_data}
          oldActionParams={oldActionsParams}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  runActionsState: state.actions
});


const mapDispatchToProps = () => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GraphWidget);