import React, { useEffect } from 'react';
import ChatMessage from './chat.message';
import PropTypes from 'prop-types';


const ChatBody = ({ posts, isReply, setIsReply, setReplyTarget, lastMessageRef, targetDivRef, handleScroll, filterMode, navData, runActionsState }) => {

  useEffect(() => {
    targetDivRef.current?.addEventListener('scroll', handleScroll);

    setTimeout(() => {
      lastMessageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }, 1000);

    return () => {
      targetDivRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [posts]);


  return (
    <div
      ref={targetDivRef}
      className="body-container"
      key={'chat-body'}
      style={{ height: isReply ? '83%' : '91%', overflowY: 'scroll' }}
    >
      {posts &&
        posts.map((post, index) => (
          <ChatMessage
            navData={navData}
            runActionsState={runActionsState}
            post={post}
            setIsReply={setIsReply}
            setReplyTarget={setReplyTarget}
            filterMode={filterMode}
            key={'message-' + post?.id + '-' + index}
          />
        ))}
      <div ref={lastMessageRef}></div>
    </div>
  );
};

ChatBody.propTypes = {
  posts: PropTypes.array,
  isReply: PropTypes.bool,
  setIsReply: PropTypes.func,
  setReplyTarget: PropTypes.func,
  lastMessageRef: PropTypes.any,
  targetDivRef: PropTypes.any,
  handleScroll: PropTypes.func,
  filterMode: PropTypes.bool,
  navData: PropTypes.any,
  runActionsState: PropTypes.bool,
};

export default ChatBody;
