import { notifyDebugError } from '../../components/Toasts/toast.debugError';
import { setBreadcrumbItems } from '../../store/workspaces/action.breadcrumb';
import { copyURL } from './func/function.copyUrl';
import { fileDownload, base64Download } from './func/file/function.download';
import { fileView } from './func/file/function.view';
import { historyBack } from './func/function.historyBack';
import { logout } from './func/function.logout';
import { decrypt } from './func/tools/decrypt';
import { encrypt } from './func/tools/encrypt';
import { translationReload } from './func/function.translationReload';
import { addNodesGraph } from './func/function.addNodes';
import sendMail from './func/function.sendMail';

export const callFunctions = async (functions: any, componentParams: any) => {

  functions.forEach((_function) => {
    try {
      console.log(
        `%c ${_function.function.name} (id: ${_function.params.id})%c\n`,
        'background: ##000',
        'background: transparent; color: #fff',
        {
          params: _function.params
        }
      );

      switch (_function.function.name) {
      case 'StoreBreadcrumb':
        return setBreadcrumbItems(_function.params);
      case 'Logout':
        return logout();
      case 'Download':
        return fileDownload(_function.params);
      case 'DownloadBase64':
        return base64Download(_function.params);
      case 'View':
        return fileView(_function.params);
      case 'Back':
      case 'Back 0':
      case 'Back 1':
        return historyBack(_function.params);
      case 'Copy':
        return copyURL();
      case 'ReloadTranslations':
        return translationReload(_function.params);
      case 'AddNodes':
        return addNodesGraph(_function.params, componentParams);
      case 'RemoveNodes':
        return componentParams ? componentParams.removeNode(_function.params) : null;
      case 'RearrangeNodes':
        return componentParams ? componentParams.reloadLayoutGraph(_function.params?.layout) : null;
      case 'Encrypt':
        return encrypt(_function.params);
      case 'Decrypt':
        return decrypt(_function.params);
      case 'SendMail':
        return sendMail(_function.params);
      default:
        return null;
      }
    } catch (error) {
      notifyDebugError(error.message, 'call _function');
      console.log(
        `%c call _function failed: ${_function.function.name}%c`,
        'background: #f66',
        'background: transparent;',
        {
          params: _function.params,
          error: error.message
        }
      );
    }
  });
};