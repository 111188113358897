import axios from 'axios';

export const writeErrorToLogFile = async (message, stack) => {
  axios.post(process.env.REACT_APP_BACKEND_URL + 'services/error-log', { message, stack })
    .then(() => {
      // console.log('Error logged successfully');
    })
    .catch((error) => {
      console.error('Write error to file failed:', error);
    });
};