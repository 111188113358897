import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouterHooks } from '../../routes/router.hooks';
import { setTreeData } from '../../store/workspaces/action.navigation';
import { getModules, setSelectedModule } from '../../store/workspaces/action.modules';
import { useTranslation } from 'react-i18next';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { run as runActions } from '../../store/actions/run';
import { clearBreadcrumb } from '../../store/workspaces/action.breadcrumb';
import PropTypes from 'prop-types';
import './styles.css';

const ModulesDropdown = ({workspace}) => {
  const themes = useSelector((state: any) => state.getThemes);
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();

  const dropdownInstance = useRef(null);

  const fields = {
    text: 'label',
    value: 'key'
  };

  useEffect(() => {
    dispatch(getModules());
  }, []);

  const onChangeModule = (args) => {
    if (!args.value) return null;

    const key = args.itemData.events?.find((event) => event?.type === 'onLoad')?.key;

    if (key) {
      dispatch(clearBreadcrumb());
      runActions(key, null, null, null);
    }
    dispatch(setTreeData(args.value));
    dispatch(setSelectedModule({key: args.value, label: args.itemData.label}));
  };

  if (workspace.navigation.modules.length !== 0 && workspace.navigation.modules) {
    workspace.navigation.modules.modules.forEach((element) => {
      element.label = t(element.label);
    });
  }

  return (
    <div className="navigation-module-select-wrap">
      <DropDownListComponent
        dataSource={workspace.navigation.modules.modules}
        fields={fields}
        ref={(scope) => {
          dropdownInstance.current = scope;
        }}
        placeholder={workspace.navigation.modules?.selectedModule?.label ?? t(workspace.navigation.modules.placeholder)}
        change={onChangeModule}
        style={{ cursor: 'pointer', color: themes?.data?.colors?.primary?.contrast }}
        allowFiltering={true}
      />
    </div>
  );
};

ModulesDropdown.propTypes = {
  workspace: PropTypes.object
};

export default withRouterHooks(ModulesDropdown);
