import React from 'react';
import { withRouterHooks } from '../../../routes/router.hooks';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  ContextMenu,
  Filter,
  Inject,
  Page,
  ExcelExport,
  PdfExport,
  Resize,
  Toolbar,
  InfiniteScroll,
  Sort
} from '@syncfusion/ej2-react-grids';
import { setGridData } from '../services/setGridData';
import { WidgetProvider } from '../../WidgetContext';
import MenuBar from '../../../components/MenuBar/MenuBar';
import { setCustomContextMenu } from '../services/setContextMenu';
import { getValue } from '@syncfusion/ej2-base';
import { setDataCounter } from '../services/dataCounter/setDataCounter';
import { contextMenuClick } from '../services/contextMenuClick';
import { withTranslation } from 'react-i18next';
import { linkElements } from './events/linkElements';
import { rowSelecting } from './events/rowSelecting';
// import DataCounter from '../services/dataCounter/dataCounter';
import { getSettingsLink } from './settings';
import { insertFilterValues, setFilterValues } from '../services/setFilterValues';
import { setKeyDownRemoveFilter } from '../services/setKeydownEvent';

class GridWidgetLink extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      gridData: null,
      gridEditData: [],
      dataCounterString: ''
    };
  }

  gridLinkInstance: GridComponent | null;
  selIndex = [];


  componentDidMount() {
    setGridData(this)
      .then(() => {
        insertFilterValues(this);
      });
  }

  componentDidUpdate(prevProps) {
    const { navData } = this.props;

    if (prevProps.navData.renderID !== navData.renderID || prevProps.navData.widgetData.dataID !== navData.widgetData.dataID || prevProps.navData.widgetData.timestamp !== navData.widgetData.timestamp) {
      this.setState({ gridData: null });
      setGridData(this);
    }
  }

  componentWillUnmount() {
    this.setState({ gridData: null, gridEditData: [], dataCounterString: '' });
    window.removeEventListener('keydown', (event) => setKeyDownRemoveFilter(event, this.gridLinkInstance));
  }

  created = () => {
    this.setState({ gridEditData: this.state.gridData });
    window.addEventListener('keydown', (event) => setKeyDownRemoveFilter(event, this.gridLinkInstance));
  };


  dataBound() {
    if (this.gridLinkInstance && this.selIndex.length) {
      this.gridLinkInstance.selectRows(this.selIndex);
      this.selIndex = [];
    }
  }

  rowDataBound(args) {
    if (getValue('selected', args.data) === true) {
      this.selIndex.push(parseInt(args.row
        .getAttribute('data-rowindex'), 0));
    }
  }

  actionComlepte = async (args) => {
    const { navData, runActionsState } = this.props;
    if (runActionsState && navData) {
      await setFilterValues(args, navData.widgetData.key);
    }
    await setDataCounter(args, this, this.props.t);
  };


  render() {
    const { navData } = this.props;

    if (!navData?.widgetData || !this.state.gridData) {
      return null;
    }

    const settings = getSettingsLink(navData);

    return (
      <div className='widget-container'>
        <WidgetProvider value={this}>
          {
            navData.widgetData.menu
              ? <MenuBar
                key={`menu-${navData.widgetData.menu.id}`}
                menu={navData.widgetData.menu}
              />
              : null
          }
          {
            navData.widgetData.label !== '' ? <h3 className='widget-label'>{this.props.t(navData.widgetData.label)} </h3> : ''
          }
          <div className="widget-content">
            <GridComponent
              locale={JSON.parse(localStorage.getItem('language'))}
              ref={g => this.gridLinkInstance = g}
              id={`grid-${navData.widgetData.key}`}
              key={`grid-${navData.widgetData.key}`}
              height={navData.widgetData.height ? navData.widgetData.height : '100%'}
              rowHeight={28}
              dataSource={this.state.gridData}
              className={`grid-widget${navData.widgetData.type === 'link' && navData.widgetData.rowSelection === 'single' ? ' e-link-grid' : ''}`}
              allowExcelExport={true}
              allowPdfExport={true}
              allowFiltering={true}
              allowResizing={true}
              allowSelection={true}
              allowSorting={true}
              allowPaging={navData.widgetData.allowPaging}
              enableInfiniteScrolling={!navData?.widgetData?.allowPaging}
              filterSettings={settings.filterSettingsMenu}
              contextMenuItems={setCustomContextMenu(navData)}
              pageSettings={navData?.widgetData?.allowPaging ? settings.pageSettings : settings.pageSettingsInfinite}
              // infiniteScrollSettings={!this.props.allowPaging || gridRenderData.type !== 'update' ? this.infiniteOptions : null}
              created={this.created.bind(this)}
              rowSelected={(args) => linkElements(this, args, true, this.gridLinkInstance)}
              rowDeselected={(args) => linkElements(this, args, false, this.gridLinkInstance)}
              rowSelecting={(args) => rowSelecting(this, args, this.gridLinkInstance)}
              contextMenuClick={(args) => contextMenuClick(args, this.gridLinkInstance, navData ? setCustomContextMenu(navData) : null)}
              dataBound={this.dataBound.bind(this)}
              rowDataBound={this.rowDataBound.bind(this)}
              actionComplete={this.actionComlepte.bind(this)}
            >
              <ColumnsDirective>
                <ColumnDirective
                  type='checkbox'
                  width='24px'
                  customAttributes={settings.customAttributes}
                  allowResizing={false}
                />
                {navData.widgetData.columns.map((column) => {
                  switch (column.type) {
                  case 'boolean':
                    return null;
                  case 'code':
                    return (
                      <ColumnDirective
                        key={`field-${column.id}`}
                        headerText={this.props.t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                        width={column.minWidth}
                        minWidth={column.minWidth}
                        maxWidth={column.maxWidth}
                        customAttributes={{ class: [settings.customAttributes.class, 'code-cell'] }}
                        textAlign={'Center'}
                      />
                    );
                  default:
                    return (
                      <ColumnDirective
                        key={`field-${column.id}`}
                        field={column.name}
                        width={column.minWidth}
                        minWidth={column.minWidth}
                        maxWidth={column.maxWidth}
                        // customAttributes={this.customAttributes}
                        customAttributes={column.name === 'id' ? { class: [settings.customAttributes.class, 'id-cell'] } : settings.customAttributes}
                        headerText={this.props.t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                        filter={settings.filterMenu}
                        visible={!column.hide}
                        disableHtmlEncode={false}
                        isPrimaryKey={column.name === 'id'}
                        type={'string'}
                      />
                    );
                  }
                })}
              </ColumnsDirective>
              <Inject services={[ContextMenu, Filter, Page, ExcelExport, PdfExport, Resize, Toolbar, InfiniteScroll, Sort]} />
            </GridComponent>
          </div>
          {/* {!navData?.widgetData?.allowPaging && (
            <DataCounter
              counter={this.state.dataCounterString}
            />
          )} */}
        </WidgetProvider>
      </div>
    );
  }
}

export default withRouterHooks(withTranslation()(GridWidgetLink));
