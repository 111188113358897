import {
  SET_CHAT_OPEN,
  SET_CHAT_POSTS,
  SET_CHAT_FILTER_MODE,
  ADD_CHAT_POSTS_TOP,
  ADD_CHAT_POSTS_BOTTOM,
  SET_CHAT_BADGE_VISIBLE
} from './types';
  
  
export const setChatOpen = (isOpen, id) => {
  return {
    type: SET_CHAT_OPEN,
    isOpen: isOpen,
    id: id
  };
};
  
  
export const setPosts = (posts) => {
  return {
    type: SET_CHAT_POSTS,
    posts: posts
  };
};
  
export const addPostsScrollTop = (loadedPosts) => {
  return {
    type: ADD_CHAT_POSTS_TOP,
    loadedPosts: loadedPosts
  };
};
  
export const addIncomingPosts = (loadedPosts) => {
  return {
    type: ADD_CHAT_POSTS_BOTTOM,
    loadedPosts: loadedPosts
  };
};
  
  
export const setFilterMode = (filterMode) => {
  return {
    type: SET_CHAT_FILTER_MODE,
    filterMode: filterMode
  };
};
  
export const setBadgeVisible = (isVisible, content) => {
  return {
    type: SET_CHAT_BADGE_VISIBLE,
    isVisible: isVisible,
    content: content
  };
};
  
  
  
  
  
  