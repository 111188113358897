import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormWidget from '../../widgets/form/Form';
import GraphWidget from '../../widgets/graph/Graph';
import TreeGridWidget from '../../widgets/treegrid/treegrid';
import ContextWidget from '../../widgets/context/Context';
import GalleryWidget from '../../widgets/gallery/Gallery';
import GanttWidget from '../../widgets/gantt/gantt';
import ModalDialog from '../../components/modals/Modal.dialog';
import View from '../../widgets/View/text/View.text';
import DiagramWidget from '../../widgets/diagram/diagram';
import GridWidgetView from '../../widgets/grid/view/Grid.view';
import GridWidgetSort from '../../widgets/grid/sort/Grid.sort';
import GridWidgetLink from '../../widgets/grid/link/Grid.link';
import GridWidgetUpdate from '../../widgets/grid/update/Grid.update';
import ChatWidget from '../../widgets/chat/chat';
import ErrorBoundary from '../../components/errorBoundary/errorBoundary';
import MapWidget from '../../widgets/map/map';
import Scheduler from '../../widgets/scheduler/Scheduler';
import PDFViewer from '../../widgets/View/pdf/View.pdf';
import CarouselWidget from '../../widgets/gallery/Gallery.carousel';

class LayoutWidget1 extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      /* navigation */
      historyPages: [],
      currentPage: null,
      runActionsState: null,
      socket: null
    };

    this.navigateWithExtraParams = this.navigateWithExtraParams.bind(this);
    this.setCurrentDataID = this.setCurrentDataID.bind(this);
  }

  componentDidMount() {
    const { widget, runActionsState } = this.props;
    if (runActionsState) {
      this.setState({ runActionsState: runActionsState });
    }

    if (widget) {
      this.setCurrentPage(widget);
    }
  }

  componentDidUpdate(prevProps) {
    const { widget, runActionsState } = this.props;

    if (JSON.stringify(prevProps.widget) !== JSON.stringify(this.props.widget)) {
      if (widget) {
        this.setCurrentPage(widget);
      }
    }

    if (JSON.stringify(prevProps.runActionsState) !== JSON.stringify(this.props.runActionsState)) {
      if (widget) {
        this.setState({ runActionsState: runActionsState });
      }
    }

  }

  setCurrentPage(widget: any) {
    this.setState({
      currentPage: {
        label: widget.label,
        widget: widget.widget,
        renderID: widget.key,
        dataID: null,
        params: { 'source': { 'id': null }, 'relation': null, 'target': { 'type': null } },
        widgetData: widget,
        query: widget.query
      }
    });
  }

  setCurrentDataID(id: number) {
    this.setState({ currentDataID: id });
  }

  navigateWithExtraParams(label, widget, viewType, renderID, dataID, params, extraParams) {
    const { currentPage, historyPages } = this.state;

    if (historyPages.length === 17) { historyPages.shift(); }

    let newParams;

    if (params !== null && (params.target !== null || params.relation !== null || params.source !== null)) {
      newParams = {
        source: {
          ...params.source,
          ...extraParams.source
        },
        relation: {
          ...params.relation,
          ...extraParams.relation
        },
        target: {
          ...params.target,
          ...extraParams.target
        }
      };
    } else {
      newParams = {
        ...currentPage.params,
        ...extraParams
      };
    }

    this.setState({
      currentPage: {
        label,
        widget,
        viewType,
        renderID,
        dataID,
        params: newParams,
        query: widget.query
      }
    });

  }


  render() {
    const { oldActionsParams, oldWidgetViewType } = this.props;
    const { currentPage, runActionsState } = this.state;

    if (!currentPage)
      return null;


    switch (currentPage.widget) {
    case 'Form':
      if (currentPage.widgetData.type === 'dialog') {
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <ModalDialog
              runActionsState={runActionsState}
              navData={currentPage}
              oldActionsParams={oldActionsParams}
              oldWidgetViewType={oldWidgetViewType}
            />
          </ErrorBoundary>
        );
      } else {
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <FormWidget
              runActionsState={runActionsState}
              navData={currentPage}
              oldActionsParams={oldActionsParams}
              oldWidgetViewType={oldWidgetViewType}
            />
          </ErrorBoundary>
        );
      }

    case 'Graph':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <GraphWidget
            runActionsState={runActionsState}
            navData={currentPage}
            oldActionsParams={oldActionsParams}
            oldWidgetViewType={oldWidgetViewType} />
        </ErrorBoundary>
      );

    case 'Grid':
      switch (currentPage.widgetData?.type) {
      case 'view':
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <GridWidgetView
              runActionsState={runActionsState}
              navData={currentPage}
              navigateWithExtraParams={this.navigateWithExtraParams}
              //oldActionsParams={oldActionsParams}
              oldWidgetViewType={oldWidgetViewType}
            />
          </ErrorBoundary>
        );
      case 'link':
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <GridWidgetLink
              runActionsState={runActionsState}
              navData={currentPage}
              navigateWithExtraParams={this.navigateWithExtraParams}
              //oldActionsParams={oldActionsParams}
              oldWidgetViewType={oldWidgetViewType}
            />
          </ErrorBoundary>
        );
      case 'sort':
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <GridWidgetSort
              runActionsState={runActionsState}
              navData={currentPage}
              navigateWithExtraParams={this.navigateWithExtraParams}
              //oldActionsParams={oldActionsParams}
              oldWidgetViewType={oldWidgetViewType}
            />
          </ErrorBoundary>
        );
      case 'update':
      case 'edit':
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <GridWidgetUpdate
              runActionsState={runActionsState}
              navData={currentPage}
              navigateWithExtraParams={this.navigateWithExtraParams}
              //oldActionsParams={oldActionsParams}
              oldWidgetViewType={oldWidgetViewType}
            />
          </ErrorBoundary>
        );

      case 'Diagram':
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <DiagramWidget
              navData={currentPage}
              oldActionsParams={oldActionsParams}
              oldWidgetViewType={oldWidgetViewType}
              runActionsState={runActionsState}
            />
          </ErrorBoundary>
        );

      case 'Chat':
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <ChatWidget
              // socket={this.state.socket}
              navData={currentPage}
              oldActionsParams={oldActionsParams}
              oldWidgetViewType={oldWidgetViewType}
              runActionsState={runActionsState}
            />
          </ErrorBoundary>
        );

      case 'Map':
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <MapWidget
              runActionsState={runActionsState}
              navData={currentPage}
            />
          </ErrorBoundary>
        );

      case 'Scheduler':
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <Scheduler
              runActionsState={runActionsState}
              navData={currentPage}
            />
          </ErrorBoundary>
        );
      default:
        return null;
      }
    case 'TreeGrid':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <TreeGridWidget
            runActionsState={runActionsState}
            navData={currentPage}
            navigateWithExtraParams={this.navigateWithExtraParams}
            oldActionsParams={oldActionsParams}
            oldWidgetViewType={oldWidgetViewType}
          />
        </ErrorBoundary>

      );

    case 'Context':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <ContextWidget
            runActionsState={runActionsState}
            navData={currentPage}
            oldActionsParams={oldActionsParams}
            oldWidgetViewType={oldWidgetViewType}
          />
        </ErrorBoundary>
      );

    case 'Gallery':
      if (currentPage.widgetData?.type === 'carousel'){
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <CarouselWidget
              runActionsState={runActionsState}
              navData={currentPage}
            />
          </ErrorBoundary>
        );
      }else{
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <GalleryWidget
              runActionsState={runActionsState}
              navData={currentPage}
            />
          </ErrorBoundary>
        );
      }
     

    case 'Gantt':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <GanttWidget
            navData={currentPage}
            oldActionsParams={oldActionsParams}
            oldWidgetViewType={oldWidgetViewType}
          />
        </ErrorBoundary>
      );

    case 'View':
      switch (currentPage.widgetData?.format) {
      case 'text':
      case 'html':
      case 'markdown':
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <View
              navData={currentPage}
              oldActionsParams={oldActionsParams}
              oldWidgetViewType={oldWidgetViewType}
              runActionsState={runActionsState}
            />
          </ErrorBoundary>
        );
      case 'pdf':
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <PDFViewer
              navData={currentPage}
              runActionsState={runActionsState}
            />
          </ErrorBoundary>
        );
      default:
        return null;
      }

    case 'Diagram':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <DiagramWidget
            navData={currentPage}
            oldActionsParams={oldActionsParams}
            oldWidgetViewType={oldWidgetViewType}
            runActionsState={runActionsState}
          />
        </ErrorBoundary>
      );

    case 'Chat':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <ChatWidget
            // socket={this.state.socket}
            navData={currentPage}
            oldActionsParams={oldActionsParams}
            oldWidgetViewType={oldWidgetViewType}
            runActionsState={runActionsState}
          />
        </ErrorBoundary>
      );

    case 'Map':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <MapWidget
            runActionsState={runActionsState}
            navData={currentPage}
          />
        </ErrorBoundary>
      );

    case 'Scheduler':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <Scheduler
            runActionsState={runActionsState}
            navData={currentPage}
          />
        </ErrorBoundary>
      );
    default:
      return null;
    }

  }
}

const mapStateToProps = (state: any) => ({
  chat: state.chat
});

export default connect(mapStateToProps, null)(LayoutWidget1);