import React, { useEffect, useState } from 'react';
import './styles.css';

const CenterLoader: any = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 1000);
  }, []);

  if (visible) {
    return (
      <div className={'centerLoaderCover'}>
        <div className="centerLoader" />
      </div>
    );
  }
};

export default CenterLoader;
