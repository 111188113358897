import { logger } from '../../../utils/logger';
import { runStaticQuery } from '../run.staticQuery';
import { jwtDecrypt } from '../../services/tools/service.jwtDecrypt';

export const getBreadcrumbItem = async (storeName: string, key: any, id: any) => {


  let userUUID = null;

  await jwtDecrypt(JSON.parse(localStorage.getItem('session')))
    .then((response) => {
      userUUID = response.data.user.uuid;
    })
    .catch((error) => {
      console.log('jwtDecrypt error', error);
    });


  try {
    const parameters = { user: userUUID, key: key, id: id };
    return runStaticQuery('breadcrumb', parameters);
  } catch (error) {
    logger.error('API getBreadcrumbItem error:  ', error);
  }
};