const initialState: any = {
  pending: false,
  success: false,
  error: false,
  data: {
    params: null
  }
};

export default initialState;
