export const setBatchEditData = ($this, args) => {
  if (args.action === 'edit') {
    const element = args.data;
    const newEditData = $this.state.gridEditData.map(obj => {
      if (obj.id === element.id) {
        return { ...element };
      }
      return obj;
    });
    $this.setState({ gridEditData: newEditData });
  }
};