import {
  WORKSPACE_OPEN,
  WORKSPACE_REMOVE,
  WORKSPACE_SET_ACTIVE_INDEX,
  UPDATE_WORKSPACE_ACTIONS, 
  WORKSPACE_SET_IS_NEW,
  WORKSPACE_RENAME
} from './types';


export const openNewWorkspace = (params: object, currentWidgets: object, widgetContext: string) => {
  return {
    type: WORKSPACE_OPEN,
    params: params,
    currentWidgets: currentWidgets,
    widgetContext: widgetContext
  };
};

export const removeWorkspace = (index: number) => {
  return {
    type: WORKSPACE_REMOVE,
    index: index
  };
};

export const setActiveWorkspace = (index: number) => {
  return {
    type: WORKSPACE_SET_ACTIVE_INDEX,
    index: index
  };
};

export const setIsNewWorkspace = (index: number, isNewTab: boolean) => {
  return {
    type: WORKSPACE_SET_IS_NEW,
    index: index,
    isNewTab: isNewTab
  };
};

export const updateWorkspaceActions = (index: number, newWorkspaceAction: object) => {
  return {
    type: UPDATE_WORKSPACE_ACTIONS,
    index: index,
    newWorkspaceAction: newWorkspaceAction
  };
};

export const renameWorkspace = (index: number, newName: string) => {
  return {
    type: WORKSPACE_RENAME,
    index: index,
    newName: newName
  };
};

