import * as React from 'react';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent
} from '@syncfusion/ej2-react-grids';
import { getData } from './services/getDataDiagram';
// import DataCounter from '../../../../../grid/services/dataCounter/dataCounter';
import { withTranslation } from 'react-i18next';


class DiagramGrid extends React.Component<any, any>{

  constructor(props) {
    super(props);
    this.state = {
      gridData: null,
      gridRenderData: {
        columns: [
          { name: 'target.ID', label: 'ID', field: 'target.ID', hide: false, minWidth: 50 },
          { name: 'id', label: 'id', field: 'id', hide: true, minWidth: 50 },
          { name: 'name', label: 'name', field: 'name', hide: false, minWidth: 150 },
          { name: 'image', label: 'image', field: 'image', hide: false, minWidth: 50 },
          { name: 'template', label: 'template', field: 'template', hide: false, minWidth: 100 },
          { name: 'preview', label: 'preview', field: 'preview', hide: true }
        ],
        rowSelection: 'single',
        type: 'link'
      }
    };
  }

  gridInstance: GridComponent | null;
  selectionSettings: any = {
    persistSelection: true,
    type: 'Single'
  };


  componentDidMount() {
    getData(this);
  }

  componentDidUpdate(prevProps: Readonly<any>): void {
    if (prevProps.tabType !== this.props.tabType) {
      getData(this);
    }
  }

  rowSelected(args: any) {
    this.props.$this.setState({ selectedElement: args.data });
  }

  rowSelecting() {
    this.gridInstance.clearSelection();
  }


  render() {
    const { gridRenderData, gridData } = this.state;

    if (!gridData) return null;

    return (
      <div className='widget-container'>

        {/* <h3 className='widget-label'>{this.props.t(navData.widgetData.label)} </h3> */}
        <div className="widget-content">
          <GridComponent
            ref={g => this.gridInstance = g}
            height={'550px'}
            rowHeight={28}
            className={' e-link-grid'}
            dataSource={this.state.gridData}
            allowFiltering={true}
            allowSelection={true}
            rowSelected={this.rowSelected.bind(this)}
            rowSelecting={this.rowSelecting.bind(this)}
          >
            <ColumnsDirective>
              <ColumnDirective
                type='checkbox'
                width='24px'
                allowResizing={false}
              />
              {gridRenderData.columns.map((column) => {
                switch (column.type) {
                case 'boolean':
                  return null;
                case 'code':
                  return (
                    <ColumnDirective
                      key={`field-${column.id}`}
                      headerText={this.props.t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                      width={column.minWidth}
                      minWidth={column.minWidth}
                      maxWidth={column.maxWidth}
                      textAlign={'Center'}
                    />
                  );
                default:
                  return (
                    <ColumnDirective
                      key={`field-${column.id}`}
                      field={column.name}
                      width={column.minWidth}
                      minWidth={column.minWidth}
                      maxWidth={column.maxWidth}
                      // customAttributes={this.customAttributes}
                      headerText={this.props.t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                      visible={!column.hide}
                      disableHtmlEncode={false}
                      isPrimaryKey={column.name === 'id'}
                      type={'string'}
                    />
                  );
                }
              })}
            </ColumnsDirective>
          </GridComponent>
        </div>
        {/* <DataCounter
          counter={this.state.dataCounterString}
        /> */}
      </div>
    );
  }
}

export default withTranslation()(DiagramGrid);