import store from '../../../store/store';
import { jwtEncrypt } from '../../services/tools/service.jwtEncrypt';

export const copyURL = async () => {

  const urlParam = {
    key: store.getState().breadcrumb.currentPage?.key,
    id: store.getState().breadcrumb.currentPage?.id,
    source: store.getState().breadcrumb.currentPage?.source,
    relation: store.getState().breadcrumb.currentPage?.relation,
    target: store.getState().breadcrumb.currentPage?.target,
    // breadcrumb: store.getState().breadcrumb.historyPages.map(item => {
    //   return { key: item.key, id: item.id, relation: item.relation, target: item.target };
    // })
  };

  jwtEncrypt(urlParam)
    .then((response) => {
      const copyUrl = window.location.href + '/' + response.data;

      if (typeof navigator.clipboard == 'undefined') {
        const textArea = document.createElement('textarea');
        textArea.value = copyUrl;
        textArea.style.position = 'fixed';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
        } catch (err) {
          console.error('Failed to copy: ', copyUrl);
        }

        document.body.removeChild(textArea);
        return;
      }
      navigator.clipboard.writeText(copyUrl);
    });
};