export const linkElements = ($this: any, args: any, link: boolean, gridLinkInstance: any) => {
  const { rowSelection } = $this.props.navData.widgetData;

  if (!$this.state.gridData) {
    return null;
  }

  const elements = [...$this.state.gridData];

  if (!gridLinkInstance) return null;

  if (rowSelection === 'single' && args.name === 'rowDeselected' && args.isInteracted) {
    elements.map((element: any) => {
      element.selected = false;
    });
    $this.setState({ gridEditData: elements });
    return null;
  }

  if (args.isHeaderCheckboxClicked) {
    elements?.map(
      row => {
        const index = elements.findIndex((element) => element.id === row.id);
        const element = elements[index];
        element.selected = args.name === 'rowDeselected' ? false : true;
        elements[index] = element;
      }
    );
    $this.setState({ gridEditData: elements });
    return null;
  }

  if (rowSelection === 'single' || !args.isInteracted) {
    args.cancel = true;
    return null;
  }

  const row: any = (args.target.closest('tr'));
  const cell = row.querySelector('td.id-cell');
  const id = Number.parseFloat(cell.innerHTML);

  const index = elements.findIndex((element) => element.id === id);
  const element = elements[index];
  element.selected = link;
  elements[index] = element;

  $this.setState({ gridEditData: elements });
};