import React from 'react';
import MenuBarButton from './MenuBar.button';
import MenuBarDropdownButton from './MenuBar.dropdown';
import { WidgetConsumer } from '../../widgets/WidgetContext';
import './styles.css';

class MenuBar extends React.Component<any, any> {


  handleNewTab = (eventKey, currentDataID, args, e) => {

    window.dispatchEvent(
      new CustomEvent(
        'custom-mousedown',
        {
          detail: {
            key: eventKey,
            id: currentDataID,
            event: e,
            context: 'menu'
          }
        }
      )
    );
  };


  render() {

    const {
      menu,
      properties,
      propertiesFiles
    } = this.props;

    if (!menu.default) return null;

    return (
      <div
        className="dropDown-container"
        id={`menu-${menu.key}`}
      >

        {menu.default?.elements?.map((element, menuIndex) => {
          switch (element.type) {
          case 'menu':
            return (
              <WidgetConsumer
                key={`menu-dd-btn-wrapper${element.key}`}
              >
                {widget => {
                  return (
                    <MenuBarDropdownButton
                      key={`menu-dd-btn-${element.key}`}
                      element={element}
                      properties={properties}
                      propertiesFiles={propertiesFiles}
                      menuIndex={menuIndex}
                      handleNewTab={this.handleNewTab}
                      validationFormObject={this.props.validationFormObject}
                      widget={widget}
                    />
                  );
                }
                }
              </WidgetConsumer>
            );
          case 'button':
            return (
              <WidgetConsumer
                key={`menu-dd-btn-wrapper${element.key}`}
              >
                {widget => {
                  return (
                    <MenuBarButton
                      key={`menu-btn-${element.key}`}
                      element={element}
                      properties={properties}
                      propertiesFiles={propertiesFiles}
                      handleNewTab={this.handleNewTab}
                      validationFormObject={this.props.validationFormObject}
                      widget={widget}
                    />
                  );
                }
                }
              </WidgetConsumer>
            );
          default:
            return null;
          }
        }
        )
        }
      </div>
    );
  }
}



export default MenuBar;
