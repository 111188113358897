import {
  ACTIONS_PENDING,
  ACTIONS_SUCCESS,
  ACTIONS_ERROR
} from './types';

export const runActionsPending = () => {
  return {
    type: ACTIONS_PENDING,
  };
};

export const runActionsReceived = (
  parameter: any
) => {
  return {
    type: ACTIONS_SUCCESS,
    parameter
  };
};

export const runActionsError = () => {
  return {
    type: ACTIONS_ERROR,
  };
};
