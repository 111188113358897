import {
  SET_LOADER
} from './types';
import initialState from './initialState';
  
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
  case SET_LOADER:
    return {
      ...state,
      visible: action.visible
    };
  
  default:
    return state;
  }
};
  
export default reducer;
  