import { logger } from '../../../utils/logger';
import { runStaticQuery } from '../run.staticQuery';
import { runDynamicQuery } from '../run.dynamicQuery';

export const getGraphData = async (params: any, widetData: any) => {
  try {
    return Promise.resolve()
      .then(() => {
        return runDynamicQuery(params, widetData);
      })
      .then((response: any) => {
        return runStaticQuery('style', { ...response, ...params });
      })
      .catch((error: any) => logger.params({}).error('getGraphData error: ', error));
  } catch (error) {
    logger.error('API getGraphData error:   ', error);
  }
};