import * as React from 'react';
import { connect } from 'react-redux';
import { withRouterHooks } from '../../routes/router.hooks';
import fetchTreeGridData from './services/fetchTreeGridData';
import MenuBar from '../../components/MenuBar/MenuBar';
import { run as runActions } from '../../store/actions/run';
/* syncfusion imports*/
import {
  GroupSettingsModel,
  Edit,
  Group,
  Selection,
  CommandColumn,
  RowSelectingEventArgs
} from '@syncfusion/ej2-react-grids';
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  ExcelExport,
  Filter,
  Inject,
  Page,
  PageSettingsModel,
  PdfExport,
  Resize,
  RowDD,
  Sort,
  TreeGridComponent,
  Toolbar,
  Aggregate,
  AggregatesDirective,
  AggregateDirective,
  AggregateColumnsDirective,
  AggregateColumnDirective
} from '@syncfusion/ej2-react-treegrid';
import { withTranslation } from 'react-i18next';
import { WidgetProvider } from '../WidgetContext';
import './styles.css';



class TreeGridWidget extends React.Component<any, any>{

  constructor(props) {
    super(props);

    this.state = {
      treeData_data: null,
      treeData_pending: false,
      treeData_success: false,
      treeData_error: false,

      gridEdit_data: [],
      gridEdit_pending: false,
      gridEdit_success: false,
      gridEdit_error: false,

      selectedDataCheckbox: [],
      deselectElement: null,
      rowClickedData: null,
    };
  }
  ctrlKeyPressed = false;
  flagDatabound = true;
  flagClickEvent = false;


  treegrid: TreeGridComponent | null;
  groupOptions: GroupSettingsModel = { showGroupedColumn: true };
  customAttributes: any = { class: 'customcss' };
  filter: any = {
    type: 'CheckBox',
    operator: 'contains'
  };
  pageOptions: PageSettingsModel = {
    pageSize: 20,
    pageSizes: ['5', '10', '20', '50']
  };
  filterSettings: any = {
    mode: 'Immediate',
    hierarchyMode: 'Both',
    immediateModeDelay: 400,
    type: 'FilterBar',
    operator: 'contains'
  };
  toolbarOptions: any = [
    'ExpandAll',
    'CollapseAll',
    { text: 'Level 1', id: '1', prefixIcon: 'e-time' },
    { text: 'Level 2', id: '2', prefixIcon: 'e-time' },
    { text: 'Level 3', id: '3', prefixIcon: 'e-time' }];


  componentDidMount() {
    const { navData, runActionsState } = this.props;
    fetchTreeGridData(this, { ...runActionsState, widgetKey: navData.renderID }, navData.widgetData);
  }

  componentDidUpdate(prevProps) {
    const { navData, runActionsState } = this.props;

    if (prevProps.navData.renderID !== navData.renderID || prevProps.navData.widgetData.dataID !== navData.widgetData.dataID || prevProps.navData.widgetData.timestamp !== navData.widgetData.timestamp) {
      this.clearData();
      fetchTreeGridData(this, { ...runActionsState, widgetKey: navData.renderID }, navData.widgetData);
    }
  }

  componentWillUnmount() {
    this.clearData();
  }

  clearData() {
    this.setState({
      treeData_data: null,
      treeData_pending: false,
      treeData_success: false,
      treeData_error: false,
    });
  }

  rowSelecting(args: RowSelectingEventArgs) {
    const { navData, oldActionsParams } = this.props;

    const rowInfo: any = this.treegrid.getRowInfo(args.target);
    const rowData: any = rowInfo.rowData;
    const eventKey = navData.widgetData.events.find(event => event.type === 'onClick')?.key;

    if (args.isShiftPressed) {
      this.treegrid.copy();
      return null;
    }

    window.dispatchEvent(
      new CustomEvent(
        'custom-mousedown',
        {
          detail: {
            key: eventKey,
            id: rowData?.id,
            event: { ctrlKey: args.isCtrlPressed },
            context: 'grid'
          }
        }
      )
    );

    if (!rowData || Object.keys(rowData).length === 0) return null;
    if (!args.isCtrlPressed) {
      runActions(eventKey, rowData?.id, { ...oldActionsParams, ...rowData.taskData });
    }
  }
  

  toolbarClick(args) {
    if (this.treegrid && args.item.text === 'Level 1') {
      this.treegrid.expandAtLevel(0);
      this.treegrid.collapseAtLevel(1);
      this.treegrid.collapseAtLevel(2);
    }
    if (this.treegrid && args.item.text === 'Level 2') {
      this.treegrid.expandAtLevel(0);
      this.treegrid.expandAtLevel(1);
      this.treegrid.collapseAtLevel(2);
    }
    if (this.treegrid && args.item.text === 'Level 3') {
      this.treegrid.expandAtLevel(0);
      this.treegrid.expandAtLevel(1);
      this.treegrid.expandAtLevel(2);
    }
  }

  initial = true;

  dataBound() {
    const { navData } = this.props;

    if (this.treegrid && this.initial === true) {
      if (navData.widgetData.level === '1') {
        this.treegrid.expandAtLevel(0);
      }
      if (navData.widgetData.level === '2') {
        this.treegrid.expandAtLevel(0);
        this.treegrid.expandAtLevel(1);
      }
      if (navData.widgetData.level === '3') {
        this.treegrid.expandAtLevel(0);
        this.treegrid.expandAtLevel(1);
        this.treegrid.expandAtLevel(2);
      }
      this.initial = false;
    }
    // this.treegrid.element.addEventListener("click", this.onClick.bind(this));
  }

  footerItemCounter(props) {
    return (<span>Items: {props.count}</span>);
  }

  render() {
    const { navData, oldActionsParams } = this.props;
    const { treeData_data, gridEdit_data } = this.state;

    if (navData.widgetData === null || treeData_data === null) {
      return null;
    }

    return (
      <div className='widget-container'>
        {/* <div className='control-section'> */}
        <WidgetProvider value={this}>
          {
            navData.widgetData.menu
              ? <MenuBar
                key={`menu-${navData.widgetData.menu.id}`}
                menu={navData.widgetData.menu}
                currentDataID={navData.widgetData.dataID}
                properties={gridEdit_data}
                oldActionsParams={oldActionsParams}
                viewType={navData.widgetData.type}
                widgetType={navData.widgetData.widget}
              />
              : null
          }
          {
            navData.widgetData.label !== '' ? <h3 className='widget-label'>{this.props.t(navData.widgetData.label)} </h3> : ''
          }
          <div className="widget-content" id="syncfusionTreeGrid">
            <TreeGridComponent
              locale={JSON.parse(localStorage.getItem('language'))}
              key={`treegrid-${navData.widgetData.id}`}
              height={'100%'}
              rowHeight={28}
              ref={g => this.treegrid = g}
              dataSource={treeData_data?.data}
              dataBound={this.dataBound.bind(this)}
              idMapping={treeData_data?.options?.id}
              parentIdMapping={treeData_data?.options?.parentId}
              pageSettings={this.pageOptions}
              toolbar={this.toolbarOptions}
              allowExcelExport={true}
              allowPdfExport={true}
              allowSorting={true}
              allowFiltering={true}
              filterSettings={this.filterSettings}
              allowResizing={true}
              allowSelection={true}
              enableCollapseAll={navData.widgetData.level === 0 ? true : false}
              treeColumnIndex={1}
              toolbarClick={this.toolbarClick.bind(this)}
              rowSelecting={this.rowSelecting.bind(this)}
            >
              <ColumnsDirective>
                {navData.widgetData.columns.map((column) => {
                  return (
                    <ColumnDirective
                      key={`field-${column.id}`}
                      field={column.name}
                      headerText={this.props.t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                      filter={this.filter}
                      visible={column.hide === false ? true : false}
                      disableHtmlEncode={false}
                      width={column.width}
                      minWidth={column.width}
                      // maxWidth={column.maxWidth}
                    />
                  );
                })}
              </ColumnsDirective>
              <AggregatesDirective>
                <AggregateDirective showChildSummary={false}>
                  <AggregateColumnsDirective>
                    <AggregateColumnDirective columnName='ID' type='count' footerTemplate={this.footerItemCounter} />
                  </AggregateColumnsDirective>
                </AggregateDirective>
              </AggregatesDirective>
              <Inject services={[Sort, ContextMenu, Filter, Page, ExcelExport, Edit, Group, PdfExport, Selection, Resize, RowDD, Toolbar, CommandColumn, Aggregate]} />
            </TreeGridComponent>
          </div>
        </WidgetProvider>
      </div>
    // </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  getState: state.getState,
  globalKeyEvent: state.keyEvent
});

const mapDispatchToProps = () => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouterHooks(withTranslation()(TreeGridWidget)));