

const tapEdge = (cy: any, widgetEvents: any, runActions: any, runActionsState: any) => {

  return cy.on('tap', 'edge', async function (e) {

    const element = e.target;

    element.addClass('selected');
    cy.elements()
      .difference(element)
      .removeClass('selected');

    const id = parseInt(element.data().id);
    const key = widgetEvents?.find(event => event.type === 'onClick').key;

    if (!key) {
      alert('tapEdge: kein event gefunden');
      return null;
    }
    runActions(key, id, { ...runActionsState.data.params }, null);
  });

};

export default tapEdge;
