import { runDynamicQuery } from '../../../backend/query/run.dynamicQuery';
import store from '../../../store/store';

export const setGridData = async ($this) => {
  const { navData } = $this.props;
  const activeTabData = store.getState().workspaces.instances[store.getState().workspaces.activeWorkspace.index].data;

  const queryParams = {
    ...activeTabData,
    params: activeTabData,
    ...activeTabData.response,
    widgetKey: navData.renderID,
  };

  try {
    const response = await runDynamicQuery(queryParams, navData.widgetData);

    $this.setState({
      gridData: response.targets,
      gridEditData: response.targets,
      // itemCountString: response.targets?.length === 1 ? t('Item') : t('Items'),
      // dataCounterString: `(${response.targets?.length || 0} ${itemCountString})`,
    });
  } catch (error) {
    console.error('Error fetching grid data:', error);
  }
};
